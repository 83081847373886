<template>
  <div>
    <el-form
      ref="ruleLoginRef"
      style="max-width: 600px; padding: 50px"
      :model="formLogin"
      :rules="rulesLogin"
      label-width="auto"
      label-position="top"
      v-loading="isLoginSending"
      status-icon
    >
      <el-form-item label="Mot de passe oublié ?">
        <el-col :span="24">
          <el-form-item label="E-mail" required prop="email">
            <el-input type="email" v-model="formLogin.email" required />
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-col :span="24"></el-col>
      <button class="btn-trans-blue w-1" type="button" @click="login()">
        Valider
      </button>
    </el-form>
    <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import { ElNotification } from "element-plus";

export default {
  name: "ForgotPsw",
  data() {
    return {
      config: config,
      errorMessage: "",
        formLogin:{
            email: ""
        },
      isLoginSending: false,
      rulesLogin: {
        email: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ]
      },
    };
  },
  methods: {
    async login() {
      try {
        if (!this.$refs.ruleLoginRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleLoginRef.validate((valid, fields) => {
            if (valid) {
              console.log("submit!");
              resolve(true);
            } else {
              console.log("error submit:" + reject, fields);
              resolve(false);
            }
          });
        });
        if (!check) return;
        this.isLoginSending = true;
        const response = await axios.post(
          `${this.config.API_BASE_URL}/user/forgotPsw`,
          {
            data: this.formLogin,
          }
        );
        if (response.data) {
            this.errorMessage = 'Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail. Veuillez utiliser le lien reçu pour réinitialiser votre mot de passe.';
        }
        ElNotification({
          title: "Success",
          message: "Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail. Veuillez utiliser le lien reçu pour réinitialiser votre mot de passe.",
          type: "success",
          position: "bottom-right",
          duration: 0,
        });
        this.isLoginSending = false;
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            console.error("Adresse Email non trouvée.");
            ElNotification({
              title: "Erreur",
              message: "Adresse Email non trouvée.",
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
          } else {
            console.error("Erreur:", error.response.data.message);
            ElNotification({
              title: "Erreur",
              message: error.response.data.message,
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
          }
        } else if (error.request) {
          console.error("Aucune réponse reçue du serveur.", error.request);
          ElNotification({
            title: "Erreur",
            message:
              "Aucune réponse reçue du serveur. Veuillez réessayer plus tard.",
            type: "error",
            position: "bottom-right",
            duration: 0,
          });
          this.isLoginSending = false;
        } else {
          console.error("Erreur:", error.message);
          ElNotification({
            title: "Erreur",
            message: error.message,
            type: "error",
            position: "bottom-right",
            duration: 0,
          });
          this.isLoginSending = false;
        }
      }
    },
  },
};
</script>
