<template>
  <div>
    <div class="flex space-between">
      <a href="https://symone.fr/moto" target="_blank">
        <img
          src="@/assets/images/ui/logo_couleur (1).png"
          alt="Logo"
          style="width: 400px; align-self: center; padding: 10px"
        />
      </a>
      <button @click="logout">Se déconnecter</button>
    </div>
    <div
      style="
        background: #000047;
        height: 50px;
        border-bottom: 3px solid #e6007e;
      "
    >
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'estimates' }"
        @click="switchTab('estimates')"
      >
        Chiffrage
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'orders' }"
        @click="switchTab('orders')"
      >
        Devis
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'routes' }"
        @click="switchTab('routes')"
      >
        Trajets
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'users' }"
        @click="switchTab('users')"
      >
        Clients
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'options' }"
        @click="switchTab('options')"
      >
        Options
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'subscribers' }"
        @click="switchTab('subscribers')"
      >
        Abonnés
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'events' }"
        @click="switchTab('events')"
      >
        Events
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'discountCodes' }"
        @click="switchTab('discountCodes')"
      >
        Code promo
      </div>
      <div
        class="menu-admin"
        :class="{ active: currentTab === 'news' }"
        @click="switchTab('news')"
      >
        Actus
      </div>
    </div>

    <!-- Tab Users -->
    <div v-if="currentTab === 'users'">
      <el-table v-if="events.length > 0" :data="users" stripe>
        <el-table-column type="expand">
          <template #default="props">
            <div class="tr-edit">
              <input
                type="text"
                v-model="
                  tmpUsers[users.findIndex((item) => item.id === props.row.id)]
                    .firstname
                "
                placeholder="Prénom"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpUsers[users.findIndex((item) => item.id === props.row.id)]
                    .lastname
                "
                placeholder="Nom"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpUsers[users.findIndex((item) => item.id === props.row.id)]
                    .phone
                "
                placeholder="Tél"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpUsers[users.findIndex((item) => item.id === props.row.id)]
                    .address
                "
                placeholder="Adresse domicile"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpUsers[users.findIndex((item) => item.id === props.row.id)]
                    .society_name
                "
                placeholder="Nom de la société"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpUsers[users.findIndex((item) => item.id === props.row.id)]
                    .society_address
                "
                placeholder="Adresse de la société"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpUsers[users.findIndex((item) => item.id === props.row.id)]
                    .tax_number
                "
                placeholder="Numéro fiscal"
              />
              <br /><br />
              <select
                v-model="
                  tmpUsers[users.findIndex((item) => item.id === props.row.id)]
                    .type
                "
                @change="
                  (event) => {
                    tmpUsers[
                      users.findIndex((item) => item.id === props.row.id)
                    ].type = parseInt(event.target.value, 10);
                  }
                "
              >
                <option value="1">Admin</option>
                <option value="2">Chauffeur</option>
                <option value="3">Client</option>
                <option value="4">Manager</option>
              </select>
              <br /><br />
              <div class="flex space-between">
                <button class="btn-blue" type="button" @click="editUserSend(props.row)">
                  Modifier
                </button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" />
        <el-table-column label="E-mail" prop="email" />
        <el-table-column label="NOM" prop="lastname" />
        <el-table-column label="Prénom" prop="firstname" />
        <el-table-column label="Tél" prop="phone" />
        <el-table-column label="Opération">
          <template #default="{ row }">
            <button class="btn-blue" type="button" @click="forgotPsw(row)">
              Réinitialiser le mot de passe
            </button>
          </template>
        </el-table-column>
        <el-table-column label="Date d'inscription">
          <template #default="{ row }">
            {{
              formattedDate(
                users[users.findIndex((item) => item.id === row.id)]
                  .date_registration
              )
            }}
          </template>
        </el-table-column>
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un client</b><br /><br />
        <form id="formUser">
          <input
            type="email"
            v-model="formDataUser.email"
            placeholder="E-mail"
          /><br />
          <input
            type="text"
            v-model="formDataUser.firstname"
            placeholder="Prénom"
          /><br />
          <input
            type="text"
            v-model="formDataUser.lastname"
            placeholder="NOM"
          /><br />
          <input
            type="text"
            v-model="formDataUser.phone"
            placeholder="Tél"
          /><br />
          <input
            type="text"
            v-model="formDataUser.address"
            placeholder="Adresse domicile"
          /><br />
          <input
            type="text"
            v-model="formDataUser.society_name"
            placeholder="Nom de la société"
          /><br />
          <input
            type="text"
            v-model="formDataUser.society_address"
            placeholder="Adresse de la société"
          /><br />
          <input
            type="text"
            v-model="formDataUser.tax_number"
            placeholder="Numéro fiscal"
          /><br />

          <select
            v-model="
              formDataUser.type
            "
            @change="
              (event) => {
                formDataUser.type = parseInt(event.target.value, 10);
              }
            "
          >
            <option value="1">Admin</option>
            <option value="2">Chauffeur</option>
            <option value="3">Client</option>
            <option value="4">Manager</option>
          </select>
          <br />
          <br />
          <button type="button" class="btn-blue" @click="addUserSend()">
            Ajouter
          </button>
        </form>
      </div>
      
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['users']" :data="histories['users']" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else-if="currentTab === 'discountCodes'">
      <el-table v-if="discountCodes.length > 0" :data="discountCodes" stripe>
        <!-- Expand Column -->
        <el-table-column type="expand">
          <template #default="props">
            <div class="tr-edit">
              <!-- Code (text) -->
              <p>
                Promo code :
                <el-input v-model="tmpDiscountCodes[discountCodes.findIndex((item) => item.id === props.row.id)]
                    .code" placeholder="Code"></el-input>
              </p>
              
              <!-- Type (select) -->
              <p>
                Type :
                <el-select v-model="tmpDiscountCodes[discountCodes.findIndex((item) => item.id === props.row.id)].type" placeholder="Select Type">
                  <el-option label="%" :value="1" />
                  <el-option label="€" :value="2" />
                </el-select>
              </p>
              
              <!-- Value (integer) -->
              <p>
                Valeur :
                <el-input-number v-model="tmpDiscountCodes[discountCodes.findIndex((item) => item.id === props.row.id)].value" :min="0" placeholder="Value"></el-input-number>
              </p>
              
              <!-- Expiration Date (date) -->
              <p>
                Expiration Date :
                <el-date-picker
                  v-model="tmpDiscountCodes[discountCodes.findIndex((item) => item.id === props.row.id)].date_expiration"
                  type="date"
                  placeholder="Select Date"
                  format="DD-MM-YYYY"
                  value-format="YYYY-MM-DD"
                />
              </p>
              
              <!-- List Route (checkbox group) -->
              <p>Trajets :</p>
              <el-checkbox-group v-model="tmpDiscountCodes[discountCodes.findIndex((item) => item.id === props.row.id)].list_route">
                <el-checkbox :value="0" :key="0">Tous les trajets</el-checkbox>
                <el-checkbox
                  v-for="row in routes.filter((item) => new Date(formatDateToInputDateValue(item.date_depart)) >= new Date())"
                  :value="row.id"
                  :key="row.id"
                >
                {{ row.id }}: 
            <span style="color: black"
              ><b>[{{
                  formattedDate(tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].date_depart)
                }}]{{
                tmpRoutes[
                  routes.findIndex((item) => item.id === row.id)
                ].depart.split(",")[0]
              }}</b></span
            >
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover1
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover1.split(",")[0]
                : ""
            }}</span>
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover2
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover2.split(",")[0]
                : ""
            }}</span>
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover3
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover3.split(",")[0]
                : ""
            }}</span>
            →
            <span style="color: black"
              ><b>{{
                tmpRoutes[
                  routes.findIndex((item) => item.id === row.id)
                ].arrival.split(",")[0]
              }}</b></span
            >
            <br>
                </el-checkbox>
              </el-checkbox-group>
              <br /><br />
              <div class="flex space-between">
                <button class="btn-blue" type="button" @click="confirmDelDiscountCode(props.row)">
                  Supprimer
                </button>
                <button class="btn-blue" type="button" @click="editDiscountCodeSend(props.row)">
                  Modifier
                </button>
              </div>
            </div>
          </template>
        </el-table-column>

        <!-- Display Columns -->
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="code" label="Promo Code" />
        <el-table-column prop="value" label="Valeur" />
        <el-table-column prop="type" label="Type">
          <template #default="{ row }">
            {{ row.type > 1 ? '€' : "%" }}
          </template>
        </el-table-column>
        <el-table-column prop="date_expiration" label="Expiration Date">
          <template #default="{ row }">
            {{discountCodes[discountCodes.findIndex((item) => item.id === row.id)]
                  .date_expiration ? 
              formattedDate(
                discountCodes[discountCodes.findIndex((item) => item.id === row.id)]
                  .date_expiration
              ) : 'Toujours valide'
            }}
          </template>
        </el-table-column>
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un code promo</b><br /><br />
        <form id="formEstimate">
          <input
            type="text"
            v-model="formDataDiscountCode.code"
            placeholder="Code promo"
          /><br /><br />
          
          Date d'expiration
          <input type="date" v-model="formDataDiscountCode.date_expiration" /><br /><br />
          
          Valeur<input
            type="text"
            v-model="formDataDiscountCode.value"
            placeholder="Valeur"
          /><br />
          <br />
          <select
            v-model="
              formDataDiscountCode.type
            "
          >
            <option :value="1">%</option>
            <option :value="2">€</option>
          </select>
          <br /><br />
          <p>Trajets :</p>
              <el-checkbox-group v-model="formDataDiscountCode.list_route">
                <el-checkbox :value="0" :key="0">Tous les trajets</el-checkbox>
                <el-checkbox
                  v-for="row in routes.filter((item) => new Date(formatDateToInputDateValue(item.date_depart)) >= new Date())"
                  :value="row.id"
                  :key="row.id"
                >
                {{ row.id }}: 
            <span style="color: black"
              ><b>[{{
                  formattedDate(tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].date_depart)
                }}]
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].depart.split(",")[0]
                }}
              </b>
            </span>
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover1
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover1.split(",")[0]
                : ""
            }}</span>
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover2
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover2.split(",")[0]
                : ""
            }}</span>
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover3
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover3.split(",")[0]
                : ""
            }}</span>
            →
            <span style="color: black"
              ><b>{{
                tmpRoutes[
                  routes.findIndex((item) => item.id === row.id)
                ].arrival.split(",")[0]
              }}</b></span
            >
            <br>
                </el-checkbox>
              </el-checkbox-group>
          <br />
          <br />
          <br />
          <button type="button" class="btn-blue" @click="addDiscountCodeSend()">
            Ajouter
          </button>
        </form>
      </div>
      
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['discount_codes']" :data="histories['discount_codes']" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else-if="currentTab === 'options'">
      <div class="news-add">
        <form id="formOptions">
          Assurance annulation
          <input
            type="number"
            v-model.number="options[0].value"
            placeholder="E-mail"
          />%<br />
          <br />
          Adresse de chargement/déchargement personnalisée
          <input
            type="number"
            v-model.number="options[1].value"
            placeholder="E-mail"
          />€<br />
          <br>
          <br>

          <button type="button" class="btn-blue" @click="editOptionsSend()">
            Enregistrer
          </button>
        </form>
      </div>
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['options']" :data="histories['options']" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else-if="currentTab === 'subscribers'">
      <el-table v-if="subscribers.length > 0" :data="subscribers" stripe>
        <el-table-column label="E-mail" prop="email" />
        <el-table-column label="Opération">
          <template #default="{ row }">
            <button class="btn-blue" type="button" @click="delSubscriber(row.email)">
              Supprimer
            </button>
          </template>
        </el-table-column>
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un abonné</b><br /><br />
        <form id="formSubscriber">
          <input
            type="email"
            v-model="formDataSubscriber.email"
            placeholder="E-mail"
          /><br />
          <br />
          <button type="button" class="btn-blue" @click="addSubscriberSend()">
            Ajouter
          </button>
        </form>
      </div>
    </div>

    
    <div v-else-if="currentTab === 'orders'">
      <el-form
        class="searchBar"
        :model="filterOrders"
        label-width="auto"
        label-position="top"
      >
        <div>
          <el-col :span="1">
            <el-form-item label="ID Devis">
              <el-input
                type="text"
                v-model="filterOrders.id"
                @input="ordersFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label="ID Chiffrage">
              <el-input
                type="text"
                v-model="filterOrders.id_estimate"
                @input="ordersFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Départ`"
            >
              <el-input
                type="text"
                v-model="filterOrders.depart"
                @input="ordersFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Arrival`"
            >
              <el-input
                type="text"
                v-model="filterOrders.arrival"
                @input="ordersFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`E-mail`"
            >
              <el-input
                type="text"
                v-model="filterOrders.email"
                @input="ordersFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Date de départ`"
            >
              <el-date-picker
                v-model="filterOrders.date_depart"
                type="date"
                placeholder="Select Date"
                format="DD-MM-YYYY"
                value-format="YYYY-MM-DD"
                @change="ordersFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Remarque`"
            >
              <el-input
                type="text"
                v-model="filterOrders.note"
                @input="ordersFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Statut">
              <el-select
                v-model="filterOrders.status"
                @change="ordersFilter()"
              >
                <el-option
                  :key="0"
                  label="Tous les statuts"
                  :value="null"
                />
                <el-option
                  v-for="item in orderStatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Billet">
              <el-select
                v-model="filterOrders.path_ticket"
                @change="ordersFilter()"
              >
                <el-option
                  :key="0"
                  label="Tous les statuts"
                  :value="null"
                />
                <el-option
                  :key="1"
                  label="A envoyer"
                  :value="1"
                />
                <el-option
                  :key="2"
                  label="Envoyé"
                  :value="2"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Facture">
              <el-select
                v-model="filterOrders.path_invoice"
                @change="ordersFilter()"
              >
                <el-option
                  :key="0"
                  label="Tous les statuts"
                  :value="null"
                />
                <el-option
                  :key="1"
                  label="A envoyer"
                  :value="1"
                />
                <el-option
                  :key="2"
                  label="Envoyé"
                  :value="2"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </div>
      </el-form>
      <el-table v-if="tmpOrders.length > 0" :data="tmpOrders" stripe
        :default-sort="{ prop: 'date_creation', order: 'descending' }"
      >
        <el-table-column type="expand">
          <template #default="props">
            <el-table style="padding: 0 0 0 50px; box-sizing: border-box" v-if="props.row.payments && props.row.payments.length > 0" :data="props.row.payments" border>
              <el-table-column label="ID" prop="id" width="80"/>
              <el-table-column label="Statut" prop="status" width="150">
                <template #default="{ row }">
                  <div class="status" :style="`background: ${orderStatus[row.status - 1].color}`">{{ orderStatus[row.status - 1].name || ''}}</div>
                </template>
              </el-table-column>
              <el-table-column label="Montant" prop="price" />
              <el-table-column label="Date paiement" prop="date_payment">
                <template #default="{ row }">
                  {{ formattedDatetime(row.date_payment) || ''}}
                </template>
              </el-table-column>
              <el-table-column label="ID Payplug" prop="id_payplug" />
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="Opération">
          <template #default="{ row }">
            <button class="btn-blue" type="button" @click="openEditOrderPopup(row)">
              Consulter
            </button>
            <button v-if="row.payment_installment == 1" class="btn-blue" type="button" @click="duplicatePayment(row)">
              Ajouter un paiement
            </button>
          </template>
        </el-table-column>
        <el-table-column label="Date de création" prop="date_creation" sortable>
          <template #default="{ row }">
            {{ formattedDatetime(row.date_creation) }}
          </template>
        </el-table-column>
        <el-table-column label="Statut" prop="status" sortable>
          <template #default="{ row }">
            <div class="status" :style="`background: ${orderStatus[row.status - 1].color}`">{{ orderStatus[row.status - 1].name || ''}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Date paiement" prop="date_payment" sortable>
          <template #default="{ row }">
            {{ formattedDatetime(row.date_paiement) || ''}}
          </template>
        </el-table-column>
        <el-table-column label="ID Devis" prop="id" width="60" sortable/>
        <el-table-column label="ID Chiffrage" prop="id_estimate" sortable width="60"/>
        <el-table-column label="E-mail" prop="email" sortable>
          <template #default="{ row }">
            <span :title="row.email">{{ row.email ? (row.email.length > 20 ? row.email.slice(0, 20) + '...' : row.email) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Client">
          <template #default="{ row }">
            {{ users.filter((item) => item.email === row.email).length > 0 ? (users.filter((item) => item.email === row.email)[0].firstname 
            + ' ' + users.filter((item) => item.email === row.email)[0].lastname) : 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="Départ" prop="depart" sortable />
        <el-table-column label="Arrivée" prop="arrival" sortable />
        <el-table-column label="Date Départ" prop="date_depart" sortable />
        <el-table-column label="Prix total TTC" prop="price" />
        <el-table-column label="Type paiement" prop="payment_installment" sortable>
          <template #default="{ row }">
            {{row.payment_installment == 1 ? 'Paiement en plusieurs fois' : '1x'}}
          </template>
        </el-table-column>
        <el-table-column label="Reste à payer" prop="payment_installment" sortable>
          <template #default="{ row }">
            {{row.payment_installment == 1 ? row.debt : ''}}
          </template>
        </el-table-column>
        <el-table-column label="Billet" prop="path_ticket" sortable>
          <template #default="{ row }">
            <div class="status" :style="`background: ${row.path_ticket ? '#5cbf2a' : '#ababab'}`">{{ row.path_ticket ? 'Envoyé' : 'À envoyer'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Facture" prop="path_invoice" sortable>
          <template #default="{ row }">
            <div class="status" :style="`background: ${row.path_invoice ? '#5cbf2a' : '#ababab'}`">{{ row.path_invoice ? 'Envoyé' : 'À envoyer'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Remarque" prop="note" />
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un paiement</b>
        <br>
        (Uniquement pour les paiements en plusieurs fois)
        <br />
        <el-form
          style="max-width: 600px; width: 100%; padding: 50px"
          :model="formDataPayment"
          :rules="rulesFormDataPayment"
          ref="ruleFormDataPaymentRef"
          label-width="auto"
          label-position="top"
          status-icon
          scroll-to-error
          v-loading="loadingNewPayment"
          id="formDataPaymentTop"
        >
          <div>
            <el-col :span="24">
              <el-form-item label="Devis ID" required prop="id_main_order">
                <el-select
                  v-model="formDataPayment.id_main_order"
                  placeholder="Sélectionner"
                  size="large"
                  filterable
                  :disabled="true"
                >
                  <el-option
                    v-for="item in orders"
                    :key="item.id"
                    :label="`${item.id}: ${item.price}€ [${item.email}]`"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="`Prix (€)`"
                prop="price"
                required
              >
                <el-input
                  type="number"
                  v-model.number="formDataPayment.price"
                />
              </el-form-item>
            </el-col>
          </div>
          <br>
          <div class="flex space-between">
            <el-button @click="addPayment()" color="#000047">Ajouter</el-button>
          </div>
          <br />
        </el-form>
      </div>
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['orders']" :data="histories['orders']" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <div v-else-if="currentTab === 'estimates'">
      <el-form
        class="searchBar"
        :model="filterEstimates"
        label-width="auto"
        label-position="top"
      >
        <div>
          <el-col :span="1">
            <el-form-item label="ID">
              <el-input
                type="text"
                v-model="filterEstimates.id"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="Nom"
            >
              <el-input
                type="text"
                v-model="filterEstimates.lastname"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="Prénom"
            >
              <el-input
                type="text"
                v-model="filterEstimates.firstname"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="Téléphone"
            >
              <el-input
                type="text"
                v-model="filterEstimates.phone"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="Moto"
            >
              <el-input
                type="text"
                v-model="filterEstimates.quantity_moto"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="Voyageur"
            >
              <el-input
                type="text"
                v-model="filterEstimates.quantity_passenger"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Départ`"
            >
              <el-input
                type="text"
                v-model="filterEstimates.depart"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Arrival`"
            >
              <el-input
                type="text"
                v-model="filterEstimates.arrival"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`E-mail`"
            >
              <el-input
                type="text"
                v-model="filterEstimates.email"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Date de départ`"
            >
              <el-input
                v-model="filterEstimates.date_depart"
                type="text"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Date de retour`"
            >
              <el-input
                v-model="filterEstimates.date_return"
                type="text"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Prix Total TTC`"
            >
              <el-input
                v-model="filterEstimates.price"
                type="text"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="Code promo"
            >
              <el-input
                type="text"
                v-model="filterEstimates.code"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="Année trajet"
            >
              <el-input
                type="text"
                v-model="filterEstimates.year"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Remarque`"
            >
              <el-input
                type="text"
                v-model="filterEstimates.note"
                @input="estimatesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Statut">
              <el-select
                v-model="filterEstimates.status"
                @change="estimatesFilter()"
              >
                <el-option
                  :key="0"
                  label="Tous les statuts"
                  :value="null"
                />
                <el-option
                  v-for="item in estimateStatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Connu grace à">
              <el-select
                v-model="filterEstimates.how"
                @change="estimatesFilter()"
              >
                <el-option
                  :key="0"
                  label="Tous"
                  :value="null"
                />
                <el-option
                  v-for="item in channels"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </div>
      </el-form>
      <el-table v-if="tmpEstimates.length > 0" :data="tmpEstimates" stripe
        :default-sort="{ prop: 'date_request', order: 'descending' }" class="table-estimates"
        :cell-class-name="setCellClass"
      >
        <el-table-column label="Opération" width="90">
          <template #default="{ row }">
            <button class="btn-blue" type="button" @click="openEditEstimatePopup(row)">
              Modifier
            </button>
          </template>
        </el-table-column>
        <el-table-column label="Statut" prop="status" sortable width="230">
          <template #default="{ row }">
            <div class="status" :style="`color: ${estimateStatus[row.status - 1].id == 1 ? (Math.floor((new Date(row.date_request) - new Date()) / (1000 * 60 * 60 * 24)) <= -4 ? 'white' : 'black') : 'white'}; background: ${estimateStatus[row.status - 1].id == 1 ? (Math.floor((new Date(row.date_request) - new Date()) / (1000 * 60 * 60 * 24)) <= -4 ? '#ff8831' : estimateStatus[row.status - 1].color) : estimateStatus[row.status - 1].color}`">{{ estimateStatus[row.status - 1].id == 1 ? (Math.floor((new Date(row.date_request) - new Date()) / (1000 * 60 * 60 * 24)) <= -4 ? '! ' + estimateStatus[row.status - 1].name + ' !' : estimateStatus[row.status - 1].name) : (estimateStatus[row.status - 1].name || '') }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Date demande" prop="date_request" sortable width="150" >
          <template #default="{ row }">
            {{ formattedDatetime(row.date_request) }}
          </template>
        </el-table-column>
        <el-table-column label="Prénom" prop='firstname' sortable min-width="100">
          <template #default="{ row }">
            <span :title="row.firstname">{{ row.firstname ? (row.firstname.length > 10 ? row.firstname.slice(0, 10) + "..." : row.firstname) : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="NOM" prop='lastname' sortable min-width="100">
          <template #default="{ row }">
            <span :title="row.lastname">{{ row.lastname ? (row.lastname.length > 10 ? row.lastname.slice(0, 10) + "..." : row.lastname) : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="E-mail" prop="email" sortable width="200">
          <template #default="{ row }">
            <span :title="row.email">{{ row.email ? (row.email.length > 20 ? row.email.slice(0, 20) + '...' : row.email) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Téléphone" prop="phone" sortable width="100"/>
        <el-table-column label="Type" prop="type" sortable width="90">
          <template #default="{ row }">
            {{ row.type == 1 ? 'Simple' : 'Aller-retour'}}
          </template>
        </el-table-column>
        <el-table-column label="Moto ALLER" prop="quantity_moto" sortable/>
        <el-table-column label="Voyageur ALLER" prop="quantity_passenger" sortable/>
        <el-table-column label="Date ALLER" prop="date_depart" sortable width="100"/>
        <el-table-column label="Ville de départ ALLER" prop="depart" sortable min-width="100">
          <template #default="{ row }">
            <span :title="row.depart">{{ row.depart ? (row.depart.length > 10 ? row.depart.slice(0, 10) + "..." : row.depart) : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Ville d'arrivée ALLER" prop="arrival" sortable min-width="100">
          <template #default="{ row }">
            <span :title="row.arrival">{{ row.arrival ? (row.arrival.length > 10 ? row.arrival.slice(0, 10) + "..." : row.arrival) : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Moto RETOUR" prop="quantity_moto_return" sortable/>
        <el-table-column label="Voyageur RETOUR" prop="quantity_passenger_return" sortable/>
        <el-table-column label="Date RETOUR" prop="date_return" sortable width="100"/>
        <el-table-column label="Ville de départ RETOUR" prop="depart_return" sortable min-width="100">
          <template #default="{ row }">
            <span :title="row.depart_return">{{ row.depart_return ? (row.depart_return.length > 10 ? row.depart_return.slice(0, 10) + "..." : row.depart_return) : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Ville d'arrivée RETOUR" prop="arrival_return" sortable min-width="100">
          <template #default="{ row }">
            <span :title="row.arrival_return">{{ row.arrival_return ? (row.arrival_return.length > 10 ? row.arrival_return.slice(0, 10) + "..." : row.arrival_return) : ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prix total HT" width="150">
          <template #default="{ row }">
            {{ row.price ? (/^-?\d+(\.\d+)?$/.test(row.price.trim()) ? (!row.quantity_passenger && !row.quantity_passenger_return ? (parseFloat(row.price) / 1.2).toFixed(2) :(parseFloat(row.price) / 1.1).toFixed(2)) : (row.price.length > 15 ? row.price.slice(0, 8) + '...' : row.price)) : '0.00' }}
          </template>
        </el-table-column>
        <el-table-column label="TVA" width="60">
          <template #default="{ row }">
            {{ !row.quantity_passenger && !row.quantity_passenger_return ? '20%' : '10%' }}
          </template>
        </el-table-column>
        <el-table-column label="Prix total TTC" prop="price" width="150">
          <template #default="{ row }">
            <span :title="row.price">{{ row.price ? (row.price.length > 15 ? row.price.slice(0, 8) + '...' : row.price) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Année trajet" prop="route_year" sortable width="60">
          <template #default="{ row }">
            {{ row.route_year && /^2\d{3}$/.test(row.route_year) ? row.route_year : ''}}
          </template>
        </el-table-column>
        <el-table-column label="Connu grace à" prop="how" sortable width="200">
          <template #default="{ row }">
            {{ row.how ? channels[row.how - 1].name : ''}}
          </template>
        </el-table-column>
        <el-table-column label="Remarque" prop="note" width="200">
          <template #default="{ row }">
            <span :title="row.note">{{ row.note ? (row.note.length > 20 ? row.note.slice(0, 20) + '...' : row.note) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Code promo" prop="discount_code" sortable width="150"/>
        <el-table-column label="Annulation" prop="insurance" sortable>
          <template #default="{ row }">
            {{ row.insurance == 1 ? 'Oui' : 'Non'}}
          </template>
        </el-table-column>
        <el-table-column label="ID Chiffrage" prop="id" sortable width="60"/>
        <el-table-column label="Dernière modification" prop="date_modification" sortable width="150">
          <template #default="{ row }">
            {{ formattedDatetime(row.date_modification) }}
          </template>
        </el-table-column>
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un chiffrage</b><br /><br />
        <el-form
          style="max-width: 600px; width: 100%; padding: 50px"
          :model="formDataEstimate"
          :rules="rulesFormDataEstimate"
          ref="ruleFormDataEstimateRef"
          label-width="auto"
          label-position="top"
          status-icon
          scroll-to-error
          v-loading="loadingNewEstimate"
          id="formDataEstimateTop"
        >
          <div>
            <el-col :span="24">
              <el-form-item label="Trajet type :">
                <el-radio-group v-model="formDataEstimate.type">
                  <el-radio :value="1">Aller simple</el-radio>
                  <el-radio :value="2">Aller retour</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Trajet ID : départ">
                <el-select
                  v-model="formDataEstimate.id_route_1"
                  placeholder="Sélectionner"
                  size="large"
                  filterable
                >
                  <el-option
                    v-for="item in routes"
                    :key="item.id"
                    :label="`${item.id}:[${formattedDate(item.date_depart)}]${item.depart} ${item.layover1 ? '→ ' + item.layover1 + ' ' : ''}${item.layover2 ? '→ ' + item.layover2 + ' ' : ''}${item.layover3 ? '→ ' + item.layover3 + ' ' : ''}→ ${item.arrival}`"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="formDataEstimate.type == 2">
              <el-form-item label="Trajet ID : retour">
                <el-select
                  v-model="formDataEstimate.id_route_2"
                  placeholder="Sélectionner"
                  size="large"
                  filterable
                >
                  <el-option
                    v-for="item in routes"
                    :key="item.id"
                    :label="`${item.id}:[${formattedDate(item.date_depart)}]${item.depart} ${item.layover1 ? '→ ' + item.layover1 + ' ' : ''}${item.layover2 ? '→ ' + item.layover2 + ' ' : ''}${item.layover3 ? '→ ' + item.layover3 + ' ' : ''}→ ${item.arrival}`"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Ville de départ">
                <Autocomplete
                  :key="refreshAutocomplete"
                  v-model="formDataEstimate.depart"
                ></Autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Ville d'arrivée">
                <Autocomplete
                  :key="refreshAutocomplete"
                  v-model="formDataEstimate.arrival"
                ></Autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Date de départ">
                <el-input
                  v-model="formDataEstimate.date_depart"
                  type="text"
                  placeholder="Date de départ"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="formDataEstimate.type == 2">
              <el-form-item label="Date de retour">
                <el-input
                  v-model="formDataEstimate.date_return"
                  type="text"
                  placeholder="Date de retour"
                />
              </el-form-item>
            </el-col>
            <br><br>
            <el-col :span="12">
              <el-form-item :label="formDataEstimate.type == 1 ? 'Prix TTC par moto (après toutes les réductions)' : 'Prix TTC Aller-retour par moto (après toutes les réductions)'">
                <el-input
                  type="number"
                  v-model.number="formDataEstimate.price_unit"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Taux voyageur supplémentaire (%)">
                <el-input
                  type="number"
                  v-model="formDataEstimate.price_passenger_rate"
                /> = {{ parseFloat((formDataEstimate.price_unit * (formDataEstimate.price_passenger_rate / 100)).toFixed(2)) }}€
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                :label="`Nombre de motos`" 
              >
                <el-input
                  type="number"
                  v-model.number="formDataEstimate.moto_nb"
                  @input="generateMotoListForEstimate"
                />
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="`Nombre de voyageurs`"
              >
                <el-input
                  type="number"
                  v-model.number="formDataEstimate.passenger_nb"
                  @input="generatePassengerListForEstimate"
                />
              </el-form-item>
            </el-col>
            <br>
            <hr>
            <br>
            <el-form-item label="Coordonnées de contact">
              <el-col :span="12">
                <el-form-item label="E-mail" required prop="email">
                  <el-autocomplete
                    style="width: 100%"
                    v-model="formDataEstimate.email"
                    :fetch-suggestions="searchUserForEmail"
                    placeholder="E-mail"
                    @select="handleSelectEmail"
                    value-key="email"
                  ></el-autocomplete>
                </el-form-item><br>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Téléphone" required prop="phone">
                  <el-input type="text" v-model="formDataEstimate.phone"/>
                </el-form-item><br>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Prénom" required prop="firstname">
                  <el-input type="text" v-model="formDataEstimate.firstname"/>
                </el-form-item><br>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Nom" required prop="lastname">
                  <el-input
                    type="text"
                    v-model="formDataEstimate.lastname"
                  />
                </el-form-item><br>
              </el-col>
            </el-form-item>
            <br>
            <hr>
            <br>
            <div
              v-for="(moto, index) in formDataEstimate.moto_list"
              :key="index"
            >
              <el-form-item
                v-if="index === 0"
                label="Liste des motos :"
              ></el-form-item>
              <el-col :span="24">
                <el-form-item>
                  <el-input type="text" v-model="moto.model" placeholder="Modèle" />
                </el-form-item>
              </el-col>
            </div>
            <div v-if="formDataEstimate.passenger_list.length > 0">
              <br>
              <hr>
              <br>
              <div
                v-for="(passenger, index) in formDataEstimate.passenger_list"
                :key="index"
              >
                <el-form-item
                  v-if="index === 0"
                  label="Liste des voyageurs :"
                ></el-form-item>
                <el-col :span="6">
                  <el-form-item>
                    <el-input type="text" v-model="passenger.firstname" placeholder="Prénom"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input type="text" v-model="passenger.lastname" placeholder="NOM"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-input type="text" v-model="passenger.email" placeholder="E-mail"/>
                  </el-form-item>
                </el-col>
              </div>
            </div>
            <br>
            <hr>
            <br>
            <div v-if="formDataEstimate.passenger_nb === 0">
              <br><br>
              <el-col :span="24">
                <el-form-item >
                  <el-checkbox-group v-model="formDataEstimate.charge">
                    <el-checkbox value="true" name="charge">
                      Adresse de chargement personnalisée : 
                      <el-input type="number" v-model.number="formDataEstimate.price_charge" style="width: 50px"/>€ TTC
                      <el-form-item v-if="formDataEstimate.charge.length > 0">
                        <el-input v-model="formDataEstimate.address_charge" placeholder="Adresse de chargement"/>
                      </el-form-item>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>    

              <br><br>

              <el-col :span="24">
                <el-form-item >
                  <el-checkbox-group v-model="formDataEstimate.discharge">
                    <el-checkbox value="true" name="discharge">
                      Adresse de déchargement personnalisée : 
                      <el-input type="number" v-model.number="formDataEstimate.price_discharge" style="width: 50px"/>€ TTC
                      <el-form-item v-if="formDataEstimate.discharge.length > 0">
                        <el-input v-model="formDataEstimate.address_discharge" placeholder="Adresse de déchargement"/>
                      </el-form-item>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>  
            </div>  

            <br>
            <br>

            <el-col :span="24">
              <el-form-item prop="insurance">
                <el-checkbox-group v-model="formDataEstimate.insurance">
                  <el-checkbox value="true" name="insuranceReservation">
                    Assurance annulation : 
                    <el-input type="number" v-model.number="formDataEstimate.price_insurance_rate" style="width: 50px"/>% 
                    {{ (formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                    ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ?
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 ) 
                      <= 500 ? 50 : 
                      Math.floor(((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) * 
                      (formDataEstimate.price_insurance_rate / 100)) }}€ TTC
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <br /><br />
            <el-col :span="24">
              <el-form-item label="Code promo">
                <el-input type="text" v-model="formDataEstimate.code" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Comment avez-vous entendu parler de nous ?">
                <el-select
                  v-model="formDataEstimate.how"
                  placeholder="Sélectionner"
                  size="large"
                >
                  <el-option
                    v-for="item in channels"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Commentaire client :">
                {{formDataEstimate.comment}}
              </el-form-item>
            </el-col>
            <el-col :span="24"></el-col>
            <div v-if="formDataEstimate.price_unit > 0">
              <hr v-if="formDataEstimate.price_unit > 0"> 
              <el-col v-if="formDataEstimate.price_unit > 0" :span="24"></el-col>
              <el-col v-if="formDataEstimate.price_unit > 0" :span="24"><b>Chiffrage (Prix HT/TTC) :</b></el-col>
              <el-col v-if="formDataEstimate.price_unit > 0" :span="24"></el-col>
              <div class="price-details">
                <el-col v-if="formDataEstimate.price_unit > 0 && formDataEstimate.code" :span="24">Code promo <b>{{ formDataEstimate.code }}</b> appliqué</el-col>
                <el-col v-if="formDataEstimate.price_unit > 0 && formDataEstimate.code" :span="24"></el-col>
                <el-col v-if="formDataEstimate.price_unit > 0 && formDataEstimate.moto_nb > 0" :span="24" style="font-size: 15px;">- Moto <b>x{{formDataEstimate.moto_nb}}</b> = {{(parseFloat(formDataEstimate.price_unit) * formDataEstimate.moto_nb / (formDataEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{(parseFloat(formDataEstimate.price_unit) * formDataEstimate.moto_nb).toFixed(2) }}€</b></el-col>
                <el-col v-if="formDataEstimate.price_unit > 0 && formDataEstimate.passenger_nb > 0" :span="24" style="font-size: 15px;">- Voyageur <b>x{{formDataEstimate.passenger_nb}}</b> = {{formDataEstimate.passenger_nb <= formDataEstimate.moto_nb ? "0" : parseFloat((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit / 1.1).toFixed(2) }}€ / <b>{{formDataEstimate.passenger_nb <= formDataEstimate.moto_nb ? "0" : parseFloat((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit).toFixed(2) }}€</b></el-col>
                <el-col v-if="formDataEstimate.price_unit > 0" :span="24"></el-col>
                <el-col v-if="formDataEstimate.price_unit > 0 && formDataEstimate.insurance.length > 0" :span="24" style="font-size: 15px;">- Assurance annulation = {{ 
                ((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) <= 500 ? parseFloat(50 / (formDataEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) : 
                      Math.floor(((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) * (formDataEstimate.price_insurance_rate / 100) / (formDataEstimate.passenger_nb > 0 ? 1.1 : 1.2)) }}€ / <b>
                      {{ ((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) * (formDataEstimate.price_insurance_rate / 100)) }}€</b></el-col>
                <el-col v-if="formDataEstimate.price_unit > 0 && formDataEstimate.charge.length > 0" :span="24" style="font-size: 15px;">- Adresse de chargement personnalisée = {{ parseFloat(formDataEstimate.price_charge / (formDataEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ formDataEstimate.price_charge }}€</b></el-col>
                <el-col v-if="formDataEstimate.price_unit > 0 && formDataEstimate.discharge.length > 0" :span="24" style="font-size: 15px;">- Adresse de déchargement personnalisée = {{ parseFloat(formDataEstimate.price_discharge / (formDataEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ formDataEstimate.price_discharge }}€</b></el-col>
              </div>
              <el-col v-if="formDataEstimate.price_unit > 0" :span="24"></el-col>
              <el-col v-if="formDataEstimate.price_unit > 0" :span="24" style="color: #e6007e; font-size: 20px; text-align: right;"><b>
                Total : {{ parseFloat((parseFloat(((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 ))) + 
                  (formDataEstimate.insurance.length > 0 ? 
                  ((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) * (formDataEstimate.price_insurance_rate / 100))
                  : 0) + 
                  (formDataEstimate.charge.length > 0 ? formDataEstimate.price_charge : 0) +
                  (formDataEstimate.discharge.length > 0 ? formDataEstimate.price_discharge : 0)) / (formDataEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ HT / {{ parseFloat(parseFloat(((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 ))) + 
                  (formDataEstimate.insurance.length > 0 ? 
                  ((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formDataEstimate.price_unit * formDataEstimate.moto_nb) + 
                      ((formDataEstimate.passenger_nb - formDataEstimate.moto_nb) > 0 ? 
                      (formDataEstimate.passenger_nb - formDataEstimate.moto_nb) * 
                      (formDataEstimate.price_passenger_rate / 100) * formDataEstimate.price_unit : 0 )) * (formDataEstimate.price_insurance_rate / 100))
                  : 0) + 
                  (formDataEstimate.charge.length > 0 ? formDataEstimate.price_charge : 0) +
                  (formDataEstimate.discharge.length > 0 ? formDataEstimate.price_discharge : 0)).toFixed(2) }}€ TTC</b></el-col>
              <el-col v-if="formDataEstimate.price_unit > 0" :span="24"></el-col>
              <el-col :span="24"></el-col>
            </div>
            
            <el-col :span="24">
              <el-form-item label="Statut">
                <el-select
                  v-model="formDataEstimate.status"
                  placeholder="Sélectionner"
                  size="large"
                >
                  <el-option
                    v-for="item in estimateStatus"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Remarque :">
                <el-input
                  type="textarea"
                  v-model="formDataEstimate.note"
                />
              </el-form-item>
            </el-col>
            <br>
          </div>
          <div class="flex space-between">
            <el-button @click="addEstimate()" color="#000047">Ajouter</el-button>
          </div>
          <br />
        </el-form>
      </div>
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['estimates']" :data="histories['estimates']" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else-if="currentTab === 'events'">
      <el-table v-if="events.length > 0" :data="events" stripe>
        <el-table-column type="expand">
          <template #default="props">
            <div class="tr-edit">
              <img v-if="props.row.img" :src="props.row.img" alt="" />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpEvents[
                    events.findIndex((item) => item.id === props.row.id)
                  ].img
                "
                placeholder="Image d'event(url)"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpEvents[
                    events.findIndex((item) => item.id === props.row.id)
                  ].name
                "
                placeholder="Nom d'event"
              />
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpEvents[
                    events.findIndex((item) => item.id === props.row.id)
                  ].location
                "
                placeholder="Lieu d'event(Ville)"
              />
              <br /><br />
              <div class="editor-con">
                <textarea
                  v-model="
                    tmpEvents[
                      events.findIndex((item) => item.id === props.row.id)
                    ].details
                  "
                ></textarea>
              </div>
              <br /><br />
              <input
                type="text"
                v-model="
                  tmpEvents[
                    events.findIndex((item) => item.id === props.row.id)
                  ].link
                "
                placeholder="Lien d'event(url)"
              />
              <br /><br />
              Date de début
              <input
                type="date"
                v-model="
                  tmpEvents[
                    events.findIndex((item) => item.id === props.row.id)
                  ].date_start
                "
              />
              <br /><br />
              Date de fin
              <input
                type="date"
                v-model="
                  tmpEvents[
                    events.findIndex((item) => item.id === props.row.id)
                  ].date_end
                "
              />
              <br /><br />
              <div class="flex space-between">
                <button class="btn-blue" type="button" @click="confirmDelEvent(props.row)">
                  Supprimer
                </button>
                <button class="btn-blue" type="button" @click="editEventSend(props.row)">
                  Modifier
                </button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Date de début">
          <template #default="{ row }">
            {{
              formattedDate(
                events[events.findIndex((item) => item.id === row.id)]
                  .date_start
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="Date de fin">
          <template #default="{ row }">
            {{
              formattedDate(
                events[events.findIndex((item) => item.id === row.id)].date_end
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="Nom d'event" prop="name" />
        <el-table-column label="Location" prop="location" />
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un event</b><br /><br />
        <form id="formEvent">
          <input
            type="text"
            v-model="formDataEvent.link"
            placeholder="Event URL"
          /><br />
          <input
            type="text"
            v-model="formDataEvent.img"
            placeholder="Event Img URL"
          /><br />
          <img v-if="formDataEvent.img" :src="formDataEvent.img" alt="" /><br />
          <input
            type="text"
            v-model="formDataEvent.name"
            placeholder="Event Name"
          />
          <input
            type="text"
            v-model="formDataEvent.location"
            placeholder="Event Location"
          /><br />
          <textarea
            type="text"
            v-model="formDataEvent.details"
            placeholder="Event Description"
          ></textarea>
          <br /><br />
          Date de début
          <input type="date" v-model="formDataEvent.date_start" /><br />
          Date de fin
          <input type="date" v-model="formDataEvent.date_end" /><br /><br />
          <button type="button" class="btn-blue" @click="addEventSend()">
            Ajouter
          </button>
        </form>
      </div>
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['events']" :data="histories['events']" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else-if="currentTab === 'routes'">
      <el-form
        class="searchBar"
        :model="filterRoutes"
        label-width="auto"
        label-position="top"
      >
        <div>
          <el-col :span="1">
            <el-form-item label="ID">
              <el-input
                type="text"
                v-model="filterRoutes.id"
                @input="routesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Départ`"
            >
              <el-input
                type="text"
                v-model="filterRoutes.depart"
                @input="routesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Arrival`"
            >
              <el-input
                type="text"
                v-model="filterRoutes.arrival"
                @input="routesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Escale 1`"
            >
              <el-input
                type="text"
                v-model="filterRoutes.layover1"
                @input="routesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Escale 2`"
            >
              <el-input
                type="text"
                v-model="filterRoutes.layover2"
                @input="routesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Escale 3`"
            >
              <el-input
                type="text"
                v-model="filterRoutes.layover3"
                @input="routesFilter()"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`Date de départ`"
            >
              <el-date-picker
                v-model="filterRoutes.date_depart"
                type="date"
                placeholder="Select Date"
                format="DD-MM-YYYY"
                value-format="YYYY-MM-DD"
                @change="routesFilter()"
              />
            </el-form-item>
          </el-col>
        </div>
      </el-form>

      <el-table
        v-if="filteredRoutes.length > 0"
        :data="filteredRoutes"
        :default-sort="{ prop: 'date_depart', order: 'descending' }"
        stripe
      >
        <el-table-column type="expand">
          <template #default="props">
            <div class="tr-edit">
              <form>
                Date de départ:
                <input
                  type="date"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].date_depart
                  "
                  placeholder="Date de départ"
                  require
                />
                <br />
                <br />
                Complet ? :
                <input
                  type="checkbox"
                  id="completed"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].completed
                  "
                  value="true"
                />
                <br />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete
                    :disabled="true"
                    :required="true"
                    v-model="
                      tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart
                    "
                    placeholder="Départ"
                    :require="true"
                    class="autocomplete"
                  />
                </div>
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].address_depart
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].time0
                  "
                />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete
                    :disabled="true"
                    :required="true"
                    v-model="
                      tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival
                    "
                    placeholder="Destination"
                    class="autocomplete"
                  ></Autocomplete>
                </div>
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].address_arrival
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].time4
                  "
                />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete
                    v-model="
                      tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1
                    "
                    placeholder="Escale 1"
                    class="autocomplete"
                    :disabled="true"
                  ></Autocomplete>
                </div>
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].address_layover1
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].time1
                  "
                />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete
                    v-model="
                      tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2
                    "
                    placeholder="Escale 2"
                    class="autocomplete"
                    :disabled="true"
                  ></Autocomplete>
                </div>
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].address_layover2
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].time2
                  "
                />
                <br />
                <div style="max-width: 475px">
                  <Autocomplete
                    v-model="
                      tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3
                    "
                    placeholder="Escale 3"
                    class="autocomplete"
                    :disabled="true"
                  ></Autocomplete>
                </div>
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].address_layover3
                  "
                  placeholder="Adresse"
                  class="autocomplete"
                />
                <input
                  type="time"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].time3
                  "
                />
                <br /><br /><br />
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].depart
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].arrival
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price0_4
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price0_4
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price0_4
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_0_4
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [0, 4])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_1
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_1
                  }}
                </b>
                <br /><br /><hr /><br />
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].depart
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover1
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price0_1
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price0_1
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price0_1
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_0_1
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [0, 1])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_1
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_1
                  }}
                </b>
                <br /><br /><hr><br>
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].depart
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover2
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price0_2
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price0_2
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price0_2
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_0_2
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [0, 2])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_1
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_1
                  }}
                </b>
                <br /><br /><hr><br>
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].depart
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].depart.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover3
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price0_3
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price0_3
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price0_3
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_0_3
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [0, 3])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_1
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_1
                  }}
                </b>
                <br /><br /><hr><br>
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover1
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover2
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price1_2
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price1_2
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price1_2
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_1_2
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [1, 2])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_2
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_2
                  }}
                </b>
                <br /><br /><hr><br>
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover1
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover3
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price1_3
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price1_3
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price1_3
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_1_3
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [1, 3])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_2
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_2
                  }}
                </b>
                <br /><br /><hr><br>
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover1
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover1.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].arrival
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price1_4
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price1_4
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price1_4
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_1_4
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [1, 4])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_2
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_2
                  }}
                </b>
                <br /><br /><hr><br>
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover2
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover3
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price2_3
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price2_3
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price2_3
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_2_3
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [2, 3])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_3
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_3
                  }}
                </b>
                <br /><br /><hr><br>
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover2
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover2.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].arrival
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price2_4
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price2_4
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price2_4
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_2_4
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [2, 4])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_3
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_3
                  }}
                </b>
                <br /><br /><hr><br>
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].layover3
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].layover3.split(",")[0] + " - "
                    : "N - "
                }}
                {{
                  tmpRoutes[
                    routes.findIndex((item) => item.id === props.row.id)
                  ].arrival
                    ? tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].arrival.split(",")[0] + " : "
                    : "N : "
                }}
                <input
                  type="text"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price3_4
                  "
                  placeholder="Prix"
                /><span
                  v-if="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  style="color: #c50909"
                  ><b>{{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type == "1"
                      ? parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price3_4
                        ) *
                        (1 -
                          parseInt(
                            tmpRoutes[
                              routes.findIndex(
                                (item) => item.id === props.row.id
                              )
                            ].discount_value
                          ) /
                            100)
                      : parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].price3_4
                        ) -
                        parseInt(
                          tmpRoutes[
                            routes.findIndex(
                              (item) => item.id === props.row.id
                            )
                          ].discount_value
                        )
                  }}</b
                  >€</span
                >
                Frais voyageur supplémentaire
                <input
                  type="number"
                  v-model.number="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].price_passenger_3_4
                  "
                  placeholder="Frais voyageur supplémentaire"
                />%
                <button class="btn-rose" type="button"
                  @click="openReservationPopup(tmpRoutes[routes.findIndex((item) => item.id === props.row.id)], [3, 4])"
                >
                  Réserver pour un client
                </button>
                <br />
                Place(s) restante(s) Moto / Passager
                <b>
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_moto_4
                  }}
                  /
                  {{
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].places_passenger_4
                  }}
                </b>
                <br /><br />
                <br />
                Nombre de moto max
                <input
                  type="number"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].moto_max
                  "
                  placeholder="Moto max"
                  require
                  :disabled="true"
                />
                <br />
                Nombre de voyageur max
                <input
                  type="number"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].passenger_max
                  "
                  placeholder="Voyageur max"
                  require
                  :disabled="true"
                />
                <br />
                <br />
                <input
                  type="number"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_value
                  "
                  placeholder="Combien de réduction ?"
                />
                <select
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_type
                  "
                >
                  <option value="1">%</option>
                  <option value="2">€</option></select
                >de réduction
                <br />
                <br />
                Tarif groupe:
                <input
                  type="number"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_group_value
                  "
                  placeholder="Combien de réduction ?"
                />% pour ≥
                <input
                  type="number"
                  v-model="
                    tmpRoutes[
                      routes.findIndex((item) => item.id === props.row.id)
                    ].discount_group_moto
                  "
                />
                moto
                <br />
                <br />
                <div class="editor-con">
                  Economie<br />
                  <QuillEditor
                    :options="optionsQuill"
                    v-model:content="
                      tmpRoutes[
                        routes.findIndex(
                          (item) => item.id === props.row.id
                        )
                      ].eco
                    "
                    contentType="html"
                  />
                </div>
                <br /><br />
                <div class="flex space-between">
                  <button
                    class="btn-blue"
                    type="button"
                    @click="confirmDelRoute(props.row)"
                  >
                    Supprimer
                  </button>
                  <button
                    type="button"
                    class="btn-blue"
                    @click="editRouteSend(props.row)"
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" sortable width="70"/>
        <el-table-column prop="date_depart" label="Date de départ" sortable width="150">
          <template #default="{ row }">
            {{
              formattedDate(
                tmpRoutes[
                  routes.findIndex((item) => item.id === row.id)
                ].date_depart
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="Trajet" prop="depart" sortable>
          <template #default="{ row }">
            <span style="color: black"
              ><b>{{
                tmpRoutes[
                  routes.findIndex((item) => item.id === row.id)
                ].depart.split(",")[0]
              }}</b></span
            >
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover1
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover1.split(",")[0]
                : ""
            }}</span>
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover2
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover2.split(",")[0]
                : ""
            }}</span>
            <span style="color: #e6007e">{{
              tmpRoutes[
                routes.findIndex((item) => item.id === row.id)
              ].layover3
                ? " → " +
                  tmpRoutes[
                    routes.findIndex((item) => item.id === row.id)
                  ].layover3.split(",")[0]
                : ""
            }}</span>
            →
            <span style="color: black"
              ><b>{{
                tmpRoutes[
                  routes.findIndex((item) => item.id === row.id)
                ].arrival.split(",")[0]
              }}</b></span
            >
          </template>
        </el-table-column>
        <el-table-column prop="date_creation" label="Date de création" sortable width="150">
          <template #default="{ row }">
            {{
              formattedDate(
                tmpRoutes[
                  routes.findIndex((item) => item.id === row.id)
                ].date_creation
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="Opération">
          <template #default="{ row }">
            <button class="btn-blue" type="button" @click="duplicateRoute(row)">
              Dupliquer
            </button>
            <button class="btn-blue" type="button" @click="showOrderListe(row)">Liste Devis</button>
          </template>
        </el-table-column>
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un trajet</b><br /><br />
        <form id="formRoute" @submit.prevent="submitForm">
          Date de départ:
          <input
            type="date"
            v-model="formDataRoute.date_depart"
            placeholder="Date de départ"
          />
          <br />
          <br />
          Complet ? :
          <input
            type="checkbox"
            id="completed"
            v-model="formDataRoute.completed"
            value="true"
          />
          <br />
          <br />
          <div style="max-width: 475px">
            <Autocomplete
              :required="true"
              v-model="formDataRoute.depart"
              placeholder="Départ"
              class="autocomplete"
              :key="refreshAutocomplete"
            />
          </div>
          <input
            type="text"
            v-model="formDataRoute.address_depart"
            placeholder="Adresse"
            class="autocomplete"
          />
          <input type="time" v-model="formDataRoute.time0" />
          <br />
          <div style="max-width: 475px">
            <Autocomplete
              :required="true"
              v-model="formDataRoute.arrival"
              placeholder="Destination"
              class="autocomplete"
              :key="refreshAutocomplete"
            />
          </div>
          <input
            type="text"
            v-model="formDataRoute.address_arrival"
            placeholder="Adresse"
            class="autocomplete"
          />
          <input type="time" v-model="formDataRoute.time4" />
          <br />
          <div style="max-width: 475px">
            <Autocomplete
              v-model="formDataRoute.layover1"
              placeholder="Escale 1"
              @change="console.log(111, formDataRoute.layover1)"
              class="autocomplete"
              :key="refreshAutocomplete"
            />
          </div>
          <input
            type="text"
            v-model="formDataRoute.address_layover1"
            placeholder="Adresse"
            class="autocomplete"
          />
          <input type="time" v-model="formDataRoute.time1" />
          <br />
          <div style="max-width: 475px">
            <Autocomplete
              v-model="formDataRoute.layover2"
              placeholder="Escale 2"
              class="autocomplete"
              :key="refreshAutocomplete"
            />
          </div>
          <input
            type="text"
            v-model="formDataRoute.address_layover2"
            placeholder="Adresse"
            class="autocomplete"
          />
          <input type="time" v-model="formDataRoute.time2" />
          <br />
          <div style="max-width: 475px">
            <Autocomplete
              v-model="formDataRoute.layover3"
              placeholder="Escale 3"
              class="autocomplete"
              :key="refreshAutocomplete"
            />
          </div>
          <input
            type="text"
            v-model="formDataRoute.address_layover3"
            placeholder="Adresse"
            class="autocomplete"
          />
          <input type="time" v-model="formDataRoute.time3" />
          <br /><br />
          {{
            formDataRoute.depart
              ? formDataRoute.depart.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.arrival
              ? formDataRoute.arrival.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price0_4"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price0_4) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price0_4) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_0_4"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.depart
              ? formDataRoute.depart.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.layover1
              ? formDataRoute.layover1.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price0_1"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price0_1) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price0_1) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_0_1"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.depart
              ? formDataRoute.depart.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.layover2
              ? formDataRoute.layover2.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price0_2"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price0_2) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price0_2) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_0_2"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.depart
              ? formDataRoute.depart.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.layover3
              ? formDataRoute.layover3.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price0_3"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price0_3) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price0_3) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_0_3"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.layover1
              ? formDataRoute.layover1.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.layover2
              ? formDataRoute.layover2.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price1_2"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price1_2) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price1_2) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_1_2"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.layover1
              ? formDataRoute.layover1.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.layover3
              ? formDataRoute.layover3.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price1_3"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price1_3) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price1_3) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_1_3"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.layover1
              ? formDataRoute.layover1.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.arrival
              ? formDataRoute.arrival.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price1_4"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price1_4) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price1_4) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_1_4"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.layover2
              ? formDataRoute.layover2.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.layover3
              ? formDataRoute.layover3.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price2_3"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price2_3) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price2_3) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_2_3"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.layover2
              ? formDataRoute.layover2.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.arrival
              ? formDataRoute.arrival.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price2_4"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price2_4) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price2_4) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_2_4"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          {{
            formDataRoute.layover3
              ? formDataRoute.layover3.split(",")[0] + " - "
              : "N - "
          }}
          {{
            formDataRoute.arrival
              ? formDataRoute.arrival.split(",")[0] + " : "
              : "N : "
          }}
          <input
            type="text"
            v-model="formDataRoute.price3_4"
            placeholder="Prix"
          /><span
            v-if="formDataRoute.discount_value"
            style="color: #c50909"
            ><b>{{
              formDataRoute.discount_type == "1"
                ? parseInt(formDataRoute.price3_4) *
                  (1 - parseInt(formDataRoute.discount_value) / 100)
                : parseInt(formDataRoute.price3_4) -
                  parseInt(formDataRoute.discount_value)
            }}</b
            >€</span
          > 
          Frais voyageur supplémentaire
          <input
            type="number"
            v-model.number="formDataRoute.price_passenger_3_4"
            placeholder="Frais voyageur supplémentaire"
          />%
          <br />
          <br />
          Nombre de moto max
          <input
            type="number"
            v-model="formDataRoute.moto_max"
            placeholder="Moto max"
          />
          <br />
          Nombre de voyageur max
          <input
            type="number"
            v-model="formDataRoute.passenger_max"
            placeholder="Voyageur max"
          />
          <br />
          <br />
          <input
            type="number"
            v-model="formDataRoute.discount_value"
            placeholder="Combien de réduction"
          />
          <select v-model="formDataRoute.discount_type">
            <option value="1">%</option>
            <option value="2">€</option></select
          >de réduction
          <br />
          <br />
          Tarif groupe:
          <input
            type="number"
            v-model="formDataRoute.discount_group_value"
            placeholder="Combien de réduction"
          />% pour ≥
          <input
            type="number"
            v-model="formDataRoute.discount_group_moto"
          />
          moto
          <br />
          <br />
          <div class="editor-con">
            Economie<br />
            <QuillEditor
              :options="optionsQuill"
              v-model:content="formDataRoute.eco"
              contentType="html"
            />
          </div>
          <br />
          <button type="submit" class="btn-blue" @click="addRouteSend()">
            Ajouter
          </button>
        </form>
      </div>
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['routes']" :data="histories['routes']" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else-if="currentTab === 'news'">
      <el-table v-if="news.length > 0" :data="news" stripe>
        <el-table-column type="expand">
          <template #default="props">
            <div class="tr-edit">
              <img
                v-if="props.row.cover"
                :src="`${config.API_BASE_URL}/assets/images/news/${props.row.cover}`"
                alt=""
              />
              <video
                v-if="props.row.video"
                :src="`${config.API_BASE_URL}/assets/videos/news/${props.row.video}`"
                controls="controls"
                autoplay
                mueted
              ></video>
              <br /><br />
              Titre(*):
              <input
                type="text"
                v-model="
                  tmpNews[news.findIndex((item) => item.id === props.row.id)]
                    .title
                "
                placeholder="Titre"
              />
              <br /><br />
              <div class="editor-con">
                <QuillEditor
                  :options="optionsQuill"
                  v-model:content="
                    tmpNews[news.findIndex((item) => item.id === props.row.id)]
                      .html
                  "
                  contentType="html"
                />
              </div>
              <br />
              Cover:
              <input
                type="file"
                @change="(event) => handleImageUploadEdit(props.row, event)"
                accept="image/*"
              />
              <br /><br />
              Vidéo:
              <input
                type="file"
                @change="(event) => handleVideoUploadEdit(props.row, event)"
                accept="video/*"
              />
              <br /><br />
              <div class="flex space-between">
                <button class="btn-blue" type="button" @click="confirmDelNews(props.row)">
                  Supprimer
                </button>
                <button class="btn-blue" type="button" @click="editNews(props.row)">
                  Modifier
                </button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Publication">
          <template #default="{ row }">
            {{
              formattedDate(
                tmpNews[news.findIndex((item) => item.id === row.id)]
                  .date_publication
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="Titre" prop="title" />
        <el-table-column label="Dernière modification">
          <template #default="{ row }">
            {{
              formattedDate(
                tmpNews[news.findIndex((item) => item.id === row.id)]
                  .date_lastedit
              )
            }}
          </template>
        </el-table-column>
      </el-table>
      <hr />
      <div class="news-add">
        <b>Ajouter un article</b><br /><br />
        Titre(*):
        <input type="text" v-model="formDataNews.title" placeholder="Titre" />
        <br /><br />
        <div class="editor-con">
          <QuillEditor
            :options="optionsQuill"
            v-model:content="formDataNews.html"
            contentType="html"
          />
        </div>
        <br />
        Cover:
        <input type="file" @change="handleImageUpload" accept="image/*" />
        <br /><br />
        Vidéo:
        <input type="file" @change="handleVideoUpload" accept="video/*" />
        <br /><br />
        <button class="btn-blue" type="button" @click="addNews()">Ajouter</button>
      </div>
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['news']" :data="histories['news']" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </div>


    
    
    <el-dialog center :title="`Chiffrage ID ${formEditEstimate.id}`" color="white" v-model="showEstimatePopup" :close-on-click-modal="false">
      <el-form
        style="max-width: 600px; width: 100%; padding: 50px"
        :model="formEditEstimate"
        label-width="auto"
        label-position="top"
        status-icon
        scroll-to-error
        v-loading="loadingEstimate"
        id="formEditEstimateTop"
      >
        <div>
          <el-col :span="24">
            <el-form-item label="Trajet type :">
              <el-radio-group v-model="formEditEstimate.type">
                <el-radio :value="1">Aller simple</el-radio>
                <el-radio :value="2">Aller retour</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="Trajet ID : aller">
              <el-select
                v-model="formEditEstimate.id_route_1"
                placeholder="Sélectionner"
                size="large"
                filterable
              >
                <el-option
                  v-for="item in routes"
                  :key="item.id"
                  :label="`${item.id}:[${formattedDate(item.date_depart)}]${item.depart} ${item.layover1 ? '→ ' + item.layover1 + ' ' : ''}${item.layover2 ? '→ ' + item.layover2 + ' ' : ''}${item.layover3 ? '→ ' + item.layover3 + ' ' : ''}→ ${item.arrival}`"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="formEditEstimate.type == 2">
            <el-form-item label="Trajet ID : retour">
              <el-select
                v-model="formEditEstimate.id_route_2"
                placeholder="Sélectionner"
                size="large"
                filterable
              >
                <el-option
                  v-for="item in routes"
                  :key="item.id"
                  :label="`${item.id}:[${formattedDate(item.date_depart)}]${item.depart} ${item.layover1 ? '→ ' + item.layover1 + ' ' : ''}${item.layover2 ? '→ ' + item.layover2 + ' ' : ''}${item.layover3 ? '→ ' + item.layover3 + ' ' : ''}→ ${item.arrival}`"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="Ville de départ">
              <Autocomplete
                :key="refreshAutocomplete"
                v-model="formEditEstimate.depart"
              ></Autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Ville d'arrivée">
              <Autocomplete
                :key="refreshAutocomplete"
                v-model="formEditEstimate.arrival"
              ></Autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Date de départ">
              <el-input
                v-model="formEditEstimate.date_depart"
                type="text"
                placeholder="Date de départ"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="formEditEstimate.type == 2">
            <el-form-item label="Date de retour">
              <el-input
                v-model="formEditEstimate.date_return"
                type="text"
                placeholder="Date de retour"
              />
            </el-form-item>
          </el-col>
          <br><br>
          <el-col :span="12">
            <el-form-item label="Prix TTC par moto (après toutes les réductions)">
              <el-input
                type="number"
                v-model.number="formEditEstimate.price_unit"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Taux voyageur supplémentaire (%)">
              <el-input
                type="number"
                v-model="formEditEstimate.price_passenger_rate"
              /> = {{ parseFloat((formEditEstimate.price_unit * (formEditEstimate.price_passenger_rate / 100)).toFixed(2)) }}€
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item 
              :label="`Nombre de motos`" 
            >
              <el-input
                type="number"
                v-model.number="formEditEstimate.moto_nb"
                @input="generateMotoListForEstimate"
              />
            </el-form-item><br>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="`Nombre de voyageurs`"
            >
              <el-input
                type="number"
                v-model.number="formEditEstimate.passenger_nb"
                @input="generatePassengerListForEstimate"
              />
            </el-form-item>
          </el-col>
          <br>
          <hr>
          <br>
          <el-form-item label="Coordonnées de contact">
            <el-col :span="12">
              <el-form-item label="Prénom" required prop="firstname">
                <el-input type="text" v-model="formEditEstimate.user.firstname" :disabled="true"/>
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Nom" required prop="lastname">
                <el-input
                  type="text"
                  v-model="formEditEstimate.user.lastname"
                  :disabled="true"
                />
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="E-mail" required prop="email">
                <el-input type="email" v-model="formEditEstimate.user.email" :disabled="true"/>
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Téléphone" required prop="phone">
                <el-input type="text" v-model="formEditEstimate.user.phone" :disabled="true"/>
              </el-form-item><br>
            </el-col>
          </el-form-item>
          <br>
          <hr>
          <br>
          <div
            v-for="(moto, index) in formEditEstimate.moto_list"
            :key="index"
          >
            <el-form-item
              v-if="index === 0"
              label="Liste des motos :"
            ></el-form-item>
            <el-col :span="24">
              <el-form-item>
                <el-input type="text" v-model="moto.model" placeholder="Modèle" />
              </el-form-item>
            </el-col>
          </div>
          <div v-if="formEditEstimate.passenger_list.length > 0">
            <br>
            <hr>
            <br>
            <div
              v-for="(passenger, index) in formEditEstimate.passenger_list"
              :key="index"
            >
              <el-form-item
                v-if="index === 0"
                label="Liste des voyageurs :"
              ></el-form-item>
              <el-col :span="6">
                <el-form-item>
                  <el-input type="text" v-model="passenger.firstname" placeholder="Prénom"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-input type="text" v-model="passenger.lastname" placeholder="NOM"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-input type="text" v-model="passenger.email" placeholder="E-mail"/>
                </el-form-item>
              </el-col>
            </div>
          </div>
          <br>
          <hr>
          <br>
          <div v-if="formEditEstimate.passenger_nb === 0">
            <br><br>
            <el-col :span="24">
              <el-form-item >
                <el-checkbox-group v-model="formEditEstimate.charge">
                  <el-checkbox value="true" name="charge">
                    Adresse de chargement personnalisée : 
                    <el-input type="number" v-model.number="formEditEstimate.price_charge" style="width: 50px"/>€ TTC
                    <el-form-item v-if="formEditEstimate.charge.length > 0">
                      <el-input v-model="formEditEstimate.address_charge" placeholder="Adresse de chargement"/>
                    </el-form-item>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>    

            <br><br>

            <el-col :span="24">
              <el-form-item >
                <el-checkbox-group v-model="formEditEstimate.discharge">
                  <el-checkbox value="true" name="discharge">
                    Adresse de déchargement personnalisée : 
                    <el-input type="number" v-model.number="formEditEstimate.price_discharge" style="width: 50px"/>€ TTC
                    <el-form-item v-if="formEditEstimate.discharge.length > 0">
                      <el-input v-model="formEditEstimate.address_discharge" placeholder="Adresse de déchargement"/>
                    </el-form-item>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>  
          </div>  

          <br>
          <br>

          <el-col :span="24">
            <el-form-item prop="insurance">
              <el-checkbox-group v-model="formEditEstimate.insurance">
                <el-checkbox value="true" name="insuranceReservation">
                  Assurance annulation : 
                  <el-input type="number" v-model.number="formEditEstimate.price_insurance_rate" style="width: 50px"/>% 
                  {{ (formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                  ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ?
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 ) 
                   <= 500 ? 50 : 
                   Math.floor(((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) * 
                   (formEditEstimate.price_insurance_rate / 100)) }}€ TTC
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <br /><br />
          <el-col :span="24">
            <el-form-item label="Code promo">
              <el-input type="text" v-model="formEditEstimate.code" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Comment avez-vous entendu parler de nous ?">
              <el-select
                v-model="formEditEstimate.how"
                placeholder="Sélectionner"
                size="large"
              >
                <el-option
                  v-for="item in channels"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Commentaire client :">
              {{formEditEstimate.comment}}
            </el-form-item>
          </el-col>
          <el-col :span="24"></el-col>
          <div v-if="formEditEstimate.price_unit > 0">
            <hr v-if="formEditEstimate.price_unit > 0"> 
            <el-col v-if="formEditEstimate.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formEditEstimate.price_unit > 0" :span="24"><b>Chiffrage (Prix HT/TTC) :</b></el-col>
            <el-col v-if="formEditEstimate.price_unit > 0" :span="24"></el-col>
            <div class="price-details">
              <el-col v-if="formEditEstimate.price_unit > 0 && formEditEstimate.code" :span="24">Code promo <b>{{ formEditEstimate.code }}</b> appliqué</el-col>
              <el-col v-if="formEditEstimate.price_unit > 0 && formEditEstimate.code" :span="24"></el-col>
              <el-col v-if="formEditEstimate.price_unit > 0 && formEditEstimate.moto_nb > 0" :span="24" style="font-size: 15px;">- Moto <b>x{{formEditEstimate.moto_nb}}</b> = {{(parseFloat(formEditEstimate.price_unit) * formEditEstimate.moto_nb / (formEditEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{(parseFloat(formEditEstimate.price_unit) * formEditEstimate.moto_nb).toFixed(2) }}€</b></el-col>
              <el-col v-if="formEditEstimate.price_unit > 0 && formEditEstimate.passenger_nb > 0" :span="24" style="font-size: 15px;">- Voyageur <b>x{{formEditEstimate.passenger_nb}}</b> = {{formEditEstimate.passenger_nb <= formEditEstimate.moto_nb ? "0" : parseFloat((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) / formEditEstimate.price_unit / 1.1).toFixed(2) }}€ / <b>{{formEditEstimate.passenger_nb <= formEditEstimate.moto_nb ? "0" : parseFloat((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                    (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit).toFixed(2) }}€</b></el-col>
              <el-col v-if="formEditEstimate.price_unit > 0" :span="24"></el-col>
              <el-col v-if="formEditEstimate.price_unit > 0 && formEditEstimate.insurance.length > 0" :span="24" style="font-size: 15px;">- Assurance annulation = {{ 
              ((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) <= 500 ? parseFloat(50 / (formEditEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) : 
                   Math.floor(((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) * (formEditEstimate.price_insurance_rate / 100) / (formEditEstimate.passenger_nb > 0 ? 1.1 : 1.2)) }}€ / <b>
                    {{ ((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) * (formEditEstimate.price_insurance_rate / 100)) }}€</b></el-col>
              <el-col v-if="formEditEstimate.price_unit > 0 && formEditEstimate.charge.length > 0" :span="24" style="font-size: 15px;">- Adresse de chargement personnalisée = {{ parseFloat(formEditEstimate.price_charge / (formEditEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ formEditEstimate.price_charge }}€</b></el-col>
              <el-col v-if="formEditEstimate.price_unit > 0 && formEditEstimate.discharge.length > 0" :span="24" style="font-size: 15px;">- Adresse de déchargement personnalisée = {{ parseFloat(formEditEstimate.price_discharge / (formEditEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ formEditEstimate.price_discharge }}€</b></el-col>
            </div>
            <el-col v-if="formEditEstimate.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formEditEstimate.price_unit > 0" :span="24" style="color: #e6007e; font-size: 20px; text-align: right;"><b>
              Total : {{ parseFloat((parseFloat(((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 ))) + 
                (formEditEstimate.insurance.length > 0 ? 
                ((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) * (formEditEstimate.price_insurance_rate / 100))
                : 0) + 
                (formEditEstimate.charge.length > 0 ? formEditEstimate.price_charge : 0) +
                (formEditEstimate.discharge.length > 0 ? formEditEstimate.price_discharge : 0)) / (formEditEstimate.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ HT / {{ parseFloat(parseFloat(((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 ))) + 
                (formEditEstimate.insurance.length > 0 ? 
                ((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formEditEstimate.price_unit * formEditEstimate.moto_nb) + 
                   ((formEditEstimate.passenger_nb - formEditEstimate.moto_nb) > 0 ? 
                   (formEditEstimate.passenger_nb - formEditEstimate.moto_nb) * 
                   (formEditEstimate.price_passenger_rate / 100) * formEditEstimate.price_unit : 0 )) * (formEditEstimate.price_insurance_rate / 100))
                : 0) + 
                (formEditEstimate.charge.length > 0 ? formEditEstimate.price_charge : 0) +
                (formEditEstimate.discharge.length > 0 ? formEditEstimate.price_discharge : 0)).toFixed(2) }}€ TTC</b></el-col>
            <el-col v-if="formEditEstimate.price_unit > 0" :span="24"></el-col>
            <el-col :span="24"></el-col>
          </div>

          
          <div v-if="formEditEstimate.type == 2" class="roundTrip">
            <el-col :span="24">
              <el-form-item label="Trajet RETOUR">
              </el-form-item>
            </el-col>
            <br>
            <hr>
            <br>
            <el-col :span="12">
              <el-form-item label="Ville de départ">
                <Autocomplete
                  :key="refreshAutocomplete"
                  v-model="formEditEstimate.depart_return"
                ></Autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Ville d'arrivée">
                <Autocomplete
                  :key="refreshAutocomplete"
                  v-model="formEditEstimate.arrival_return"
                ></Autocomplete>
              </el-form-item>
            </el-col>
            <br><br>
            <el-col :span="12">
              <el-form-item label="Prix TTC par moto (après toutes les réductions)">
                <el-input
                  type="number"
                  v-model.number="formEditEstimate.price_unit_return"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Taux voyageur supplémentaire (%)">
                <el-input
                  type="number"
                  v-model="formEditEstimate.price_passenger_rate_return"
                /> = {{ parseFloat((formEditEstimate.price_unit_return * (formEditEstimate.price_passenger_rate_return / 100)).toFixed(2)) }}€
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item 
                :label="`Nombre de motos`" 
              >
                <el-input
                  type="number"
                  v-model.number="formEditEstimate.moto_nb_return"
                  @input="generateMotoListForEstimateReturn"
                />
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="`Nombre de voyageurs`"
              >
                <el-input
                  type="number"
                  v-model.number="formEditEstimate.passenger_nb_return"
                  @input="generatePassengerListForEstimateReturn"
                />
              </el-form-item>
            </el-col>
            <br>
            <hr>
            <br>
            <div
              v-for="(moto, index) in formEditEstimate.moto_list_return"
              :key="index"
            >
              <el-form-item
                v-if="index === 0"
                label="Liste des motos :"
              ></el-form-item>
              <el-col :span="24">
                <el-form-item>
                  <el-input type="text" v-model="moto.model" placeholder="Modèle" />
                </el-form-item>
              </el-col>
            </div>
            <div v-if="formEditEstimate.passenger_list_return.length > 0">
              <br>
              <hr>
              <br>
              <div
                v-for="(passenger, index) in formEditEstimate.passenger_list_return"
                :key="index"
              >
                <el-form-item
                  v-if="index === 0"
                  label="Liste des voyageurs :"
                ></el-form-item>
                <el-col :span="6">
                  <el-form-item>
                    <el-input type="text" v-model="passenger.firstname" placeholder="Prénom"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item>
                    <el-input type="text" v-model="passenger.lastname" placeholder="NOM"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-input type="text" v-model="passenger.email" placeholder="E-mail"/>
                  </el-form-item>
                </el-col>
              </div>
            </div>
            <br>
            <hr>
            <br>
            <div v-if="formEditEstimate.passenger_nb_return === 0">
              <br><br>
              <el-col :span="24">
                <el-form-item >
                  <el-checkbox-group v-model="formEditEstimate.charge_return">
                    <el-checkbox value="true" name="charge_return">
                      Adresse de chargement personnalisée : 
                      <el-input type="number" v-model.number="formEditEstimate.price_charge_return" style="width: 50px"/>€ TTC
                      <el-form-item v-if="formEditEstimate.charge_return.length > 0">
                        <el-input v-model="formEditEstimate.address_charge_return" placeholder="Adresse de chargement"/>
                      </el-form-item>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>    

              <br><br>

              <el-col :span="24">
                <el-form-item >
                  <el-checkbox-group v-model="formEditEstimate.discharge_return">
                    <el-checkbox value="true" name="discharge_return">
                      Adresse de déchargement personnalisée : 
                      <el-input type="number" v-model.number="formEditEstimate.price_discharge_return" style="width: 50px"/>€ TTC
                      <el-form-item v-if="formEditEstimate.discharge_return.length > 0">
                        <el-input v-model="formEditEstimate.address_discharge_return" placeholder="Adresse de déchargement"/>
                      </el-form-item>
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>  
            </div>  

            <br>
            <br>

            <el-col :span="24">
              <el-form-item prop="insurance">
                <el-checkbox-group v-model="formEditEstimate.insurance_return">
                  <el-checkbox value="true" name="insuranceReservation_return">
                    Assurance annulation : 
                    <el-input type="number" v-model.number="formEditEstimate.price_insurance_rate_return" style="width: 50px"/>% 
                    {{ (formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ?
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 ) 
                    <= 500 ? 50 : 
                    Math.floor(((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) * 
                    (formEditEstimate.price_insurance_rate_return / 100)) }}€ TTC
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <br /><br />
            <el-col :span="24"></el-col>
            <div v-if="formEditEstimate.price_unit_return > 0">
              <hr v-if="formEditEstimate.price_unit_return > 0"> 
              <el-col v-if="formEditEstimate.price_unit_return > 0" :span="24"></el-col>
              <el-col v-if="formEditEstimate.price_unit_return > 0" :span="24"><b>Chiffrage (Prix HT/TTC) :</b></el-col>
              <el-col v-if="formEditEstimate.price_unit_return > 0" :span="24"></el-col>
              <div class="price-details">
                <el-col v-if="formEditEstimate.price_unit_return > 0 && formEditEstimate.code" :span="24">Code promo <b>{{ formEditEstimate.code }}</b> appliqué</el-col>
                <el-col v-if="formEditEstimate.price_unit_return > 0 && formEditEstimate.code" :span="24"></el-col>
                <el-col v-if="formEditEstimate.price_unit_return > 0 && formEditEstimate.moto_nb_return > 0" :span="24" style="font-size: 15px;">- Moto <b>x{{formEditEstimate.moto_nb_return}}</b> = {{(parseFloat(formEditEstimate.price_unit_return) * formEditEstimate.moto_nb_return / (formEditEstimate.passenger_nb_return > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{(parseFloat(formEditEstimate.price_unit_return) * formEditEstimate.moto_nb_return).toFixed(2) }}€</b></el-col>
                <el-col v-if="formEditEstimate.price_unit_return > 0 && formEditEstimate.passenger_nb_return > 0" :span="24" style="font-size: 15px;">- Voyageur <b>x{{formEditEstimate.passenger_nb_return}}</b> = {{formEditEstimate.passenger_nb_return <= formEditEstimate.moto_nb_return ? "0" : parseFloat((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) / formEditEstimate.price_unit_return / 1.1).toFixed(2) }}€ / <b>{{formEditEstimate.passenger_nb_return <= formEditEstimate.moto_nb_return ? "0" : parseFloat((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                      (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return).toFixed(2) }}€</b></el-col>
                <el-col v-if="formEditEstimate.price_unit_return > 0" :span="24"></el-col>
                <el-col v-if="formEditEstimate.price_unit_return > 0 && formEditEstimate.insurance_return.length > 0" :span="24" style="font-size: 15px;">- Assurance annulation = {{ 
                ((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) <= 500 ? parseFloat(50 / (formEditEstimate.passenger_nb_return > 0 ? 1.1 : 1.2)).toFixed(2) : 
                    Math.floor(((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) * (formEditEstimate.price_insurance_rate_return / 100) / (formEditEstimate.passenger_nb_return > 0 ? 1.1 : 1.2)) }}€ / <b>
                      {{ ((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) <= 500 ? 50 : Math.floor(((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) * (formEditEstimate.price_insurance_rate_return / 100)) }}€</b></el-col>
                <el-col v-if="formEditEstimate.price_unit_return > 0 && formEditEstimate.charge_return.length > 0" :span="24" style="font-size: 15px;">- Adresse de chargement personnalisée = {{ parseFloat(formEditEstimate.price_charge_return / (formEditEstimate.passenger_nb_return > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ formEditEstimate.price_charge_return }}€</b></el-col>
                <el-col v-if="formEditEstimate.price_unit_return > 0 && formEditEstimate.discharge_return.length > 0" :span="24" style="font-size: 15px;">- Adresse de déchargement personnalisée = {{ parseFloat(formEditEstimate.price_discharge_return / (formEditEstimate.passenger_nb_return > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ formEditEstimate.price_discharge_return }}€</b></el-col>
              </div>
              <el-col v-if="formEditEstimate.price_unit_return > 0" :span="24"></el-col>
              <el-col v-if="formEditEstimate.price_unit_return > 0" :span="24" style="color: #e6007e; font-size: 20px; text-align: right;"><b>
                Total : {{ parseFloat((parseFloat(((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 ))) + 
                  (formEditEstimate.insurance_return.length > 0 ? 
                  ((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) <= 500 ? 50 : Math.floor(((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) * (formEditEstimate.price_insurance_rate_return / 100))
                  : 0) + 
                  (formEditEstimate.charge_return.length > 0 ? formEditEstimate.price_charge_return : 0) +
                  (formEditEstimate.discharge_return.length > 0 ? formEditEstimate.price_discharge_return : 0)) / (formEditEstimate.passenger_nb_return > 0 ? 1.1 : 1.2)).toFixed(2) }}€ HT / {{ parseFloat(parseFloat(((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 ))) + 
                  (formEditEstimate.insurance_return.length > 0 ? 
                  ((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) <= 500 ? 50 : Math.floor(((formEditEstimate.price_unit_return * formEditEstimate.moto_nb_return) + 
                    ((formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) > 0 ? 
                    (formEditEstimate.passenger_nb_return - formEditEstimate.moto_nb_return) * 
                    (formEditEstimate.price_passenger_rate_return / 100) * formEditEstimate.price_unit_return : 0 )) * (formEditEstimate.price_insurance_rate_return / 100))
                  : 0) + 
                  (formEditEstimate.charge_return.length > 0 ? formEditEstimate.price_charge_return : 0) +
                  (formEditEstimate.discharge_return.length > 0 ? formEditEstimate.price_discharge_return : 0)).toFixed(2) }}€ TTC</b></el-col>
              <el-col v-if="formEditEstimate.price_unit_return > 0" :span="24"></el-col>
              <el-col :span="24"></el-col>
            </div>
            
            <br>
          </div>
          
          <el-col :span="24">
            <el-form-item label="Statut">
              <el-select
                v-model="formEditEstimate.status"
                placeholder="Sélectionner"
                size="large"
              >
                <el-option
                  v-for="item in estimateStatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Remarque :">
              <el-input
                type="textarea"
                v-model="formEditEstimate.note"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Message au client :">
              <el-input
                type="textarea"
                v-model="formEditEstimate.msg"
              />
            </el-form-item>
          </el-col>
          <br>
        </div>
        <div class="flex space-between">
          <el-button @click="resetEstimate(formEditEstimate.row)" color="#000047">Réinitialiser</el-button>
          <el-button @click="sendEstimate()" v-if="formEditEstimate.status <= 4" color="#E6007E">Envoyer le chiffrage au client</el-button>
          <el-button @click="editEstimate()" color="#000047">Enregistrer</el-button>
        </div>
        <br />
      </el-form>
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['estimates']" :data="filteredEstimates" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog center title="Réservation" color="white" v-model="showReservationPopup" :close-on-click-modal="false">
      <el-form
        ref="ruleReservationRef"
        style="max-width: 600px; width: 100%; padding: 50px"
        :model="formReservation"
        :rules="rulesReservation"
        label-width="auto"
        label-position="top"
        status-icon
        scroll-to-error
        v-loading="loadingReservation"
        id="formReservationTop"
      >
        <el-steps style="max-width: 600px; width: 100%;" :active="stepReservation" finish-status="success" align-center>
          <el-step title="Trajet" />
          <el-step title="Contact" />
          <el-step title="Moto" />
          <el-step title="Voyageur" />
          <el-step title="Options" />
          <el-step title="Récapitulatif" />
        </el-steps>
        <br><br><br>
        <div v-if="stepReservation == 0">
          <el-col :span="12">
            <el-form-item label="Ville de départ" prop="depart">
              <Autocomplete
                :key="refreshAutocomplete"
                :disabled="true"
                v-model="formReservation.depart"
              ></Autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Ville d'arrivée" prop="arrival">
              <Autocomplete
                :key="refreshAutocomplete"
                :disabled="true"
                v-model="formReservation.arrival"
              ></Autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Date de départ" prop="date_depart">
              <el-input
                v-model="formReservation.date_depart"
                type="text"
                placeholder="Date de départ"
                style="width: 100%"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <br><br>
          <el-col :span="12">
            <el-form-item label="Prix TTC par moto (0~1 voyageur)">
              <el-input
                type="number"
                v-model.number="formReservation.price_unit"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Prix voyageur supplémentaire">
              <el-input
                type="text"
                v-model="formReservation.price_passenger"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item 
              :label="`Nombre de motos`" 
              required 
              prop="moto_nb"
            >
              <el-input
                type="number"
                v-model.number="formReservation.moto_nb"
                @input="generateMotoListForReservation"
              />
              <div style="text-align: right; width: 100%">Il reste &nbsp;<b><span style="color:#e6007e">{{formReservation.places_moto - formReservation.moto_nb}}</span></b>&nbsp; place(s) disponible(s)</div>
            </el-form-item><br>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="`Nombre de voyageurs`"
              required
              prop="passenger_nb"
            >
              <el-input
                type="number"
                v-model.number="formReservation.passenger_nb"
                @input="generatePassengerListForReservation"
              />
              <div style="text-align: right; width: 100%">Il reste &nbsp;<b><span style="color:#e6007e">{{formReservation.places_passenger - formReservation.passenger_nb}}</span></b>&nbsp; place(s) disponible(s)</div>
            </el-form-item>
          </el-col>
        </div>

        <div v-if="stepReservation == 1">
          <el-form-item label="Vos coordonnées de contact">
            <el-col :span="12">
              <el-form-item label="Prénom" required prop="firstname">
                <el-input type="text" v-model="formReservation.firstname" />
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Nom" required prop="lastname">
                <el-input
                  type="text"
                  v-model="formReservation.lastname"
                  required
                />
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="E-mail" required prop="email">
                <el-autocomplete
                  style="width: 100%"
                  v-model="formReservation.email"
                  :fetch-suggestions="searchUserForEmail"
                  @select="handleSelectEmailForReservation"
                  value-key="email"
                ></el-autocomplete>
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Téléphone" required prop="phone">
                <el-input type="text" v-model="formReservation.phone" />
              </el-form-item><br>
            </el-col>
          </el-form-item>
        </div>

        <div v-if="stepReservation == 2">
          <div
            v-for="(moto, index) in formReservation.moto_list"
            :key="index"
          >
            <el-form-item
              v-if="index === 0"
              label="* Liste des motos :"
            ></el-form-item>
            <el-col :span="24">
              <el-form-item :prop="`moto_list[${index}].model`">
                <el-input type="text" v-model="moto.model" placeholder="Modèle" />
              </el-form-item>
            </el-col>
          </div>
        </div>

        <div v-if="stepReservation == 3">
          <div
            v-for="(passenger, index) in formReservation.passenger_list"
            :key="index"
          >
            <el-form-item
              v-if="index === 0"
              label="Liste des voyageurs :"
            ></el-form-item>
            <el-col :span="6">
              <el-form-item :prop="`passenger_list[${index}].firstname`">
                <el-input type="text" v-model="passenger.firstname" placeholder="Prénom"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :prop="`passenger_list[${index}].lastname`">
                <el-input type="text" v-model="passenger.lastname" placeholder="NOM"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :prop="`passenger_list[${index}].email`">
                <el-input type="text" v-model="passenger.email" placeholder="E-mail"/>
              </el-form-item>
            </el-col>
          </div>
        </div>

        <div v-if="stepReservation == 4">
          <div v-if="formReservation.passenger_nb == 0">
            <el-col :span="24">
              <el-form-item prop="charge">
                <el-checkbox-group v-model="formReservation.charge">
                  <el-checkbox value="true" name="charge">
                    Si le client souhaite une autre adresse de chargement : 
                    <span class="rose">{{ options[1].value }}€ TTC</span><br>
                    <el-form-item v-if="formReservation.charge.length > 0" prop="address_charge">
                      <el-input v-model="formReservation.address_charge" placeholder="Adresse de chargement"/>
                    </el-form-item>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>    

            <br><br>

            <el-col :span="24">
              <el-form-item prop="discharge">
                <el-checkbox-group v-model="formReservation.discharge">
                  <el-checkbox value="true" name="discharge">
                    Si le client souhqte une autre adresse de déchargement : 
                    <span class="rose">{{ options[1].value }}€ TTC</span><br>
                    <el-form-item v-if="formReservation.discharge.length > 0" prop="address_discharge">
                      <el-input v-model="formReservation.address_discharge" placeholder="Adresse de déchargement"/>
                    </el-form-item>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>  
          </div>  

          <br>

          <el-col :span="24">
            <el-form-item prop="insurance">
              <el-checkbox-group v-model="formReservation.insurance">
                <el-checkbox v-if="formReservation.price_unit > 0" value="true" name="insuranceReservation">
                  Assurance annulation : <span class="rose"><b>{{ formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * (options[1].value / 100)) }}€ TTC</b></span>
                </el-checkbox>
                <el-checkbox v-else value="true" name="insuranceReservation">
                  Assurance annulation : 
                </el-checkbox>
                <el-alert type="info" :closable="false">
                  Avec l'assurance annulation: Annulation et remboursement intégral
                  possible jusqu'à 48h avant le départ, hors coût de l'assurance
                  annulation qui représente {{options[0].value}}% du billet avec un minimum de 50
                  euros.
                </el-alert>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <br /><br />
          <el-col :span="24">
            <el-form-item label="Code promo" prop="code">
              <el-input type="text" v-model="formReservation.code" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Remarque">
              <el-input type="textarea" v-model="formReservation.note" />
            </el-form-item>
          </el-col>
          <el-col :span="24"></el-col>
          <div v-if="formReservation.price_unit > 0">
            <hr v-if="formReservation.price_unit > 0">
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"><b>Détails du transport (Prix HT/TTC) :</b></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <div class="price-details">
              <el-col v-if="formReservation.price_unit > 0 && formReservation.code && formReservation.checkCode" :span="24">Code promo <b>{{ formReservation.code }}</b> appliqué</el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.code && formReservation.checkCode" :span="24"></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.moto_nb > 0" :span="24" style="font-size: 15px;">- Moto <b>x{{formReservation.moto_nb}}</b> = {{(parseFloat(formReservation.price_unit) * formReservation.moto_nb / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{(parseFloat(formReservation.price_unit) * formReservation.moto_nb).toFixed(2) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.passenger_nb > 0" :span="24" style="font-size: 15px;">- Voyageur <b>x{{formReservation.passenger_nb}}</b> = {{formReservation.passenger_nb <= formReservation.moto_nb ? "0" : parseFloat(formReservation.price_passenger / 1.1 * (formReservation.passenger_nb - formReservation.moto_nb)).toFixed(2) }}€ / <b>{{formReservation.passenger_nb <= formReservation.moto_nb ? "0" : parseFloat(formReservation.price_passenger * (formReservation.passenger_nb - formReservation.moto_nb)).toFixed(2) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.insurance.length > 0" :span="24" style="font-size: 15px;">- Assurance annulation = {{ formReservation.total <= 500 ? parseFloat(50 / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) : Math.floor(formReservation.total * (options[0].value / 100) / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)) }}€ / <b>{{ formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * (options[0].value / 100)) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.charge.length > 0" :span="24" style="font-size: 15px;">- Adresse de chargement personnalisée = {{ parseFloat(options[1].value / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ options[1].value }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.discharge.length > 0" :span="24" style="font-size: 15px;">- Adresse de déchargement personnalisée = {{ parseFloat(options[1].value / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ options[1].value }}€</b></el-col>
            </div>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24" style="color: #e6007e; font-size: 20px; text-align: right;"><b>
              Total : {{ parseFloat((parseFloat(formReservation.total) + 
                (formReservation.insurance.length > 0 ? 
                  formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * (options[0].value / 100))
                : 0) + 
                (formReservation.charge.length > 0 ? options[1].value : 0) +
                (formReservation.discharge.length > 0 ? options[1].value : 0)) / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ HT / {{ parseFloat(parseFloat(formReservation.total) + 
                (formReservation.insurance.length > 0 ? 
                  formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * (options[0].value / 100))
                : 0) + 
                (formReservation.charge.length > 0 ? options[1].value : 0) +
                (formReservation.discharge.length > 0 ? options[1].value : 0)).toFixed(2) }}€ TTC</b></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col :span="24"></el-col>
          </div>
        </div>
        
        <div v-if="stepReservation == 5">
          <el-timeline
            style="
              width: 200px;
              background: white;
              border-radius: 20px;
            "
          >
            <div class="timeline-bloc" v-if="formReservation.row.time0">
              <div class="time">
                {{
                  formatTimestamp(formReservation.row.time0, formReservation.row.places_moto_1)
                }}
              </div>
              <el-timeline-item color="#44bbc3" size="large">
                <div>
                  Début chargement
                  {{
                    formReservation.row.places_moto_1 <= 3
                      ? "30"
                      : "15"
                  }}
                  minutes avant le départ
                </div>
              </el-timeline-item>
            </div>
            <div class="timeline-bloc" v-if="formReservation.row.depart">
              <div v-if="formReservation.row.time0" class="time">
                {{ formReservation.row.time0 }}
              </div>
              <div v-else class="time white">00:00</div>
              <el-timeline-item color="black" size="large">
                <a
                  v-if="formReservation.row.address_depart"
                  :title="formReservation.row.address_depart"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_depart}`"
                  target="_blank"
                  ><u
                    ><b>{{ formReservation.row.depart.split(",")[0] }}</b></u
                  ></a
                >
                <div v-else>
                  <b>{{ formReservation.row.depart.split(",")[0] }}</b>
                </div>

                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_depart"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>
            <div class="timeline-bloc" v-if="formReservation.row.layover1">
              <div v-if="formReservation.row.time1" class="time">
                {{ formReservation.row.time1 }}
              </div>
              <div v-else class="time white">00:00</div>
              <el-timeline-item color="#E6007E" size="normal">
                <a
                  v-if="formReservation.row.address_layover1"
                  :title="formReservation.row.address_layover1"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_layover1}`"
                  target="_blank"
                >
                  <span class="rose"
                    ><u
                      ><b>{{
                        formReservation.row.layover1.split(",")[0]
                      }}</b></u
                    ></span
                  ></a
                >
                <div v-else>
                  <span class="rose"
                    ><b>{{
                      formReservation.row.layover1.split(",")[0]
                    }}</b></span
                  >
                </div>
                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_layover1"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>

            <div class="timeline-bloc" v-if="formReservation.row.layover2">
              <div v-if="formReservation.row.time2" class="time">
                {{ formReservation.row.time2 }}
              </div>
              <div v-else class="time white">00:00</div>
              <el-timeline-item color="#E6007E" size="normal"
                ><a
                  v-if="formReservation.row.address_layover2"
                  :title="formReservation.row.address_layover2"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_layover2}`"
                  target="_blank"
                >
                  <span class="rose"
                    ><u
                      ><b>{{
                        formReservation.row.layover2.split(",")[0]
                      }}</b></u
                    ></span
                  ></a
                >
                <div v-else>
                  <span class="rose"
                    ><b>{{
                      formReservation.row.layover2.split(",")[0]
                    }}</b></span
                  >
                </div>
                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_layover2"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>
            <div class="timeline-bloc" v-if="formReservation.row.layover3">
              <div v-if="formReservation.row.time3" class="time">
                {{ formReservation.row.time3 }}
              </div>
              <div v-else class="time white">00:00</div>
              <el-timeline-item color="#E6007E" size="normal"
                ><a
                  v-if="formReservation.row.address_layover3"
                  :title="formReservation.row.address_layover3"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_layover3}`"
                  target="_blank"
                >
                  <span class="rose"
                    ><u
                      ><b>{{
                        formReservation.row.layover3.split(",")[0]
                      }}</b></u
                    ></span
                  >
                </a>
                <div v-else>
                  <span class="rose"
                    ><b>{{
                      formReservation.row.layover3.split(",")[0]
                    }}</b></span
                  >
                </div>
                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_layover3"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>
            <div class="timeline-bloc" v-if="formReservation.row.arrival">
              <div v-if="formReservation.row.time4" class="time">
                {{ formReservation.row.time4 }}
              </div>
              <div v-else class="time white">00:00</div>

              <el-timeline-item color="black" size="large">
                <a
                  v-if="formReservation.row.address_arrival"
                  :title="formReservation.row.address_arrival"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_arrival}`"
                  target="_blank"
                >
                  <u
                    ><b>{{ formReservation.row.arrival.split(",")[0] }}</b></u
                  ></a
                >
                <div v-else>
                  <b>{{ formReservation.row.arrival.split(",")[0] }}</b>
                </div>

                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_arrival"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>
          </el-timeline>
          <br>
          Veuillez vérifier vos informations saisies :
          <br>
          <br>
          <hr>
          <br>
          <br>
          <b>Ville de départ : </b><span class="rose">{{ formReservation.depart || 'Non renseigné' }}</span>
          <br>
          <b>Ville d'arrivée : </b><span class="rose">{{ formReservation.arrival || 'Non renseigné' }}</span><br>
          <br>
          <b>Date de départ : </b>{{ formReservation.date_depart }}<br>
          <br>
          <b>Prix TTC par moto (0~1 voyageur) </b>: {{ formReservation.price_unit }}
          <br>
          <b>Prix voyageur supplémentaire : </b>{{ formReservation.price_passenger }}<br>
          <br>
          <b>Nombre de motos : </b>{{formReservation.moto_nb}}
          <br>
          <b>Nombre de voyageurs : </b>{{formReservation.passenger_nb}}
          <br>
          <br>
          <hr>
          <br>
          <b>Coordonnées de contact du client</b>
          <br><br>
          <b>Prénom : </b>{{formReservation.firstname}}
          <br>
          <b>NOM : </b>{{formReservation.lastname}}
          <br>
          <b>E-mail : </b>{{ formReservation.email }}
          <br>
          <b>Téléphone : </b>{{formReservation.phone}}
          <br>
          <br>
          <hr v-if="formReservation.moto_nb > 0">
          <br v-if="formReservation.moto_nb > 0">

          <div v-if="formReservation.moto_nb > 0">
            <div
              v-for="(moto, index) in formReservation.moto_list"
              :key="index"
            >
              <el-form-item
                v-if="index === 0"
                label="Liste des motos :"
              ></el-form-item>
              <el-col :span="24">
                <el-form-item props="model">
                  <el-input type="text" v-model="moto.model" placeholder="Modèle" disabled="false"/>
                </el-form-item>
              </el-col>
            </div>
          </div>
          <br v-if="formReservation.passenger_nb > 0">
          <hr v-if="formReservation.passenger_nb > 0">
          <br v-if="formReservation.passenger_nb > 0">
          <div
            v-for="(passenger, index) in formReservation.passenger_list"
            :key="index"
          >
            <el-form-item
              v-if="index === 0"
              label="Liste des voyageurs :"
            ></el-form-item>
            <el-col :span="6">
              <el-form-item>
                <el-input type="text" v-model="passenger.firstname" placeholder="Prénom" disabled="false"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-input type="text" v-model="passenger.lastname" placeholder="NOM" disabled="false"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-input type="text" v-model="passenger.email" placeholder="E-mail" disabled="false"/>
              </el-form-item>
            </el-col>
          </div>
          <br>
          <hr>
          <br>
          <b>Assurance annulation : </b>{{ formReservation.insurance.length > 0 ? "Oui" : "Non"}}<br>
          <b>Adresse de chargement personnalisée : </b>{{ formReservation.charge.length > 0 ? "Oui - " + (formReservation.address_charge || "Adresse non renseignée") : "Non"}}<br>
          <b>Adresse de déchargement personnalisée : </b>{{ formReservation.discharge.length > 0 ? "Oui - " + (formReservation.address_discharge || "Adresse non renseignée") : "Non"}}<br>
          <br>
          <b>Code Promo : </b>{{ formReservation.code }}
          <br><br><hr><br><br>
          <div>
            <el-col v-if="formReservation.price_unit > 0" :span="24"><b>Détails du transport (Prix HT/TTC) :</b></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <div class="price-details">
              <el-col v-if="formReservation.price_unit > 0 && formReservation.code && formReservation.checkCode" :span="24">Code promo <b>{{ formReservation.code }}</b> appliqué</el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.code && formReservation.checkCode" :span="24"></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.moto_nb > 0" :span="24" style="font-size: 15px;">- Moto <b>x{{formReservation.moto_nb}}</b> = {{(parseFloat(formReservation.price_unit) * formReservation.moto_nb / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{(parseFloat(formReservation.price_unit) * formReservation.moto_nb).toFixed(2) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.passenger_nb > 0" :span="24" style="font-size: 15px;">- Voyageur <b>x{{formReservation.passenger_nb}}</b> = {{formReservation.passenger_nb <= formReservation.moto_nb ? "0" : parseFloat(formReservation.price_passenger / 1.1 * (formReservation.passenger_nb - formReservation.moto_nb)).toFixed(2) }}€ / <b>{{formReservation.passenger_nb <= formReservation.moto_nb ? "0" : parseFloat(formReservation.price_passenger * (formReservation.passenger_nb - formReservation.moto_nb)).toFixed(2) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.insurance.length > 0" :span="24" style="font-size: 15px;">- Assurance annulation = {{ formReservation.total <= 500 ? parseFloat(50 / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) : Math.floor(formReservation.total * (options[0].value / 100) / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)) }}€ / <b>{{ formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * (options[0].value / 100)) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.charge.length > 0" :span="24" style="font-size: 15px;">- Adresse de chargement personnalisée = {{ parseFloat(options[1].value / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ options[1].value }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.discharge.length > 0" :span="24" style="font-size: 15px;">- Adresse de déchargement personnalisée = {{ parseFloat(options[1].value / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ options[1].value }}€</b></el-col>
            </div>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24" style="color: #e6007e; font-size: 20px; text-align: right;"><b>
              Total : {{ parseFloat((parseFloat(formReservation.total) + 
                (formReservation.insurance.length > 0 ? 
                  formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * (options[0].value / 100))
                : 0) + 
                (formReservation.charge.length > 0 ? options[1].value : 0) +
                (formReservation.discharge.length > 0 ? options[1].value : 0)) / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ HT / {{ parseFloat(parseFloat(formReservation.total) + 
                (formReservation.insurance.length > 0 ? 
                  formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * (options[0].value / 100))
                : 0) + 
                (formReservation.charge.length > 0 ? options[1].value : 0) +
                (formReservation.discharge.length > 0 ? options[1].value : 0)).toFixed(2) }}€ TTC</b></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col :span="24"></el-col>
            <el-col :span="24"></el-col>
          </div>

          <el-col :span='24'></el-col>
          <el-col :span="24"></el-col>

          <el-col :span='24'>
            <el-form-item prop='payment_installment'>
              <el-checkbox-group v-model="formReservation.payment_installment">
                <el-checkbox value="true" name="payment_installment">
                  Paiement en plusieurs fois
                  <el-form-item v-if="formReservation.payment_installment.length > 0" required prop="price_firstpayment" label="Montant à payer pour cette fois-ci (1er paiement)">
                    <el-input type="number" v-model.number="formReservation.price_firstpayment" placeholder="Montant à payer pour cette fois-ci (1er paiement)" />
                  </el-form-item>
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          
          <br>
          <br>

          <el-col :span="24">
            <el-form-item label="Chiffrage ID" prop="id_estimate" required>
              <el-select
                v-model="formReservation.id_estimate"
                placeholder="Chiffrage ID"
                size="large"
                filterable
              >
                <el-option
                  v-for="item in estimates"
                  :key="item.id"
                  :label="`${item.id}:[${item.email}][${item.date_depart}]${item.depart || 'Ville A'} → ${item.arrival || 'Ville B'}`"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item prop="conditionCheck">
              <el-checkbox-group v-model="formReservation.conditionCheck">
                <el-checkbox value="true" name="conditionCheck">
                  <span style="color: red">*</span>Cette réservation enverra un lien de paiement au client. Je confirme avoir généré ce lien de paiement et vérifié que les informations saisies sont exactes.
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>

          <el-col :span='24'></el-col>
        </div>
        
        <div style="width: 100%; display: flex; justify-content: space-between">
          <div v-if="stepReservation == 0"></div>
          <el-button v-if="stepReservation > 0" @click="
            [3, 4].includes(stepReservation) ? 
              formReservation.passenger_nb == 0 ?
                formReservation.moto_nb == 0 ? 
                  stepReservation = stepReservation - 3 : 
                stepReservation = stepReservation - 2 :
              formReservation.moto_nb == 0 ? 
                stepReservation == 3 ? 
                  stepReservation = stepReservation - 2 : 
                stepReservation -- :
              stepReservation-- :
            stepReservation--
          ">
            Précédent
          </el-button>
          <el-button v-if="stepReservation < 5" @click="nextStep" color="#000047" plain>Suivant</el-button>
          <el-button v-if="stepReservation == 5" @click="sendReservation()" color="#E6007E">Valider la réservation</el-button>
        </div>

        <br />
        <br />
      </el-form>
    </el-dialog>
    <el-dialog center :title="`Devis ID ${formEditOrder.id}`" color="white" v-model="showOrderPopup" :close-on-click-modal="false">
      <el-form
        style="width: 100%; padding: 50px"
        :model="formEditOrder"
        label-width="auto"
        label-position="top"
        status-icon
        scroll-to-error
        id="formEditOrderTop"
        v-loading="loadingEditOrder"
      >
        <div>
          <el-col :span="24">
            <el-form-item label="Trajet ID">
              <el-select
                v-model="formEditOrder.id_route"
                placeholder="Sélectionner"
                size="large"
                filterable
                :disabled="true"
              >
                <el-option
                  v-for="item in routes"
                  :key="item.id"
                  :label="`${item.id}:[${formattedDate(item.date_depart)}]${item.depart} ${item.layover1 ? '→ ' + item.layover1 + ' ' : ''}${item.layover2 ? '→ ' + item.layover2 + ' ' : ''}${item.layover3 ? '→ ' + item.layover3 + ' ' : ''}→ ${item.arrival}`"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Ville de départ">
              <Autocomplete
                :key="refreshAutocomplete"
                v-model="formEditOrder.depart"
                :disabled="true"
              ></Autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Ville d'arrivée">
              <Autocomplete
                :key="refreshAutocomplete"
                v-model="formEditOrder.arrival"
                :disabled="true"
              ></Autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Date de départ">
              <el-input
                v-model="formEditOrder.date_depart"
                type="text"
                placeholder="Date de départ"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Prix TTC par moto (après toutes les réductions)">
              <el-input
                type="number"
                v-model.number="formEditOrder.price_unit"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Taux voyageur supplémentaire (%)">
              <el-input
                type="number"
                v-model="formEditOrder.price_passenger_rate"
                :disabled="true"
              /> = {{ parseFloat((formEditOrder.price_unit * (formEditOrder.price_passenger_rate / 100)).toFixed(2)) }}€
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item 
              :label="`Nombre de motos`" 
            >
              <el-input
                type="number"
                v-model.number="formEditOrder.quantity_moto"
                :disabled="true"
              />
            </el-form-item><br>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="`Nombre de voyageurs`"
            >
              <el-input
                type="number"
                v-model.number="formEditOrder.quantity_passenger"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
          <br>
          <hr>
          <br>
          <el-form-item label="Coordonnées de contact">
            <el-col :span="12">
              <el-form-item label="Prénom" prop="firstname">
                <el-input type="text" v-model="formEditOrder.user.firstname" :disabled="true"/>
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Nom" prop="lastname">
                <el-input
                  type="text"
                  v-model="formEditOrder.user.lastname"
                  :disabled="true"
                />
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="E-mail" prop="email">
                <el-input type="email" v-model="formEditOrder.user.email" :disabled="true"/>
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Téléphone" prop="phone">
                <el-input type="text" v-model="formEditOrder.user.phone" :disabled="true"/>
              </el-form-item><br>
            </el-col>
          </el-form-item>
          <el-form-item label="Facture" v-if="formEditOrder.status == 2">     
            <el-upload
              ref="uploadInvoiceRef"
              class="upload-demo"
              :action="config.API_BASE_URL + '/order/upload/invoice'"
              :on-success="handleUploadSuccessInvoice"
              :limit="1"
              :file-list="invoiceList"
              :auto-upload="false"
              :show-file-list="true"
              accept=".pdf,.docx"
            >
              <template #trigger>
                <el-button type="primary">Ajouter</el-button>
              </template>
              <el-button class="ml-3" type="success" @click="saveInvoice">
                Enregistrer & envoyer la facture
              </el-button>
              
              <el-button v-if="formEditOrder.path_invoice" class="ml-3" type="info" @click="showInvoice">
                Visualiser la facture
              </el-button>

              <template #tip>
                <div class="el-upload__tip rose">
                  limit 1 file(*.pdf/.docx only)
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <hr>
          <br>
          <div
            v-for="(moto, index) in formEditOrder.moto_list"
            :key="index"
          >
            <el-form-item
              v-if="index === 0"
              label="Liste des motos :"
            ></el-form-item>
            <el-col :span="24">
              <el-form-item>
                <el-input type="text" v-model="moto.model" placeholder="Modèle" :disabled="true" />
              </el-form-item>
            </el-col>
          </div>
          <div v-if="formEditOrder.passenger_list.length > 0">
            <br>
            <hr>
            <br>
            <div
              v-for="(passenger, index) in formEditOrder.passenger_list"
              :key="index"
            >
              <el-form-item
                v-if="index === 0"
                label="Liste des voyageurs :"
              ></el-form-item>
              <el-col :span="6">
                <el-form-item>
                  <el-input type="text" v-model="passenger.firstname" placeholder="Prénom" :disabled="true"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-input type="text" v-model="passenger.lastname" placeholder="NOM" :disabled="true"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-input type="text" v-model="passenger.email" placeholder="E-mail" :disabled="true"/>
                </el-form-item>
              </el-col>
            </div>
          </div>
          <el-form-item label="Billet" v-if="formEditOrder.status == 2">     
            <el-upload
              ref="uploadTicketRef"
              class="upload-demo"
              :action="config.API_BASE_URL + '/order/upload/ticket'"
              :on-success="handleUploadSuccessTicket"
              :limit="1"
              :file-list="ticketList"
              :auto-upload="false"
              :show-file-list="true"
              accept=".pdf,.docx"
            >
              <template #trigger>
                <el-button type="primary">Ajouter</el-button>
              </template>
              <el-button class="ml-3" type="success" @click="saveTicket">
                Enregistrer & envoyer le billet
              </el-button>
              
              <el-button v-if="formEditOrder.path_ticket" class="ml-3" type="info" @click="showTicket">
                Visualiser le billet
              </el-button>

              <template #tip>
                <div class="el-upload__tip rose">
                  limit 1 file(*.pdf/.docx only)
                </div>
              </template>
            </el-upload>
          </el-form-item>
          <hr>
          <br>
          <div v-if="formEditOrder.quantity_passenger === 0">
            <br><br>
            <el-col :span="24">
              <el-form-item >
                <el-checkbox-group v-model="formEditOrder.charge">
                  <el-checkbox value="true" name="charge" :disabled="true">
                    Adresse de chargement personnalisée : 
                    <el-input type="number" v-model.number="formEditOrder.price_charge" style="width: 50px" :disabled="true"/>€ TTC
                    <el-form-item v-if="formEditOrder.charge.length > 0">
                      <el-input v-model="formEditOrder.address_charge" placeholder="Adresse de chargement" :disabled="true"/>
                    </el-form-item>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>    

            <br><br>

            <el-col :span="24">
              <el-form-item >
                <el-checkbox-group v-model="formEditOrder.discharge">
                  <el-checkbox value="true" name="discharge" :disabled="true">
                    Adresse de déchargement personnalisée : 
                    <el-input type="number" v-model.number="formEditOrder.price_discharge" style="width: 50px" :disabled="true"/>€ TTC
                    <el-form-item v-if="formEditOrder.discharge.length > 0">
                      <el-input v-model="formEditOrder.address_discharge" placeholder="Adresse de déchargement" :disabled="true"/>
                    </el-form-item>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>  
          </div>  

          <br>
          <br>

          <el-col :span="24">
            <el-form-item prop="insurance">
              <el-checkbox-group v-model="formEditOrder.insurance">
                <el-checkbox value="true" name="insuranceReservation" :disabled="true">
                  Assurance annulation : 
                  <el-input type="number" v-model.number="formEditOrder.price_insurance_rate" style="width: 50px" :disabled="true"/>% 
                  {{ (formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                  ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ?
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 ) 
                   <= 500 ? 50 : 
                   Math.floor(((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) * 
                   (formEditOrder.price_insurance_rate / 100)) }}€ TTC
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <br /><br />
          <el-col :span="24">
            <el-form-item label="Code promo">
              <el-input type="text" v-model="formEditOrder.code" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="24"></el-col>
          <div v-if="formEditOrder.price_unit > 0">
            <hr v-if="formEditOrder.price_unit > 0"> 
            <el-col v-if="formEditOrder.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formEditOrder.price_unit > 0" :span="24"><b>Chiffrage (Prix HT/TTC) :</b></el-col>
            <el-col v-if="formEditOrder.price_unit > 0" :span="24"></el-col>
            <div class="price-details">
              <el-col v-if="formEditOrder.price_unit > 0 && formEditOrder.code" :span="24">Code promo <b>{{ formEditOrder.code }}</b> appliqué</el-col>
              <el-col v-if="formEditOrder.price_unit > 0 && formEditOrder.code" :span="24"></el-col>
              <el-col v-if="formEditOrder.price_unit > 0 && formEditOrder.quantity_moto > 0" :span="24" style="font-size: 15px;">- Moto <b>x{{formEditOrder.quantity_moto}}</b> = {{(parseFloat(formEditOrder.price_unit) * formEditOrder.quantity_moto / (formEditOrder.quantity_passenger > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{(parseFloat(formEditOrder.price_unit) * formEditOrder.quantity_moto).toFixed(2) }}€</b></el-col>
              <el-col v-if="formEditOrder.price_unit > 0 && formEditOrder.quantity_passenger > 0" :span="24" style="font-size: 15px;">- Voyageur <b>x{{formEditOrder.quantity_passenger}}</b> = {{formEditOrder.quantity_passenger <= formEditOrder.quantity_moto ? "0" : parseFloat((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit / 1.1).toFixed(2) }}€ / <b>{{formEditOrder.quantity_passenger <= formEditOrder.quantity_moto ? "0" : parseFloat((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                    (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit).toFixed(2) }}€</b></el-col>
              <el-col v-if="formEditOrder.price_unit > 0" :span="24"></el-col>
              <el-col v-if="formEditOrder.price_unit > 0 && formEditOrder.insurance.length > 0" :span="24" style="font-size: 15px;">- Assurance annulation = {{ 
              ((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) <= 500 ? parseFloat(50 / (formEditOrder.quantity_passenger > 0 ? 1.1 : 1.2)).toFixed(2) : 
                   Math.floor(((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) * (formEditOrder.price_insurance_rate / 100) / (formEditOrder.quantity_passenger > 0 ? 1.1 : 1.2)) }}€ / <b>
                    {{ ((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) * (formEditOrder.price_insurance_rate / 100)) }}€</b></el-col>
              <el-col v-if="formEditOrder.price_unit > 0 && formEditOrder.charge.length > 0" :span="24" style="font-size: 15px;">- Adresse de chargement personnalisée = {{ parseFloat(formEditOrder.price_charge / (formEditOrder.quantity_passenger > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ formEditOrder.price_charge }}€</b></el-col>
              <el-col v-if="formEditOrder.price_unit > 0 && formEditOrder.discharge.length > 0" :span="24" style="font-size: 15px;">- Adresse de déchargement personnalisée = {{ parseFloat(formEditOrder.price_discharge / (formEditOrder.quantity_passenger > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ formEditOrder.price_discharge }}€</b></el-col>
            </div>
            <el-col v-if="formEditOrder.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formEditOrder.price_unit > 0" :span="24" style="color: #e6007e; font-size: 20px; text-align: right;"><b>
              Total : {{ parseFloat((parseFloat(((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 ))) + 
                (formEditOrder.insurance.length > 0 ? 
                ((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) * (formEditOrder.price_insurance_rate / 100))
                : 0) + 
                (formEditOrder.charge.length > 0 ? formEditOrder.price_charge : 0) +
                (formEditOrder.discharge.length > 0 ? formEditOrder.price_discharge : 0)) / (formEditOrder.quantity_passenger > 0 ? 1.1 : 1.2)).toFixed(2) }}€ HT / {{ parseFloat(parseFloat(((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 ))) + 
                (formEditOrder.insurance.length > 0 ? 
                ((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) <= 500 ? 50 : Math.floor(((formEditOrder.price_unit * formEditOrder.quantity_moto) + 
                   ((formEditOrder.quantity_passenger - formEditOrder.quantity_moto) > 0 ? 
                   (formEditOrder.quantity_passenger - formEditOrder.quantity_moto) * 
                   (formEditOrder.price_passenger_rate / 100) * formEditOrder.price_unit : 0 )) * (formEditOrder.price_insurance_rate / 100))
                : 0) + 
                (formEditOrder.charge.length > 0 ? formEditOrder.price_charge : 0) +
                (formEditOrder.discharge.length > 0 ? formEditOrder.price_discharge : 0)).toFixed(2) }}€ TTC</b></el-col>
            <el-col v-if="formEditOrder.price_unit > 0" :span="24"></el-col>
            <el-col :span="24"></el-col>
          </div>
          
          <el-col :span="24">
            <el-form-item label="Statut" required>
              <el-select
                v-model="formEditOrder.status"
                size="large"
              >
                <el-option
                  v-for="item in orderStatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Remarque :">
              <el-input
                type="textarea"
                v-model="formEditOrder.note"
              />
            </el-form-item>
          </el-col>
          <br>
        </div>
        <div class="flex space-between">
          <el-button @click="editOrder()" color="#000047">Enregistrer</el-button>
        </div>
        <br />
      </el-form>
      <div class='histories'>Historiques</div>   
      <el-table v-if="histories['orders']" :data="filteredOrders" :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column label="Utilisateur" prop="id_user">
          <template #default="{ row }">
            {{ usersMap.get(row.id_user) || 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="ID cible" prop="id_category" />
        <el-table-column label="Opération" prop="action" />
        <el-table-column label="Date d'action" prop="date">
          <template #default="{ row }">
            {{
              formattedDatetime(row.date)
            }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog center :title="`Liste Devis`" color="white" v-model="showRouteOrderPopup" :close-on-click-modal="false">
      <el-table v-if="orders.length > 0" :data="tmpResults" stripe>
        <el-table-column type="expand">
          <template #default="props">
            <el-table style="padding: 0 0 0 50px" v-if="props.row.subOrders" :data="props.row.subOrders" border>
              <el-table-column label="Date de création" prop="date_creation">
                <template #default="{ row }">
                  {{ formattedDatetime(row.date_creation) }}
                </template>
              </el-table-column>
              <el-table-column label="Statut" prop="status">
                <template #default="{ row }">
                  <div class="status" :style="`background: ${orderStatus[row.status - 1].color}`">{{ orderStatus[row.status - 1].name || ''}}</div>
                </template>
              </el-table-column>
              <el-table-column label="Date paiement" prop="date_payment">
                <template #default="{ row }">
                  {{ formattedDatetime(row.date_paiement) || ''}}
                </template>
              </el-table-column>
              <el-table-column label="ID Paiement" prop="id" />
              <el-table-column label="Prix" prop="price" />
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="Date de création" prop="date_creation">
          <template #default="{ row }">
            {{ formattedDatetime(row.date_creation) }}
          </template>
        </el-table-column>
        <el-table-column label="Statut" prop="status">
          <template #default="{ row }">
            <div class="status" :style="`background: ${orderStatus[row.status - 1].color}`">{{ orderStatus[row.status - 1].name || ''}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Date paiement" prop="date_payment">
          <template #default="{ row }">
            {{ formattedDatetime(row.date_paiement) || ''}}
          </template>
        </el-table-column>
        <el-table-column label="ID Devis" prop="id" />
        <el-table-column label="ID Chiffrage" prop="id_estimate" />
        <el-table-column label="E-mail" prop="email" />
        <el-table-column label="Client">
          <template #default="{ row }">
            {{ users.filter((item) => item.email === row.email).length > 0 ? (users.filter((item) => item.email === row.email)[0].firstname 
            + ' ' + users.filter((item) => item.email === row.email)[0].lastname) : 'Compte non trouvé' }}
          </template>
        </el-table-column>
        <el-table-column label="Départ" prop="depart" />
        <el-table-column label="Arrivée" prop="arrival" />
        <el-table-column label="Date Départ" prop="date_depart" />
        <el-table-column label="Prix total TTC" prop="price" />
        <el-table-column label="Type paiement" prop="payment_installment">
          <template #default="{ row }">
            {{row.payment_installment == 1 ? 'Paiement en plusieurs fois' : '1x'}}
          </template>
        </el-table-column>
        <el-table-column label="Reste à payer" prop="payment_installment">
          <template #default="{ row }">
            {{row.payment_installment == 1 ? row.debt : ''}}
          </template>
        </el-table-column>
        <el-table-column label="Billet" prop="path_ticket">
          <template #default="{ row }">
            <div class="status" :style="`background: ${row.path_ticket ? '#5cbf2a' : '#ababab'}`">{{ row.path_ticket ? 'Envoyé' : 'À envoyer'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Facture" prop="path_invoice">
          <template #default="{ row }">
            <div class="status" :style="`background: ${row.path_invoice ? '#5cbf2a' : '#ababab'}`">{{ row.path_invoice ? 'Envoyé' : 'À envoyer'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="Remarque" prop="note" />
        <el-table-column label="Opération">
          <template #default="{ row }">
            <button class="btn-blue" type="button" @click="openEditOrderPopup(row)">
              Consulter
            </button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import axios from "axios";
import Autocomplete from "@/components/Autocomplete.vue";
import config from "@/config";
import Cookies from "js-cookie";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ElNotification } from "element-plus";
//import { ref } from 'vue';
export default {
  name: "AdminSpace",
  components: {
    QuillEditor,
    Autocomplete,
  },
  data() {
    return {
      arrivalEdit: null,
      channels: [],
      config: config,
      currentTab: "routes",
      departEdit: null,
      discountCodes: [],
      estimates: [],
      estimateStatus: [],
      events: [],
      layover1Edit: null,
      layover2Edit: null,
      layover3Edit: null,
      loadingEditOrder: false,
      loadingEstimate: false,
      loadingNewEstimate: false,
      loadingNewPayment: false,
      loadingReservation: false,
      formDataDiscountCode: {
        code: "",
        type: 1,
        value: 0,
        date_expiration: null,
        list_route: []
      },
      formDataEstimate: {
        address_charge: '',
        address_discharge: '',
        charge: [],
        discharge: [],
        price_charge: 0,
        price_discharge: 0,
        price_unit: 0,
        price_passenger_rate: 0,
        price_insurance_rate: 0,
        date_depart: '',
        date_return: '',
        depart: "",
        arrival: "",
        moto_nb: 0,
        moto_list: [],
        passenger_nb: 0,
        passenger_list: [],
        code: "",
        how: "",
        comment: "",
        id_route_1: 0,
        id_route_2: 0,
        insurance: [],
        status: 1,
        type: 1,
        email: '',
        phone: '',
        lastname: '',
        firstname: '',
        note: ''
      },
      formDataEvent: {
        name: "",
        date_start: "",
        date_end: "",
        img: "",
        link: "",
        details: "",
        location: "",
      },
      formDataNews: {
        title: "",
        html: "",
        cover: "",
        video: "",
        fileCover: null,
        fileVideo: null,
      },
      formDataSubscriber: {
        email: ""
      },
      formDataRoute: {
        eco: "",
        depart: "",
        arrival: "",
        layover1: "",
        layover2: "",
        layover3: "",
        address_depart: "",
        address_arrival: "",
        address_layover1: "",
        address_layover2: "",
        address_layover3: "",
        price0_1: null,
        price0_2: null,
        price0_3: null,
        price0_4: null,
        price1_2: null,
        price1_3: null,
        price1_4: null,
        price2_3: null,
        price2_4: null,
        price3_4: null,
        price_passenger_0_1: 30,
        price_passenger_0_2: 30,
        price_passenger_0_3: 30,
        price_passenger_0_4: 30,
        price_passenger_1_2: 30,
        price_passenger_1_3: 30,
        price_passenger_1_4: 30,
        price_passenger_2_3: 30,
        price_passenger_2_4: 30,
        price_passenger_3_4: 30,
        moto_max: 6,
        passenger_max: 7,
        time0: "",
        time1: "",
        time2: "",
        time3: "",
        time4: "",
        discount_type: "1",
        discount_value: null,
        discount_group_value: null,
        discount_group_moto: 0,
        completed: "",
      },
      formDataUser: {
        firstname: "",
        lastname: "",
        type: 3,
        society_name: "",
        society_address: "",
        address: "",
        email: "",
        phone: ""
      },
      formEditEstimate: {
        address_charge: '',
        address_discharge: '',
        charge: [],
        discharge: [],
        price_charge: 0,
        price_discharge: 0,
        price_unit: 0,
        price_passenger_rate: 0,
        price_insurance_rate: 0,
        date_depart: null,
        date_return: null,
        depart: "",
        arrival: "",
        moto_nb: 0,
        moto_list: [],
        passenger_nb: 0,
        passenger_list: [],
        code: "",
        how: "",
        comment: "",
        id: 0,
        insurance: [],
        status: 1,
        type: 1,
        row: null,
        user: null,
        note: '',
        address_charge_return: '',
        address_discharge_return: '',
        charge_return: [],
        discharge_return: [],
        price_charge_return: 0,
        price_discharge_return: 0,
        price_unit_return: 0,
        price_passenger_rate_return: 0,
        price_insurance_rate_return: 0,
        depart_return: "",
        arrival_return: "",
        moto_nb_return: 0,
        moto_list_return: [],
        passenger_nb_return: 0,
        passenger_list_return: [],
        insurance_return: [],
        msg: ''
      },
      formEditOrder: {},
      formDataPayment: {
        price: 0,
        id_main_order: null,
        row: []
      },
      formReservation: {
        address_charge: '',
        address_discharge: '',
        charge: [],
        checkCode: false,
        discharge: [],
        price: "",
        price_charge: this.charge,
        price_discharge: this.charge,
        price_unit_origin: 0,
        price_passenger: 0,
        price_passenger_origin: 0,
        price_passenger_rate: 0,
        price_insurance_rate: this.insurance * 100,
        date_depart: null,
        depart: "",
        arrival: "",
        moto_nb: 0,
        moto_list: [],
        moto_list_estimate: '',
        passenger_nb: 0,
        passenger_list: [],
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        code: "",
        how: "",
        comment: "",
        id: "",
        title: "",
        route: null,
        insurance: [],
        price_unit: 0,
        total: 0,
        option: "",
        places_moto: 0,
        places_passenger: 0,
        status: 1,
        type: 1,
        subscriber: [],
        conditionCheck: [],
        row: null,
        payment_installment: [],
        id_estimate: null,
        note: '',
        price_firstpayment: 0
      },
      options: [],
      //News editor
      optionsQuill: {
        modules: {
          toolbar: {
            container: [
              [{ header: 1 }, { header: 2 }, { font: [] }],
              [{ size: ["small", false, "large", "huge"] }],
              ["bold", "italic", "underline", "strike"],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ["link"],
              ["clean"],
            ],
          },
        },
      },
      orders: [],
      orderStatus: [
        {id: 1, name: 'En attente', color: '#e6df00'},
        {id: 2, name: 'Payé', color: '#5cbf2a'},
        {id: 3, name: 'Annulé', color: '#b71111'}
      ],
      news: [],
      routes: [],
      refreshAutocomplete: 0,
      rulesFormDataEstimate: {
        email: [
          { required: true, message: "Champ requis", trigger: "blur" }, 
        ],
        phone: [
          { required: true, message: "Champ requis", trigger: "blur" },
        ],
        firstname: [
          { required: true, message: "Champ requis", trigger: "blur" }, 
        ],
        lastname: [
          { required: true, message: "Champ requis", trigger: "blur" },
        ],
      },
      rulesFormDataPayment: {
        id_main_order: [
          { required: true, message: "Champ requis", trigger: ["blur", "change"] }, 
        ],
        price: [
          { required: true, message: "Champ requis", trigger: "blur" },
        ]
      },
      rulesReservation: {
        address_charge: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        address_discharge: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        depart: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        arrival: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        date_depart: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: false,
            validator: this.checkDiscountCode,
            trigger: "blur",
          },
        ],
        moto_nb: [
          {
            required: true,
            validator: this.checkReservationMotoPassengerCount,
            trigger: "blur",
          },
        ],
        passenger_nb: [
          {
            required: true,
            validator: this.checkReservationMotoPassengerCount,
            trigger: "blur",
          },
        ],
        firstname: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        lastname: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Veuillez saisir une adresse E-mail valide",
            validator: (rule, value, callback) => {
              const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (!emailPattern.test(value) && !value) {
                callback(new Error(`Adresse e-mail non valide`));
              }
              callback(); 
            },
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        id_estimate: [
          {
            required: true,
            message: "Champ requis",
            trigger: ["blur", "change"],
          },
        ],
        price_firstpayment: [
          {
            required: true,
            message: "Champ requis",
            trigger: ["blur", "change"],
          },
        ],
        conditionCheck: [
          {
            type: "array",
            required: true,
            message: "Veuillez cocher la case",
            trigger: "change",
          },
        ],
      },
      showEstimatePopup: false,
      showOrderPopup: false,
      showReservationPopup: false,
      showRouteOrderPopup: false,
      tmpResults: [],
      stepReservation: 0,
      subscribers: [],
      ticketList: [],
      invoiceList: [],
      tmpDiscountCodes: [],
      tmpEstimates: [],
      tmpEvents: [],
      tmpNews: [],
      tmpOrders: [],
      tmpRoutes: [],
      tmpSubscribers: [],
      tmpUsers: [],
      token: null,
      users: [],
      histories: [],
      filterRoutes: {
        id: null,
        depart: null,
        arrival: null,
        layover1: null,
        layover2: null,
        layover3: null,
        date_depart: null
      },
      filterEstimates: {
        id: null,
        depart: null,
        arrival: null,
        status: null,
        email: null,
        note: null,
        date_depart: null,
        date_return: null,
        firstname: null,
        lastname: null,
        quantity_moto: null,
        quantity_passenger: null,
        phone: null,
        year: null,
        how: null,
        code: null,
        price: null
      },
      filterOrders: {
        id: null,
        id_estimate: null,
        depart: null,
        arrival: null,
        email: null,
        status: null,
        path_invoice: null,
        path_ticket: null,
        date_depart: null,
        note: null
      },
      filteredRoutes: [],
    };
  },
  setup() {
    const setCellClass = ({ row, column}) => {
      const cellValue = row[column.property];
      return cellValue === '' || cellValue === null ? "empty-background" : "";
    };

    return {
      setCellClass,
    };
  },
  created() {
    document.title = "Admin - Symone";
  },
  computed: {
    filteredOrders() {
      return this.histories['orders'].filter(i => i.id_category === this.formEditOrder.id);
    },
    filteredEstimates() {
      return this.histories['estimates'].filter(i => i.id_category === this.formEditEstimate.id);
    },
    usersMap() {
      const usersMap = new Map();
      this.users.forEach(user => {
        usersMap.set(user.id, `${user.firstname} ${user.lastname}`);
      });
      return usersMap;
    }
  },
  watch: {
    currentTab(value) {
      this.checkAuthToken();
      this.getChannels();
      this.getNews();
      this.getRoutes();
      this.getEvents();
      this.getHistories();
      this.getUsers();
      this.getEstimates();
      this.getEstimateStatus();
      this.getOptions();
      this.getSubscribers();
      this.getDiscountCodes();
      this.getOrders();
      if(value == 'estimates'){
        this.formDataEstimate.price_charge = this.options[1].value;
        this.formDataEstimate.price_discharge = this.options[1].value;
        this.formDataEstimate.price_insurance_rate = this.options[0].value;
      }
    },
    formDataRoute: function (val) {
      console.log(val.address_depart);
    },
    stepReservation(value) {
      if (value == 3) {
        this.$nextTick(() => {
          this.formReservation.passenger_list[0].firstname = this.formReservation.firstname;
          this.formReservation.passenger_list[0].lastname = this.formReservation.lastname;
          this.formReservation.passenger_list[0].email = this.formReservation.email;
        });
      }
      if (value == 4 && this.formReservation.price_unit > 0) {
        this.$nextTick(() => {
          this.$refs.ruleReservationRef
            .validateField('code', (valid) => {
              if(valid){
                console.log('Validation passed');
              } else {
                return;
              }
            });
        });
      }
      this.$nextTick(() => {
        let targetElement = document.getElementById("formReservationTop");
        if (targetElement)
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
      });
    },
    
  },
  mounted() {
    this.checkAuthToken();
    this.getChannels();
    this.getNews();
    this.getRoutes();
    this.getEvents();
    this.getUsers();
    this.getEstimates();
    this.getEstimateStatus();
    this.getOptions();
    this.getSubscribers();
    this.getDiscountCodes();
    this.getOrders();
    this.getHistories();
  },
  methods: {
    routesFilter(){
      let tmp = JSON.parse(JSON.stringify(this.routes));
      if(this.filterRoutes.id) tmp = tmp.filter(i => i.id.toString().includes(this.filterRoutes.id));
      if(this.filterRoutes.depart) tmp = tmp.filter(i => i.depart && i.depart.toLowerCase().includes(this.filterRoutes.depart.toLowerCase()));
      if(this.filterRoutes.arrival) tmp = tmp.filter(i => i.arrival && i.arrival.toLowerCase().includes(this.filterRoutes.arrival.toLowerCase()));
      if(this.filterRoutes.layover1) tmp = tmp.filter(i => i.layover1 && i.layover1.toLowerCase().includes(this.filterRoutes.layover1.toLowerCase()));
      if(this.filterRoutes.layover2) tmp = tmp.filter(i => i.layover2 && i.layover2.toLowerCase().includes(this.filterRoutes.layover2.toLowerCase()));
      if(this.filterRoutes.layover3) tmp = tmp.filter(i => i.layover3 && i.layover3.toLowerCase().includes(this.filterRoutes.layover3.toLowerCase()));
      if(this.filterRoutes.date_depart) tmp = tmp.filter(i => i.date_depart && i.date_depart == this.filterRoutes.date_depart);
      this.filteredRoutes = tmp;
    },
    estimatesFilter(){
      let tmp = JSON.parse(JSON.stringify(this.estimates));
      if(this.filterEstimates.id) tmp = tmp.filter(i => i.id.toString().includes(this.filterEstimates.id));
      if(this.filterEstimates.depart) tmp = tmp.filter(i => i.depart && i.depart.toLowerCase().includes(this.filterEstimates.depart.toLowerCase()));
      if(this.filterEstimates.arrival) tmp = tmp.filter(i => i.arrival && i.arrival.toLowerCase().includes(this.filterEstimates.arrival.toLowerCase()));
      if(this.filterEstimates.price) tmp = tmp.filter(i => i.price && i.price.toLowerCase().includes(this.filterEstimates.price.toLowerCase()));
      if(this.filterEstimates.status) tmp = tmp.filter(i => i.status && i.status.toString() == this.filterEstimates.status.toString());
      if(this.filterEstimates.email) tmp = tmp.filter(i => i.email && i.email.toLowerCase().includes(this.filterEstimates.email.toLowerCase()));
      if(this.filterEstimates.note) tmp = tmp.filter(i => i.note && i.note.toLowerCase().includes(this.filterEstimates.note.toLowerCase()));
      if(this.filterEstimates.date_depart) tmp = tmp.filter(i => i.date_depart && i.date_depart.toLowerCase().includes(this.filterEstimates.date_depart.toLowerCase()));
      if(this.filterEstimates.date_return) tmp = tmp.filter(i => i.date_depart && i.date_return.toLowerCase().includes(this.filterEstimates.date_return.toLowerCase()));
      if(this.filterEstimates.lastname) tmp = tmp.filter(i => i.lastname && i.lastname.toLowerCase().includes(this.filterEstimates.lastname.toLowerCase()));
      if(this.filterEstimates.firstname) tmp = tmp.filter(i => i.firstname && i.firstname.toLowerCase().includes(this.filterEstimates.firstname.toLowerCase()));
      if(this.filterEstimates.quantity_moto) tmp = tmp.filter(i => i.quantity_moto && i.quantity_moto.toString().includes(this.filterEstimates.quantity_moto.toString()));
      if(this.filterEstimates.quantity_passenger) tmp = tmp.filter(i => i.quantity_passenger && i.quantity_passenger.toString().includes(this.filterEstimates.quantity_passenger.toString()));
      if(this.filterEstimates.phone) tmp = tmp.filter(i => i.phone && i.phone.toString().includes(this.filterEstimates.phone.toString()));
      if(this.filterEstimates.year) tmp = tmp.filter(i => i.route_year && i.route_year.toString().includes(this.filterEstimates.year.toString()));
      if(this.filterEstimates.code) tmp = tmp.filter(i => i.discount_code && i.discount_code.toString().includes(this.filterEstimates.code.toString()));
      if(this.filterEstimates.how) tmp = tmp.filter(i => i.how && i.how.toString() == this.filterEstimates.how.toString());
      this.tmpEstimates = [...tmp];
    },
    ordersFilter(){
      let tmp = JSON.parse(JSON.stringify(this.orders));
      if(this.filterOrders.id) tmp = tmp.filter(i => i.id.toString().includes(this.filterOrders.id));
      if(this.filterOrders.id_estimate) tmp = tmp.filter(i => i.id_estimate.toString().includes(this.filterOrders.id_estimate));
      if(this.filterOrders.depart) tmp = tmp.filter(i => i.depart && i.depart.toLowerCase().includes(this.filterOrders.depart.toLowerCase()));
      if(this.filterOrders.arrival) tmp = tmp.filter(i => i.arrival && i.arrival.toLowerCase().includes(this.filterOrders.arrival.toLowerCase()));
      if(this.filterOrders.status) tmp = tmp.filter(i => i.status.toString() == this.filterOrders.status.toString());
      if(this.filterOrders.email) tmp = tmp.filter(i => i.email && i.email.toLowerCase().includes(this.filterOrders.email.toLowerCase()));
      if(this.filterOrders.note) tmp = tmp.filter(i => i.note && i.note.toLowerCase().includes(this.filterOrders.note.toLowerCase()));
      if(this.filterOrders.date_depart) tmp = tmp.filter(i => i.date_depart && i.date_depart == this.filterOrders.date_depart);
      if(this.filterOrders.path_invoice) tmp = this.filterOrders.path_invoice == 2 ? tmp.filter(i => i.path_invoice) : tmp.filter(i => !i.path_invoice);
      if(this.filterOrders.path_ticket) tmp = this.filterOrders.path_ticket == 2 ? tmp.filter(i => i.path_ticket) : tmp.filter(i => !i.path_ticket);
      this.tmpOrders = [...tmp];
    },
    async editOrder(){
      this.loadingEditOrder = true;
      try{
          const responses = await axios.post(
            `${config.API_BASE_URL}/order/edit`,
            { data: this.formEditOrder }
          );
          if(responses){
            
              ElNotification({
                title: "Success",
                message:
                  "Modification enregistrée avec succès.",
                type: "success",
                position: "bottom-right",
                duration: 10000,
              });
          }
          const responseNewHistory = await axios.post(
            `${config.API_BASE_URL}/history/add`,
            { data: { 
              id_user: this.token.id,  
              category: 'orders',
              id_category: this.formEditOrder.id,
              action: 'Modification'
            }}
          );
          console.log(responseNewHistory)
          this.loadingEditOrder = false;
          this.getOrders();
        } catch (error){
          console.error('Erreur:', error);
          ElNotification({
            title: "Erreur",
            message: error,
            type: "error",
            position: "bottom-right",
            duration: 10000,
          });
          this.loadingEditOrder = false;
        }
    },
    async handleUploadSuccessTicket(response){
      this.formEditOrder.path_ticket = response.url;
      this.ticketList = [];
      this.loadingEditOrder = true;
      try{
        const responses = await axios.post(
          `${config.API_BASE_URL}/order/edit`,
          { data: this.formEditOrder }
        );
        if(responses){
          const responseSend = await axios.post(
            `${config.API_BASE_URL}/order/send/ticket`,
            { data: this.formEditOrder }
          );
          const responseNewHistory = await axios.post(
            `${config.API_BASE_URL}/history/add`,
            { data: { 
              id_user: this.token.id,  
              category: 'orders',
              id_category: this.formEditOrder.id,
              action: 'Envoie Billet'
            }}
          );
          console.log(responseNewHistory)
          if(responseSend){
            ElNotification({
              title: "Success",
              message:
                "Billet envoyé avec succès.",
              type: "success",
              position: "bottom-right",
              duration: 10000,
            });
          }
        }
        this.loadingEditOrder = false;
      } catch (error){
        console.error('Erreur:', error);
        ElNotification({
          title: "Erreur",
          message: error,
          type: "error",
          position: "bottom-right",
          duration: 10000,
        });
        this.loadingEditOrder = false;
      }
    },
    async handleUploadSuccessInvoice(response){
      this.formEditOrder.path_invoice = response.url;
      this.invoiceList = [];
      this.loadingEditOrder = true;
      try{
        const responses = await axios.post(
          `${config.API_BASE_URL}/order/edit`,
          { data: this.formEditOrder }
        );
        if(responses){
          const responseSend = await axios.post(
            `${config.API_BASE_URL}/order/send/invoice`,
            { data: this.formEditOrder }
          );
          const responseNewHistory = await axios.post(
            `${config.API_BASE_URL}/history/add`,
            { data: { 
              id_user: this.token.id,  
              category: 'orders',
              id_category: this.formEditOrder.id,
              action: 'Envoie Facture'
            }}
          );
          console.log(responseNewHistory)
          if(responseSend){
            ElNotification({
              title: "Success",
              message:
                "Facture envoyé avec succès.",
              type: "success",
              position: "bottom-right",
              duration: 10000,
            });
          }
        }
        this.loadingEditOrder = false;
      } catch (error){
        console.error('Erreur:', error);
        ElNotification({
          title: "Erreur",
          message: error,
          type: "error",
          position: "bottom-right",
          duration: 10000,
        });
        this.loadingEditOrder = false;
      }
    },
    saveInvoice() {
      if (this.$refs.uploadInvoiceRef) {
        this.$refs.uploadInvoiceRef.submit();
      }
    },
    saveTicket() {
      if (this.$refs.uploadTicketRef) {
        this.$refs.uploadTicketRef.submit();
      }
    },
    showOrderListe(row){
      this.tmpResults = this.orders.filter(i => i.id_route == row.id)
      this.showRouteOrderPopup = true;
    },
    showInvoice(){
      if(this.formEditOrder.path_invoice) window.open(window.location.origin + 'api/invoice/' + this.formEditOrder.path_invoice, '_blank')
    },
    showTicket(){
      if(this.formEditOrder.path_ticket) window.open(window.location.origin + 'api/ticket/' + this.formEditOrder.path_ticket, '_blank')
    },
    calculatePlaces(tj){
      let nb_moto = tj.moto_max;
      let nb_passenger = tj.passenger_max;

      for (let i = 1; i <= Number(tj.option[1])-Number(tj.option[0]); i++) {
        tj[`places_moto_${i}`] < nb_moto ? nb_moto = tj[`places_moto_${i}`] : "";
        tj[`places_passenger_${i}`] < nb_passenger ? nb_passenger = tj[`places_passenger_${i}`] : "";
      }
      tj.places_moto = nb_moto;
      tj.places_passenger = nb_passenger;
      return tj;
    },
    checkAuthToken() {
      const tk = Cookies.get("authToken");
      if (!tk) {
        console.log("No token found.");
        this.token = null;
        this.$router.push("/login");
      } else {
        fetch(`${config.API_BASE_URL}/user/token`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${tk}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.valid && data.user.type !== 3) {
              console.log("Token is valid");
              this.token = data.user;
              this.$router.push("/admin");
            } else {
              console.log("Token is invalid.");
              Cookies.remove("authToken");
              this.token = null;
              this.$router.push("/login");
            }
          })
          .catch((error) => {
            console.error("Error validating token:", error);
            this.token = null;
            this.$router.push("/login");
          });
      }
    },
    async checkDiscountCode(rule, value, callback) {
      if(this.formReservation.row.discount_group_moto && this.formReservation.row.discount_group_value && this.formReservation.moto_nb >= this.formReservation.row.discount_group_moto ){
        this.formReservation.price_unit = this.formReservation.price_unit_origin * (1 - (this.formReservation.row.discount_group_value / 100));
        let price_passenger = 0;
        if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
        this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
      } else {
        this.formReservation.price_unit = this.formReservation.price_unit_origin;
        let price_passenger = 0;
        if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
        this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
      }
      this.formReservation.price_passenger = (this.formReservation.price_unit * this.formReservation.price_passenger_origin).toFixed(2);
      if (value) {
        try{
          const response = await axios.get(
            `${config.API_BASE_URL}/discountCode/verify/${value}/${this.formReservation.id}`
          );
          console.log(response.data);
          if(response.data && response.data.length > 0) {
            let price_passenger1 = 0;
            this.formReservation.price_unit = response.data[0].type == 1 ? (this.formReservation.price_unit * (1 - (response.data[0].value / 100))).toFixed(2) : (this.formReservation.price_unit - response.data[0].value).toFixed(2);
            this.formReservation.price_passenger = (this.formReservation.price_unit * this.formReservation.price_passenger_origin).toFixed(2);
            if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger1 = ((this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin)).toFixed(2);
            this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger1)).toFixed(2);
            this.formReservation.checkCode = true;
            callback();
          } else {
            callback(new Error('Code non valide'));
            this.formReservation.code = '';
            this.formReservation.checkCode = false;
          }
        } catch(error){
          if(error.response){
            callback(new Error(error.response.data.message));
            this.formReservation.code = '';
            this.formReservation.checkCode = false;
          }
        }
      } else {
        this.formReservation.checkCode = false;
        callback();
      }
    },
    checkReservationMotoPassengerCount(rule, value, callback) {
      if (value === '') {
        callback(new Error('Champ requis'));
      } else if (this.formReservation.moto_nb == 0) {
        callback(new Error('Le nombre de motos doit être > 0, sinon veuillez faire une demande de devis'));
      } else {
        if(this.formReservation.passenger_nb > (this.formReservation.moto_nb * 3)) this.formReservation.passenger_nb = this.formReservation.moto_nb * 3;
        if(value > 0) this.formReservation.charge = [];
        callback();
      }
    },
    async getChannels() {
      try {
        let response = await axios.get(
          `${config.API_BASE_URL}/channel/get`
        );
        this.channels = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error channels/get:", error);
      }
    },
    async getDiscountCodes() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/discountCode/get/all`);
        this.discountCodes = JSON.parse(JSON.stringify(response.data));
        this.tmpDiscountCodes = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error estimates/get:", error);
      }
    },
    async getEstimates() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/estimate/get/all`);
        this.estimates = JSON.parse(JSON.stringify(response.data));
        this.tmpEstimates = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error estimates/get:", error);
      }
    },
    async getEstimateStatus() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/estimate/get/status`);
        this.estimateStatus = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error estimates/get/status:", error);
      }
    },
    async getEvents() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/event/get/all`);
        this.events = JSON.parse(JSON.stringify(response.data));
        this.tmpEvents = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error events/get:", error);
      }
    },
    async getHistories() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/history/get/all`);
        this.histories = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error events/get:", error);
      }
    },

    async getNews() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/news/get/all`);
        this.news = JSON.parse(JSON.stringify(response.data));
        this.tmpNews = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error news/get:", error);
      }
    },
    async getOptions() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/option/get/all`);
        this.options = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error options/get:", error);
      }
    },
    async getOrders() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/order/get/all`);
        this.orders = JSON.parse(JSON.stringify(response.data));
        this.tmpOrders = JSON.parse(JSON.stringify(response.data));
        console.log('orders', this.orders);
      } catch (error) {
        console.error("Error users/get:", error);
      }
    },

    async getRoutes() {
      try {
        let response = await axios.get(
          `${config.API_BASE_URL}/route/get/all`
        );
        this.routes = JSON.parse(JSON.stringify(response.data));
        this.tmpRoutes = JSON.parse(JSON.stringify(response.data));
        this.filteredRoutes = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error routes/get:", error);
      }
    },
    async getSubscribers() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/subscriber/get/all`);
        this.subscribers = JSON.parse(JSON.stringify(response.data));
        this.tmpSubscribers = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error estimates/get:", error);
      }
    },

    async getUsers() {
      try {
        let response = await axios.get(`${config.API_BASE_URL}/user/get/all`);
        this.users = JSON.parse(JSON.stringify(response.data));
        this.tmpUsers = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error users/get:", error);
      }
    },

    async handleImageUpload(event) {
      this.formDataNews.fileCover = event.target.files[0];
    },
    async handleVideoUpload(event) {
      this.formDataNews.fileVideo = event.target.files[0];
    },

    async handleImageUploadEdit(props, event) {
      this.tmpNews[this.news.indexOf(props)]["fileCover"] =
        event.target.files[0];
    },
    async handleVideoUploadEdit(props, event) {
      this.tmpNews[this.news.indexOf(props)]["fileVideo"] =
        event.target.files[0];
    },

    
    nextStep(){
      if(this.stepReservation == 0 && this.formReservation.price_unit > 0) {
        let price_passenger = 0;
        this.formReservation.price_passenger = (this.formReservation.price_unit * this.formReservation.price_passenger_origin).toFixed(2);
        if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
        this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
      }
      const reservationFieldsToValidate = {
        0: ['depart', 'arrival', 'date_depart', 'moto_nb', 'passenger_nb'],
        1: ['email', 'firstname', 'lastname', 'phone'],
        4: ['code', 'address_charge', 'address_discharge'],
        5: ['conditionCheck']
      }

      if(this.stepReservation == 2){
        this.formReservation.moto_list.forEach((_, index) => {
          this.rulesReservation[`moto_list[${index}].model`] = [
            {
              required: true,
              message: "Champ requis",
              trigger: "blur",
            }
          ];
          this.$refs.ruleReservationRef
            .validateField(`moto_list[${index}].model`, (valid) => {
              if(valid){
                console.log('Validation passed');
              } else {
                return;
              }
            });
        });
      }

      if(this.stepReservation == 3){
        this.formReservation.passenger_list.forEach((_, index) => {
          this.rulesReservation[`passenger_list[${index}].firstname`] = [
            {
              required: true,
              message: "Champ requis",
              trigger: "blur",
            }
          ];
          this.rulesReservation[`passenger_list[${index}].lastname`] = [
            {
              required: true,
              message: "Champ requis",
              trigger: "blur",
            }
          ];
          this.rulesReservation[`passenger_list[${index}].email`] = [
            {
              required: true,
              message: "Veuillez saisir une adresse E-mail valide",
              validator: (rule, value, callback) => {
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!emailPattern.test(value) && !value) {
                  callback(new Error(`Adresse e-mail non valide`));
                }
                callback(); 
              },
              trigger: ["blur", "change"],
            }
          ];
          ['firstname', 'lastname', 'email'].forEach((field) => {
            this.$refs.ruleReservationRef
              .validateField(`passenger_list[${index}].${field}`, (valid) => {
                if(valid){
                  console.log('Validation passed');
                } else {
                  return;
                }
              });
          });
        });
      }

      const fields = reservationFieldsToValidate[this.stepReservation];

      this.$refs.ruleReservationRef.validateField(fields, (valid) => {
        if (valid) {
          if(this.formReservation.moto_nb == 0 && this.stepReservation == 1 && this.formReservation.passenger_nb > 0) this.stepReservation = this.stepReservation + 2;
          else if(this.formReservation.moto_nb == 0 && this.stepReservation == 1 && this.formReservation.passenger_nb == 0) this.stepReservation = this.stepReservation + 3;
          else if(this.stepReservation == 2 && this.formReservation.passenger_nb == 0) this.stepReservation = this.stepReservation + 2;
          else this.stepReservation++;
          
        } else {
          return;
        }
      })
    },
    
    openReservationPopup(row, option) {
      this.stepReservation = 0;
      this.formReservation = {
        address_charge: '',
        address_discharge: '',
        charge: [],
        checkCode: false,
        discharge: [],
        price: "",
        price_charge: this.charge,
        price_discharge: this.charge,
        price_unit_origin: 0,
        price_passenger: 0,
        price_passenger_origin: 0,
        price_passenger_rate: 0,
        price_insurance_rate: this.insurance * 100,
        date_depart: null,
        depart: "",
        arrival: "",
        moto_nb: 0,
        moto_list: [],
        moto_list_estimate: '',
        passenger_nb: 0,
        passenger_list: [],
        firstname: this.token ? this.token.firstname : "",
        lastname: this.token ? this.token.lastname : "",
        email: this.token ? this.token.email : "",
        phone: this.token ? this.token.phone : "",
        code: "",
        how: "",
        comment: "",
        id: "",
        title: "",
        route: null,
        insurance: [],
        total: 0,
        price_unit: 0,
        option: "",
        places_moto: 0,
        places_passenger: 0,
        status: 5,
        type: 1,
        subscriber: [],
        conditionCheck: [],
        row: row,
        payment_installment: [],
        id_estimate: null,
        price_firstpayment: 0
      };

      console.log(row)

        this.formReservation.title = `${row.depart}${
          row.layover1 ? " → " + row.layover1 : ""
        }${row.layover2 ? " → " + row.layover2 : ""}${
          row.layover3 ? " → " + row.layover3 : ""
        }→${row.arrival}`;
        this.formReservation.id = row.id;
        this.formReservation.date_depart = row.date_depart;
        this.formReservation.route = row;

            this.formReservation.option = option;
            row['option'] = option;
            let getPlaces = this.calculatePlaces(row);
            this.formReservation.places_moto = getPlaces.places_moto;
            this.formReservation.places_passenger = getPlaces.places_passenger;
            switch (option.toString()) {
              case [0, 1].toString():
                this.formReservation.price_passenger_rate = row.price_passenger_0_1;
                this.formReservation.depart = row.depart;
                this.formReservation.arrival = row.layover1;
                if (/^\d+$/.test(row.price0_1)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price0_1 + "€";
                    this.formReservation.price_unit = row.price0_1;
                    this.formReservation.price_unit_origin = row.price0_1;
                  }
                  else {
                    if (row.discount_type == 1) {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price0_1) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price0_1) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price0_1) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price0_1}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price =
                        parseInt(row.price0_1) - parseInt(row.discount_value);
                      this.formReservation.price_unit_origin = parseInt(row.price0_1) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                        parseInt(row.price0_1) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price0_1}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price0_1;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_0_1 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_0_1 / 100;
                }
                break;
              case [0, 2].toString():
                this.formReservation.price_passenger_rate = row.price_passenger_0_2;
                this.formReservation.depart = row.depart;
                this.formReservation.arrival = row.layover2;
                if (/^\d+$/.test(row.price0_2)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price0_2 + "€";
                    this.formReservation.price_unit = row.price0_2;
                    this.formReservation.price_unit_origin = row.price0_2;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price0_2) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price0_2) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price0_2) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price0_2}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price0_2) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                        parseInt(row.price0_2) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price0_2) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price0_2}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price0_2;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_0_2 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_0_2 / 100;
                }
                break;
              case [0, 3].toString():
                this.formReservation.depart = row.depart;
                this.formReservation.arrival = row.layover3;
                this.formReservation.price_passenger_rate = row.price_passenger_0_3;
                if (/^\d+$/.test(row.price0_3)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price0_3 + "€";
                    this.formReservation.price_unit = row.price0_3;
                    this.formReservation.price_unit_origin = row.price0_3;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price0_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price0_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price0_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price0_3}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price0_3) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                        parseInt(row.price0_3) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price0_3) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price0_3}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price0_3;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_0_3 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_0_3 / 100;
                }
                break;
              case [0, 4].toString():
                this.formReservation.depart = row.depart;
                this.formReservation.arrival = row.arrival;
                this.formReservation.price_passenger_rate = row.price_passenger_0_4;
                if (/^\d+$/.test(row.price0_4)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price0_4 + "€";
                    this.formReservation.price_unit = row.price0_4;
                    this.formReservation.price_unit_origin = row.price0_4;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price0_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price0_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price0_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price0_4}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                      parseInt(row.price0_4) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                      parseInt(row.price0_4) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price0_4) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price0_4}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price0_4;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_0_4 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_0_4 / 100;
                }
                break;
              case [1, 2].toString():
                this.formReservation.depart = row.layover1;
                this.formReservation.arrival = row.layover2;
                this.formReservation.price_passenger_rate = row.price_passenger_1_2;
                if (/^\d+$/.test(row.price1_2)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price1_2 + "€";
                    this.formReservation.price_unit = row.price1_2;
                    this.formReservation.price_unit_origin = row.price1_2;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price1_2) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price1_2) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price1_2) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price1_2}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                      parseInt(row.price1_2) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                      parseInt(row.price1_2) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price1_2) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price1_2}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price1_2;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_1_2 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_1_2 / 100;
                }
                break;
              case [1, 3].toString():
                this.formReservation.depart = row.layover1;
                this.formReservation.arrival = row.layover3;
                this.formReservation.price_passenger_rate = row.price_passenger_1_3;
                if (/^\d+$/.test(row.price1_3)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price1_3 + "€";
                    this.formReservation.price_unit = row.price1_3;
                    this.formReservation.price_unit_origin = row.price1_3;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price1_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price1_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price1_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price1_3}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                      parseInt(row.price1_3) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                      parseInt(row.price1_3) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price1_3) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price1_3}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price1_3;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_1_3 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_1_3 / 100;
                }
                break;
              case [1, 4].toString():
                this.formReservation.depart = row.layover1;
                this.formReservation.arrival = row.arrival;
                this.formReservation.price_passenger_rate = row.price_passenger_1_4;
                if (/^\d+$/.test(row.price1_4)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price1_4 + "€";
                    this.formReservation.price_unit = row.price1_4;
                    this.formReservation.price_unit_origin = row.price1_4;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price1_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price1_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price1_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price1_4}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                      parseInt(row.price1_4) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                      parseInt(row.price1_4) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price1_4) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price1_4}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price1_4;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_1_4 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_1_4 / 100;
                }
                break;
              case [2, 3].toString():
                this.formReservation.depart = row.layover2;
                this.formReservation.arrival = row.layover3;
                this.formReservation.price_passenger_rate = row.price_passenger_2_3;
                if (/^\d+$/.test(row.price2_3)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price2_3 + "€";
                    this.formReservation.price_unit = row.price2_3;
                    this.formReservation.price_unit_origin = row.price2_3;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price2_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price2_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price2_3) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price2_3}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                      parseInt(row.price2_3) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                      parseInt(row.price2_3) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price2_3) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price2_3}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price2_3;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_2_3 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_2_3 / 100;
                }
                break;
              case [2, 4].toString():
                this.formReservation.depart = row.layover1;
                this.formReservation.arrival = row.arrival;
                this.formReservation.price_passenger_rate = row.price_passenger_2_4;
                if (/^\d+$/.test(row.price2_4)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price2_4 + "€";
                    this.formReservation.price_unit = row.price2_4;
                    this.formReservation.price_unit_origin = row.price2_4;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price2_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price2_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price2_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price2_4}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                      parseInt(row.price2_4) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                      parseInt(row.price2_4) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price2_4) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price2_4}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price2_4;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_2_4 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_2_4 / 100;
                }
                break;
              case [3, 4].toString():
                this.formReservation.depart = row.layover3;
                this.formReservation.arrival = row.arrival;
                this.formReservation.price_passenger_rate = row.price_passenger_3_4;
                if (/^\d+$/.test(row.price3_4)) {
                  if (!row.discount_value){
                    this.formReservation.price = row.price3_4 + "€";
                    this.formReservation.price_unit = row.price3_4;
                    this.formReservation.price_unit_origin = row.price3_4;
                  }
                  else {
                    if (row.discount_type == "1") {
                      this.formReservation.price_unit_origin = 
                        parseInt(row.price3_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price_unit = 
                        parseInt(row.price3_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price =
                        parseInt(row.price3_4) *
                        (1 - parseInt(row.discount_value) / 100);
                      this.formReservation.price += `€(${row.price3_4}€ -${row.discount_value}%)`;
                    } else {
                      this.formReservation.price_unit_origin = 
                      parseInt(row.price3_4) - parseInt(row.discount_value);
                      this.formReservation.price_unit = 
                        parseInt(row.price3_4) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price3_4) - parseInt(row.discount_value);
                      this.formReservation.price =
                        parseInt(row.price3_4) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price3_4}€ -${row.discount_value}€)`;
                    }
                  }
                } else {
                  this.formReservation.price = row.price3_4;
                }
                if(this.formReservation.price_unit > 0) {
                  this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_3_4 / 100)).toFixed(2);
                  this.formReservation.price_passenger_origin = row.price_passenger_3_4 / 100;
                }
                break;
              default:
                this.formReservation.price = 0;
                this.formReservation.price_passenger = 0;
                break;
            }
      this.refreshAutocomplete++;
      this.showReservationPopup = true;
    },

    async sendReservation() {
      try {
        if (!this.$refs.ruleReservationRef) return;
        let fields = this.formReservation.payment_installment.length > 0 ? ['conditionCheck', 'id_estimate', 'price_firstpayment'] : ['conditionCheck', 'id_estimate'];
        console.log(this.formReservation)
        this.formReservation.price_charge = this.options[1].value;
        this.formReservation.price_discharge = this.options[1].value;
        this.formReservation.price_insurance_rate = this.options[0].value;
        this.$refs.ruleReservationRef
          .validateField(fields, async(valid) => {
            if(valid){
              console.log('Validation passed');
              
              this.loadingReservation = true;
              if(this.formReservation.price_unit > 0){
                this.formReservation['insurance_rate'] = 1 + (this.options[0].value / 100);
              } 
              const response = await axios.post(
                `${config.API_BASE_URL}/order/add/main`,
                this.formReservation
              );
              console.log(response.data);
              
              const responseNewHistory = await axios.post(
                `${config.API_BASE_URL}/history/add`,
                { data: { 
                  id_user: this.token.id,  
                  category: 'orders',
                  id_category: response.data.id,
                  action: 'Ajout'
                }}
              );
              console.log(responseNewHistory)
              if(response.data.payment_url) window.open(response.data.payment_url, '_blank');

              ElNotification({
                title: "Success",
                message:
                  "Lien de paiement envoyée, voir l'onglet Devis pour suivre tout avancement.",
                type: "success",
                position: "bottom-right",
                duration: 10000,
              });

              this.loadingReservation = false;
              this.showReservationPopup = false;
              this.getRoutes();
            } else {
              return;
            }
          });
      } catch (error) {
        console.error("Error Send/Reservation:", error);
        this.loadingReservation = false;
        ElNotification({
          title: "Erreur",
          message:
            "Une erreur est survenue.",
          type: "error",
          position: "bottom-right",
          duration: 10000,
        });
      }
    },

    async addNews() {
      // Save HTML content
      const htmlContent = this.formDataNews.html;

      // Upload image
      let imageName = "";
      if (this.formDataNews.fileCover) {
        imageName = await this.uploadFile(
          this.formDataNews.fileCover,
          "images"
        );
      }

      // Upload video
      let videoName = "";
      if (this.formDataNews.fileVideo) {
        videoName = await this.uploadFile(
          this.formDataNews.fileVideo,
          "videos"
        );
      }

      let newsTitle = this.formDataNews.title;

      // Send data to backend for saving
      await this.addNewsSend({ htmlContent, imageName, videoName, newsTitle });
    },

    async editNews(row) {
      // Save HTML content
      const htmlContent = this.tmpNews[this.news.indexOf(row)].html;

      // Upload image
      let imageName = row.cover;
      if (this.tmpNews[this.news.indexOf(row)].fileCover) {
        imageName = await this.uploadFileEdit(
          this.tmpNews[this.news.indexOf(row)].fileCover,
          "images",
          row.cover
        );
      }

      // Upload video
      let videoName = row.video;
      if (this.tmpNews[this.news.indexOf(row)].fileVideo) {
        videoName = await this.uploadFileEdit(
          this.tmpNews[this.news.indexOf(row)].fileVideo,
          "videos",
          row.video
        );
      }
      let newsId = row.id;
      let newsTitle = this.tmpNews[this.news.indexOf(row)].title;

      // Send data to backend for saving
      await this.editNewsSend({
        htmlContent,
        imageName,
        videoName,
        newsTitle,
        newsId,
      });
      const responseNewHistory = await axios.post(
        `${config.API_BASE_URL}/history/add`,
        { data: { 
          id_user: this.token.id,  
          category: 'news',
          id_category: row.id,
          action: 'Modification'
        }}
      );
      console.log(responseNewHistory)
    },
    async uploadFile(file, fileType) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetch(
        `${config.API_BASE_URL}/news/upload/${fileType}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await response.json();
      return responseData.fileName;
    },
    async uploadFileEdit(file, fileType, oldMedia = null) {
      const formData = new FormData();
      formData.append("file", file);
      if (oldMedia) formData.append("oldMedia", oldMedia);
      const response = await fetch(
        `${config.API_BASE_URL}/news/upload/${fileType}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const responseData = await response.json();
      return responseData.fileName;
    },
    async addNewsSend(data) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/news/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData); // handle response as needed
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'news',
            id_category: response.data.id,
            action: 'Ajout'
          }}
        );
        console.log(responseNewHistory)
        this.refreshNews();
        this.formDataNews = {
          title: "",
          html: "",
          cover: "",
          video: "",
          fileCover: null,
          fileVideo: null,
        };
      } catch (error) {
        console.error("Error News/add :", error);
      }
    },
    async addDiscountCodeSend() {
      try {
        const response = await axios.post(`${config.API_BASE_URL}/discountCode/add`, {
          data: this.formDataDiscountCode,
        });
        const responseData = await response;
        console.log(responseData);
        this.refreshDiscountCodes();
        this.formDataDiscountCode = {
          code: "",
          type: 1,
          date_expiration: null,
          list_route: [],
          value: 0
        };
      } catch (error) {
        console.error("Error Event/add :", error);
      }
    },
    async addEventSend() {
      try {
        const response = await axios.post(`${config.API_BASE_URL}/event/add`, {
          data: this.formDataEvent,
        });
        const responseData = await response;
        console.log(responseData); // handle response as needed
        this.refreshEvents();
        this.formDataEvent = {
          name: "",
          date_start: "",
          date_end: "",
          img: "",
          link: "",
          details: "",
          location: "",
        };
      } catch (error) {
        console.error("Error Event/add :", error);
      }
    },
    async addRouteSend() {
      try {
        this.formDataRoute.depart
          ? (this.formDataRoute.depart =
              this.formDataRoute.depart.split(",")[0].trim() +
              ", " +
              this.formDataRoute.depart
                .split(",")[this.formDataRoute.depart.split(",").length - 1].trim())
          : "";
        this.formDataRoute.arrival
          ? (this.formDataRoute.arrival =
              this.formDataRoute.arrival.split(",")[0].trim() +
              ", " +
              this.formDataRoute.arrival
                .split(",")[this.formDataRoute.arrival.split(",").length - 1].trim())
          : "";
        this.formDataRoute.layover1
          ? (this.formDataRoute.layover1 =
              this.formDataRoute.layover1.split(",")[0].trim() +
              ", " +
              this.formDataRoute.layover1
                .split(",")[this.formDataRoute.layover1.split(",").length - 1].trim())
          : "";
        this.formDataRoute.layover2
          ? (this.formDataRoute.layover2 =
              this.formDataRoute.layover2.split(",")[0].trim() +
              ", " +
              this.formDataRoute.layover2
                .split(",")[this.formDataRoute.layover2.split(",").length - 1].trim())
          : "";
        this.formDataRoute.layover3
          ? (this.formDataRoute.layover3 =
              this.formDataRoute.layover3.split(",")[0].trim() +
              ", " +
              this.formDataRoute.layover3
                .split(",")[this.formDataRoute.layover3.split(",").length - 1].trim())
          : "";

        const response = await axios.post(
          `${config.API_BASE_URL}/route/add`,
          {
            data: this.formDataRoute,
          }
        );
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'routes',
            id_category: response.data.id,
            action: 'Ajout'
          }}
        );
        console.log(responseNewHistory)
        const responseData = await response;
        console.log(responseData); // handle response as needed
        this.refreshRoutes();
      } catch (error) {
        console.error("Error Routes/add :", error);
      }
    },
    async addSubscriberSend() {
      try {
        const response = await axios.post(`${config.API_BASE_URL}/subscriber/add`, {
          data: this.formDataSubscriber,
        });
        const responseData = await response;
        console.log(responseData); // handle response as needed
        this.refreshSubscribers();
        this.formDataSubscriber = {
          email: ""
        };
      } catch (error) {
        console.error("Error Subscriber/add :", error);
      }
    },
    async addUserSend() {
      try {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        this.formDataUser['password'] = result;
        const response = await axios.post(`${config.API_BASE_URL}/user/add`, {
          data: this.formDataUser,
        });
        const responseData = await response;
        console.log(responseData);
        this.refreshUsers();
        this.formDataUser = {
          firstname: "",
          lastname: "",
          type: 3,
          society_name: "",
          society_address: "",
          address: "",
          email: "",
          phone: ""
        };
      } catch (error) {
        console.error("Error Event/add :", error);
      }
    },
    async editDiscountCodeSend(row) {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/discountCode/edit`,
          {
            data: this.tmpDiscountCodes[
              this.discountCodes.findIndex((item) => item.id === row.id)
            ],
          }
        );
        const responseData = await response;
        console.log(responseData);
        this.refreshDiscountCodes();
      } catch (error) {
        console.error("Error Event/edit :", error);
      }
    },
    async editEstimate() {
      try {
        this.loadingEstimate = true;
        const response = await fetch(
          `${config.API_BASE_URL}/estimate/edit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formEditEstimate),
          }
        );
        const responseData = await response.json();
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'estimates',
            id_category: this.formEditEstimate.id,
            action: 'Modification'
          }}
        );
        console.log(responseNewHistory)
        console.log(responseData);
        ElNotification({
          title: "Success",
          message: "Opération terminée",
          type: "success",
          position: "bottom-right",
          duration: 10000,
        });
        this.loadingEstimate = false;
        this.showEstimatePopup = false;
        this.getEstimates();
      } catch (error) {
        ElNotification({
          title: "Erreur",
          message: `Erreur : ${error}`,
          type: "error",
          position: "bottom-right",
          duration: 10000,
        });
        this.loadingEstimate = false;
        console.error("Error estimate/edit :", error);
        return;
      }
    },
    async editNewsSend(data) {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/news/edit/${data.newsId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'news',
            id_category: data.newsId,
            action: 'Modification'
          }}
        );
        console.log(responseNewHistory)
        this.refreshNews();
      } catch (error) {
        console.error("Error News/edit :", error);
      }
    },
    async editEventSend(row) {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/event/edit/${row.id}`,
          {
            data: this.tmpEvents[
              this.events.findIndex((item) => item.id === row.id)
            ],
          }
        );
        const responseData = await response;
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'events',
            id_category: row.id,
            action: 'Modification'
          }}
        );
        console.log(responseNewHistory)
        this.refreshEvents();
      } catch (error) {
        console.error("Error Event/edit :", error);
      }
    },
    async editOptionsSend() {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/option/edit`,
          {
            data: this.options
          }
        );
        const responseData = await response;
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'options',
            id_category: null,
            action: 'Modification'
          }}
        );
        console.log(responseNewHistory)
      } catch (error) {
        console.error("Error Options/edit :", error);
      }
    },
    async editRouteSend(row) {
      try {
        this.tmpRoutes[
          this.routes.findIndex((item) => item.id === row.id)
        ].depart
          ? (this.tmpRoutes[
              this.routes.findIndex((item) => item.id === row.id)
            ].depart =
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].depart
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].depart
                .split(",")[
                  this.tmpRoutes[
                    this.routes.findIndex((item) => item.id === row.id)
                  ].depart.split(",").length - 1
                ].trim())
          : "";
        this.tmpRoutes[
          this.routes.findIndex((item) => item.id === row.id)
        ].arrival
          ? (this.tmpRoutes[
              this.routes.findIndex((item) => item.id === row.id)
            ].arrival =
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].arrival
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].arrival
                .split(",")[
                  this.tmpRoutes[
                    this.routes.findIndex((item) => item.id === row.id)
                  ].arrival.split(",").length - 1
                ].trim())
          : "";
        this.tmpRoutes[
          this.routes.findIndex((item) => item.id === row.id)
        ].layover1
          ? (this.tmpRoutes[
              this.routes.findIndex((item) => item.id === row.id)
            ].layover1 =
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].layover1
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].layover1
                .split(",")[
                  this.tmpRoutes[
                    this.routes.findIndex((item) => item.id === row.id)
                  ].layover1.split(",").length - 1
                ].trim())
          : "";
        this.tmpRoutes[
          this.routes.findIndex((item) => item.id === row.id)
        ].layover2
          ? (this.tmpRoutes[
              this.routes.findIndex((item) => item.id === row.id)
            ].layover2 =
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].layover2
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].layover2
                .split(",")[
                  this.tmpRoutes[
                    this.routes.findIndex((item) => item.id === row.id)
                  ].layover2.split(",").length - 1
                ].trim())
          : "";
        this.tmpRoutes[
          this.routes.findIndex((item) => item.id === row.id)
        ].layover3
          ? (this.tmpRoutes[
              this.routes.findIndex((item) => item.id === row.id)
            ].layover3 =
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].layover3
                .split(",")[0]
                .trim() +
              ", " +
              this.tmpRoutes[
                this.routes.findIndex((item) => item.id === row.id)
              ].layover3
                .split(",")[
                  this.tmpRoutes[
                    this.routes.findIndex((item) => item.id === row.id)
                  ].layover3.split(",").length - 1
                ].trim())
          : "";

        const response = await axios.post(
          `${config.API_BASE_URL}/route/edit/${row.id}`,
          {
            data: this.tmpRoutes[
              this.routes.findIndex((item) => item.id === row.id)
            ],
          }
        );
        const responseData = await response;
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'routes',
            id_category: row.id,
            action: 'Modification'
          }}
        );
        console.log(responseNewHistory)
        this.refreshRoutes();
      } catch (error) {
        console.error("Error Route/edit :", error);
      }
    },
    async editUserSend(row) {
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/user/edit/${row.id}`,
          {
            data: this.tmpUsers[
              this.users.findIndex((item) => item.id === row.id)
            ],
          }
        );
        const responseData = await response;
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'users',
            id_category: row.id,
            action: 'Modification'
          }}
        );
        console.log(responseNewHistory)
        this.refreshUsers();
      } catch (error) {
        console.error("Error User/edit :", error);
      }
    },
    async delDiscountCode(id) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/discountCode/del/${id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'discountCodes',
            id_category: id,
            action: 'Suppression'
          }}
        );
        console.log(responseNewHistory)

        this.refreshDiscountCodes();
      } catch (error) {
        console.error("Error News/del :", error);
      }
    },
    async delNews(id) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/news/del/${id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'news',
            id_category: id,
            action: 'Suppression'
          }}
        );
        console.log(responseNewHistory)

        this.refreshNews();
      } catch (error) {
        console.error("Error News/del :", error);
      }
    },
    async delRoute(id) {
      try {
        const response = await fetch(
          `${config.API_BASE_URL}/route/del/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'routes',
            id_category: id,
            action: 'Suppression'
          }}
        );
        console.log(responseNewHistory)

        this.refreshRoutes();
      } catch (error) {
        console.error("Error Route/del :", error);
      }
    },
    async delEvent(id) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/event/del/${id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const responseData = await response.json();
        console.log(responseData);
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'events',
            id_category: id,
            action: 'Suppression'
          }}
        );
        console.log(responseNewHistory)

        this.refreshEvents();
      } catch (error) {
        console.error("Error Event/del :", error);
      }
    },
    async delSubscriber(email) {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/subscriber/del/${email}`);
        console.log(response.data);

        this.refreshSubscribers();
      } catch (error) {
        console.error("Error Subscriber/del :", error);
      }
    },
    confirmDelDiscountCode(row) {
      this.$confirm(
        `Confirmez-vous la suppression du code promo "${row.code}"?`,
        "Attention",
        {
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          type: "warning",
        }
      )
        .then(() => {
          this.delDiscountCode(row.id);
        })
        .catch(() => {});
    },
    confirmDelNews(row) {
      this.$confirm(
        `Confirmez-vous la suppression du article "${row.title}"?`,
        "Attention",
        {
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          type: "warning",
        }
      )
        .then(() => {
          this.delNews(row.id);
        })
        .catch(() => {});
    },
    confirmDelEvent(row) {
      this.$confirm(
        `Confirmez-vous la suppression du event "${row.name}"?`,
        "Attention",
        {
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          type: "warning",
        }
      )
        .then(() => {
          this.delEvent(row.id);
        })
        .catch(() => {});
    },
    confirmDelRoute(row) {
      this.$confirm(
        `Confirmez-vous la suppression du trajet ${row.depart.split(",")[0]}${
          row.layover1 ? " → " + row.layover1.split(",")[0] : ""
        }${row.layover2 ? " → " + row.layover2.split(",")[0] : ""}${
          row.layover3 ? " → " + row.layover3.split(",")[0] : ""
        } → ${row.arrival.split(",")[0]} ?`,
        "Attention",
        {
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          type: "warning",
        }
      )
        .then(() => {
          this.delRoute(row.id);
        })
        .catch(() => {});
    },
    duplicatePayment(row) {
      this.formDataPayment.id_main_order = row.id;
      this.formDataPayment.row = row;
      this.refreshAutocomplete++;
      document
        .getElementById("formDataPaymentTop")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
    duplicateRoute(route) {
      this.formDataRoute = JSON.parse(JSON.stringify(route));
      this.refreshAutocomplete++;
      document
        .getElementById("formRoute")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
    formattedDate(date) {
      date = String(date);
      if (date[4] != "-") {
        return (
          date.slice(6, 8).padStart(2, "0") +
          "-" +
          date.slice(4, 6).padStart(2, "0") +
          "-" +
          date.slice(0, 4)
        );
      } else {
        return (
          date.slice(8, 10).padStart(2, "0") +
          "-" +
          date.slice(5, 7).padStart(2, "0") +
          "-" +
          date.slice(0, 4)
        );
      }
    },
    formattedDatetime(datetime) {
      if(datetime){
        return new Date(new Date(datetime).getTime() + 3600 * 1000).toLocaleString('fr-FR');
      }
    },
    generateMotoListForEstimate() {
      this.formEditEstimate.moto_nb = Math.floor(this.formEditEstimate.moto_nb);
      if (this.formEditEstimate.moto_nb > 12) {
        this.formEditEstimate.moto_nb = 12;
      }
      if(this.formEditEstimate.moto_nb !== ''){
        const currentLength = this.formEditEstimate.moto_list.length;

        if (this.formEditEstimate.moto_nb > currentLength) {
          for (let i = currentLength; i < this.formEditEstimate.moto_nb; i++) {
            this.formEditEstimate.moto_list.push({
              model: "",
            });
          }
        }

        if (this.formEditEstimate.moto_nb < currentLength) {
          this.formEditEstimate.moto_list.splice(this.formEditEstimate.moto_nb);
        }
      }
    },
    generateMotoListForEstimateReturn() {
      this.formEditEstimate.moto_nb_return = Math.floor(this.formEditEstimate.moto_nb_return);
      if (this.formEditEstimate.moto_nb_return > 12) {
        this.formEditEstimate.moto_nb_return = 12;
      }
      if(this.formEditEstimate.moto_nb_return !== ''){
        const currentLength = this.formEditEstimate.moto_list_return.length;

        if (this.formEditEstimate.moto_nb_return > currentLength) {
          for (let i = currentLength; i < this.formEditEstimate.moto_nb_return; i++) {
            this.formEditEstimate.moto_list_return.push({
              model: "",
            });
          }
        }

        if (this.formEditEstimate.moto_nb_return < currentLength) {
          this.formEditEstimate.moto_list_return.splice(this.formEditEstimate.moto_nb_return);
        }
      }
    },
    generateMotoListForReservation() {
      this.formReservation.moto_nb = Math.floor(this.formReservation.moto_nb);
      if (this.formReservation.moto_nb > this.formReservation.places_moto) {
        this.formReservation.moto_nb = this.formReservation.places_moto;
      }
      if(this.formReservation.moto_nb !== ''){
        const currentLength = this.formReservation.moto_list.length;

        if (this.formReservation.moto_nb > currentLength) {
          for (let i = currentLength; i < this.formReservation.moto_nb; i++) {
            this.formReservation.moto_list.push({
              model: "",
            });
          }
        }

        if (this.formReservation.moto_nb < currentLength) {
          this.formReservation.moto_list.splice(this.formReservation.moto_nb);
        }
      }

      if(this.formReservation.row.discount_group_moto && this.formReservation.row.discount_group_value && this.formReservation.moto_nb >= this.formReservation.row.discount_group_moto ){
        this.formReservation.price_unit = this.formReservation.price_unit_origin * (1 - (this.formReservation.row.discount_group_value / 100));
        let price_passenger = 0;
        if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
        this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
      } else {
        this.formReservation.price_unit = this.formReservation.price_unit_origin;
        let price_passenger = 0;
        if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
        this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
      }
      this.formReservation.price_passenger = (this.formReservation.price_unit * this.formReservation.price_passenger_origin).toFixed(2);
      this.formReservation.price = this.formReservation.price_unit.toFixed(2);
    },
    generatePassengerListForEstimate() {
      this.formEditEstimate.passenger_nb = Math.floor(this.formEditEstimate.passenger_nb);
      if (this.formEditEstimate.passenger_nb > 20) {
        this.formEditEstimate.passenger_nb = 20;
      }
      if(this.formEditEstimate.passenger_nb !== ''){
        const currentLength = this.formEditEstimate.passenger_list.length;

        if (this.formEditEstimate.passenger_nb > currentLength) {
          for (let i = currentLength; i < this.formEditEstimate.passenger_nb; i++) {
            this.formEditEstimate.passenger_list.push({
              firstname: "",
              lastname: "",
              email: ""
            });
          }
        }

        if (this.formEditEstimate.passenger_nb < currentLength) {
          this.formEditEstimate.passenger_list.splice(this.formEditEstimate.passenger_nb);
        }
      }
    },
    generatePassengerListForEstimateReturn() {
      this.formEditEstimate.passenger_nb_return = Math.floor(this.formEditEstimate.passenger_nb_return);
      if (this.formEditEstimate.passenger_nb_return > 20) {
        this.formEditEstimate.passenger_nb_return = 20;
      }
      if(this.formEditEstimate.passenger_nb_return !== ''){
        const currentLength = this.formEditEstimate.passenger_list_return.length;

        if (this.formEditEstimate.passenger_nb_return > currentLength) {
          for (let i = currentLength; i < this.formEditEstimate.passenger_nb_return; i++) {
            this.formEditEstimate.passenger_list_return.push({
              firstname: "",
              lastname: "",
              email: ""
            });
          }
        }

        if (this.formEditEstimate.passenger_nb_return < currentLength) {
          this.formEditEstimate.passenger_list_return.splice(this.formEditEstimate_return.passenger_nb);
        }
      }
    },
    generatePassengerListForReservation() {
      this.formReservation.passenger_nb = Math.floor(this.formReservation.passenger_nb);
      if (this.formReservation.passenger_nb > this.formReservation.places_passenger) {
        this.formReservation.passenger_nb = this.formReservation.places_passenger;
      }
      if(this.formReservation.passenger_nb !== ''){
        const currentLength = this.formReservation.passenger_list.length;

        if (this.formReservation.passenger_nb > currentLength) {
          for (let i = currentLength; i < this.formReservation.passenger_nb; i++) {
            this.formReservation.passenger_list.push({
              firstname: "",
              lastname: "",
              email: ""
            });
          }
        }

        if (this.formReservation.passenger_nb < currentLength) {
          this.formReservation.passenger_list.splice(this.formReservation.passenger_nb);
        }
      }
    },
    openEditEstimatePopup(row){
      let moto_list = JSON.parse(row.list_moto);
      if(moto_list && moto_list.length > 0 && moto_list[0].text) moto_list[0]['model'] = moto_list[0].text;
      let moto_list_return = JSON.parse(row.list_moto_return);
      if(moto_list_return && moto_list_return.length > 0 && moto_list_return[0].text) moto_list_return[0]['model'] = moto_list_return[0].text;
      this.formEditEstimate = {
        address_charge: row.address_charge,
        address_discharge: row.address_discharge,
        charge: row.charge == 1 ? ['true'] : [],
        discharge: row.discharge == 1 ? ['true'] : [],
        price_charge: row.price_charge,
        price_discharge: row.price_discharge,
        price_unit: row.price_unit,
        price_passenger_rate: row.price_passenger_rate,
        price_insurance_rate: row.price_insurance_rate,
        date_depart: row.date_depart,
        depart: row.depart,
        date_return: row.date_return,
        arrival: row.arrival,
        moto_nb: row.quantity_moto,
        moto_list: moto_list || [],
        passenger_nb: row.quantity_passenger,
        passenger_list: JSON.parse(row.list_passenger) || [],
        code: row.discount_code,
        how: row.how,
        comment: row.comment,
        id: row.id,
        id_route_1: row.id_route_1,
        id_route_2: row.id_route_2,
        insurance: row.insurance == 1 ? ["true"] : [],
        status: row.status,
        type: row.type,
        row: row,
        user: this.users.filter((item) => item.email === row.email)[0],
        note: row.note,
        address_charge_return: row.address_charge_return,
        address_discharge_return: row.address_discharge_return,
        charge_return: row.charge_return == 1 ? ['true'] : [],
        discharge_return: row.discharge_return == 1 ? ['true'] : [],
        price_charge_return: row.price_charge_return,
        price_discharge_return: row.price_discharge_return,
        price_unit_return: row.price_unit_return,
        price_passenger_rate_return: row.price_passenger_rate_return,
        price_insurance_rate_return: row.price_insurance_rate_return,
        depart_return: row.depart_return,
        arrival_return: row.arrival_return,
        moto_nb_return: row.quantity_moto_return,
        moto_list_return: moto_list_return || [],
        passenger_nb_return: row.quantity_passenger_return,
        passenger_list_return: JSON.parse(row.list_passenger_return) || [],
        insurance_return: row.insurance_return == 1 ? ["true"] : [],
        msg: ''
      }
      this.showEstimatePopup = true;
    },
    openEditOrderPopup(row){
      this.formEditOrder = JSON.parse(JSON.stringify(row));
      this.formEditOrder['row'] = row;
      this.formEditOrder.charge = row.charge == 1 ? ['true'] : [];
      this.formEditOrder.discharge = row.discharge == 1 ? ['true'] : [];
      this.formEditOrder.moto_list = JSON.parse(row.list_moto) || [];
      this.formEditOrder.passenger_list = JSON.parse(row.list_passenger) || [];
      this.formEditOrder.insurance = row.insurance == 1 ? ["true"] : [];
      this.formEditOrder['user'] = this.users.filter((item) => item.email === row.email)[0];
      
      this.showOrderPopup = true;
    },
    refreshNews() {
      ElNotification({
        title: "Success",
        message: "Opération terminée",
        type: "success",
        position: "bottom-right",
        duration: 10000,
      });
      setTimeout(() => {
        this.getNews();
      }, 2000);
    },
    refreshDiscountCodes() {
      ElNotification({
        title: "Success",
        message: "Opération terminée",
        type: "success",
        position: "bottom-right",
        duration: 10000,
      });
      setTimeout(() => {
        this.getDiscountCodes();
      }, 2000);
    },
    refreshEvents() {
      ElNotification({
        title: "Success",
        message: "Opération terminée",
        type: "success",
        position: "bottom-right",
        duration: 10000,
      });
      setTimeout(() => {
        this.getEvents();
      }, 2000);
    },
    refreshRoutes() {
      ElNotification({
        title: "Success",
        message: "Opération terminée",
        type: "success",
        position: "bottom-right",
        duration: 10000,
      });
      setTimeout(() => {
        location.reload();
      }, 2000);
    },
    refreshSubscribers() {
      ElNotification({
        title: "Success",
        message: "Opération terminée",
        type: "success",
        position: "bottom-right",
        duration: 10000,
      });
      setTimeout(() => {
        this.getSubscribers();
      }, 2000);
    },
    refreshUsers() {
      ElNotification({
        title: "Success",
        message: "Opération terminée",
        type: "success",
        position: "bottom-right",
        duration: 10000,
      });
      setTimeout(() => {
        this.getUsers();
      }, 2000);
    },
    resetEstimate(row){
      let moto_list = JSON.parse(row.list_moto);
      if(moto_list && moto_list.length > 0 && moto_list[0].text) moto_list[0]['model'] = moto_list[0].text;
      let moto_list_return = JSON.parse(row.list_moto_return);
      if(moto_list_return && moto_list_return.length > 0 && moto_list_return[0].text) moto_list_return[0]['model'] = moto_list_return[0].text;
      this.formEditEstimate = {
        address_charge: row.address_charge,
        address_discharge: row.address_discharge,
        charge: row.charge == 1 ? ['true'] : [],
        discharge: row.discharge == 1 ? ['true'] : [],
        price_charge: row.price_charge,
        price_discharge: row.price_discharge,
        price_unit: row.price_unit,
        price_passenger_rate: row.price_passenger_rate,
        price_insurance_rate: row.price_insurance_rate,
        date_depart: row.date_depart,
        depart: row.depart,
        date_return: row.date_return,
        arrival: row.arrival,
        moto_nb: row.quantity_moto,
        moto_list: moto_list || [],
        passenger_nb: row.quantity_passenger,
        passenger_list: JSON.parse(row.list_passenger) || [],
        code: row.discount_code,
        how: row.how,
        comment: row.comment,
        id: row.id,
        id_route_1: row.id_route_1,
        id_route_2: row.id_route_2,
        insurance: row.insurance == 1 ? ["true"] : [],
        status: row.status,
        type: row.type,
        row: row,
        user: this.users.filter((item) => item.email === row.email)[0],
        note: row.note,
        address_charge_return: row.address_charge_return,
        address_discharge_return: row.address_discharge_return,
        charge_return: row.charge_return == 1 ? ['true'] : [],
        discharge_return: row.discharge_return == 1 ? ['true'] : [],
        price_charge_return: row.price_charge_return,
        price_discharge_return: row.price_discharge_return,
        price_unit_return: row.price_unit_return,
        price_passenger_rate_return: row.price_passenger_rate_return,
        price_insurance_rate_return: row.price_insurance_rate_return,
        depart_return: row.depart_return,
        arrival_return: row.arrival_return,
        moto_nb_return: row.quantity_moto_return,
        moto_list_return: moto_list_return || [],
        passenger_nb_return: row.quantity_passenger_return,
        passenger_list_return: JSON.parse(row.list_passenger_return) || [],
        insurance_return: row.insurance_return == 1 ? ["true"] : [],
        msg: ''
      }
    },
    searchUserForEmail(queryString, cb) {
      const results = this.users.filter((item) =>
        item.email.toLowerCase().includes(queryString.toLowerCase())
      );
      cb(results);
    },
    handleSelectEmail(item) {
      this.formDataEstimate.email = item.email;
      this.formDataEstimate.phone = item.phone;
      this.formDataEstimate.firstname = item.firstname;
      this.formDataEstimate.lastname = item.lastname;
    },
    handleSelectEmailForReservation(item) {
      this.formReservation.email = item.email;
      this.formReservation.phone = item.phone;
      this.formReservation.firstname = item.firstname;
      this.formReservation.lastname = item.lastname;
    },
    async addEstimate() {
      try {
        this.$refs.ruleFormDataEstimateRef.validate(async(valid) => {
          if (valid) {
            console.log("Form submitted:", this.formDataEstimate);
            this.loadingNewEstimate = true;
            const response = await fetch(
              `${config.API_BASE_URL}/estimate/add`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.formDataEstimate),
              }
            );
            const responseData = await response.json();
            console.log(responseData);
            const responseNewHistory = await axios.post(
              `${config.API_BASE_URL}/history/add`,
              { data: { 
                id_user: this.token.id,  
                category: 'estimates',
                id_category: responseData.data.id,
                action: 'Ajout'
              }}
            );
            console.log(responseNewHistory)
            ElNotification({
              title: "Success",
              message: "Chiffrage enregistré",
              type: "success",
              position: "bottom-right",
              duration: 10000,
            });
            this.loadingNewEstimate = false;
            this.formDataEstimate= {
              address_charge: '',
              address_discharge: '',
              charge: [],
              discharge: [],
              price_charge: this.options[1].value,
              price_discharge: this.options[1].value,
              price_unit: 0,
              price_passenger_rate: 0,
              price_insurance_rate: this.options[0].value,
              date_depart: '',
              date_return: '',
              depart: "",
              arrival: "",
              moto_nb: 0,
              moto_list: [],
              passenger_nb: 0,
              passenger_list: [],
              code: "",
              how: "",
              comment: "",
              id_route_1: 0,
              id_route_2: 0,
              insurance: [],
              status: 1,
              type: 1,
              email: '',
              phone: '',
              lastname: '',
              firstname: '',
              note: ''
            };
            this.getEstimates();
          } else {
            console.error("Validation failed!");
            this.loadingNewEstimate = false;
            return;
          }
        });
      } catch (error) {
        ElNotification({
          title: "Erreur",
          message: `Erreur : ${error}`,
          type: "error",
          position: "bottom-right",
          duration: 10000,
        });
        this.loadingNewEstimate = false;
        console.error("Error estimate/add :", error);
        return;
      }
    },
    async addPayment() {
      try {
        this.$refs.ruleFormDataPaymentRef.validate(async(valid) => {
          if (valid) {
            console.log("Form submitted:", this.formDataPayment);
            this.loadingNewPayment = true;
            const response = await fetch(
              `${config.API_BASE_URL}/order/add/sub`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.formDataPayment),
              }
            );
            const responseNewHistory = await axios.post(
              `${config.API_BASE_URL}/history/add`,
              { data: { 
                id_user: this.token.id,  
                category: 'orders',
                id_category: responseData.data.id,
                action: 'Ajout'
              }}
            );
            console.log(responseNewHistory)
            const responseData = await response.json();
            console.log(responseData);
            ElNotification({
              title: "Success",
              message: "Paiement envoyé",
              type: "success",
              position: "bottom-right",
              duration: 10000,
            });
            this.loadingNewPayment = false;
            this.formDataPayment = {
              id_main_order: null,
              price: 0
            };
            this.getOrders();
          } else {
            console.error("Validation failed!");
            this.loadingNewPayment = false;
            return;
          }
        });
      } catch (error) {
        ElNotification({
          title: "Erreur",
          message: `Erreur : ${error}`,
          type: "error",
          position: "bottom-right",
          duration: 10000,
        });
        this.loadingNewEstimate = false;
        console.error("Error estimate/add :", error);
        return;
      }
    },
    async sendEstimate() {
      try {
        this.loadingEstimate = true;
        const responseEdit = await fetch(
          `${config.API_BASE_URL}/estimate/edit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formEditEstimate),
          }
        );
        const responseEditData = await responseEdit.json();
        const response = await fetch(
          `${config.API_BASE_URL}/estimate/send`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formEditEstimate),
          }
        );
        const responseData = await response.json();
        console.log(responseEditData);
        console.log(responseData);
        
        const responseNewHistory = await axios.post(
          `${config.API_BASE_URL}/history/add`,
          { data: { 
            id_user: this.token.id,  
            category: 'estimates',
            id_category: this.formEditEstimate.id,
            action: 'Envoie Chiffrage'
          }}
        );
        console.log(responseNewHistory)
        ElNotification({
          title: "Success",
          message: "Chiffrage envoyé",
          type: "success",
          position: "bottom-right",
          duration: 10000,
        });
        this.loadingEstimate = false;
        this.getEstimates();
      } catch (error) {
        ElNotification({
          title: "Erreur",
          message: `Erreur : ${error}`,
          type: "error",
          position: "bottom-right",
          duration: 10000,
        });
        this.loadingEstimate = false;
        console.error("Error estimate/send :", error);
        return;
      }
    },
    switchTab(tab) {
      this.currentTab = tab;
    },
    formatDateToInputDateValue(yyyyMMdd) {
      if (yyyyMMdd[4] != "-") {
        const year = yyyyMMdd.slice(0, 4);
        const month = yyyyMMdd.slice(4, 6).padStart(2, "0");
        const day = yyyyMMdd.slice(6, 8).padStart(2, "0");

        return `${year}-${month}-${day}`;
      } else {
        return yyyyMMdd;
      }
    },
    formatDate(dateString) {
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6).padStart(2, "0");
      const day = dateString.slice(6, 8).padStart(2, "0");
      return `${day}/${month}/${year}`;
    },
    formatTimestamp(timestamp, nbMoto) {
      const [hours, minutes] = timestamp.split(":").map(Number);
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      if (nbMoto && nbMoto <= 3) date.setMinutes(date.getMinutes() - 30);
      else date.setMinutes(date.getMinutes() - 15);

      const formattedHours = String(date.getHours()).padStart(2, "0");
      const formattedMinutes = String(date.getMinutes()).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    },
    async forgotPsw(row){
      try {
        const response = await axios.post(
          `${this.config.API_BASE_URL}/user/forgotPsw`,
          {
            data: row,
          }
        );
        if (response.data) {
          ElNotification({
            title: "Success",
            message: "Un mail de réinitialisation de mot de passe a été envoyé à l'adresse e-mail du client.",
            type: "success",
            position: "bottom-right",
            duration: 10000,
          });
        }
        return;
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            console.error("Adresse Email non trouvée.");
            ElNotification({
              title: "Erreur",
              message: "Adresse Email non trouvée.",
              type: "error",
              position: "bottom-right",
              duration: 10000,
            });
            return;
          } else {
            console.error("Erreur:", error.response.data.message);
            ElNotification({
              title: "Erreur",
              message: error.response.data.message,
              type: "error",
              position: "bottom-right",
              duration: 10000,
            });
            return;
          }
        } else if (error.request) {
          console.error("Aucune réponse reçue du serveur.", error.request);
          ElNotification({
            title: "Erreur",
            message:
              "Aucune réponse reçue du serveur. Veuillez réessayer plus tard.",
            type: "error",
            position: "bottom-right",
            duration: 10000,
          });
          return;
        } else {
          console.error("Erreur:", error.message);
          ElNotification({
            title: "Erreur",
            message: error.message,
            type: "error",
            position: "bottom-right",
            duration: 10000,
          });
          return;
        }
      }
    },
    logout(){
      Cookies.remove('authToken');
      window.location.reload();
    },
  },
};
</script>
<style lang="scss">
.el-col-1,
.el-col-2,
.el-col-3,
.el-col-4,
.el-col-5,
.el-col-6,
.el-col-7,
.el-col-8,
.el-col-9,
.el-col-10,
.el-col-11,
.el-col-12,
.el-col-13,
.el-col-14,
.el-col-15,
.el-col-16,
.el-col-17,
.el-col-18,
.el-col-19,
.el-col-20,
.el-col-21,
.el-col-22,
.el-col-23,
.el-col-24 {
  display: inline-block;
  width: 100%;
  align-self: start;
  vertical-align: top;
}
.el-checkbox__label{
  text-wrap: auto;
}
.el-dialog__header{
  background: #000047;
  border-radius: 10px;
  padding-top: 20px;
  font-weight: 800;
  text-transform: uppercase;
}
.el-dialog__headerbtn{
  font-size: 30px;
  margin-top: 10px;
}
span.el-dialog__title{
  color: white !important;
}

.el-alert__content {
  line-height: normal;
}
.el-checkbox{
  display: inline;
}
.el-pagination {
  place-content: center;
}
.el-pagination.is-background .el-pager li.is-active {
  background-color: #000047;
}
.el-pager {
  position: relative;
}
.el-dialog__body {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}
.el-checkbox.is-checked{
  display: inline;
}
.el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
  display: inline;
}
.el-timeline .timeline-bloc:last-child .el-timeline-item__tail {
  border-color: white;
}
.el-select__selected-item.el-select__placeholder{
  white-space: normal;
}
.el-table{
  width: 100%;
}
.table-estimates {
  .el-table__body-wrapper tbody tr td:nth-child(1) {
    border-right: 2px solid gray;
  }
  .el-table__body-wrapper tbody tr td:nth-child(3) {
    border-right: 2px solid gray;
  }
  .el-table__body-wrapper tbody tr td:nth-child(7) {
    border-right: 2px solid gray;
  }
  .el-table__body-wrapper tbody tr td:nth-child(8) {
    border-right: 2px solid gray;
  }
  .el-table__body-wrapper tbody tr td:nth-child(13) {
    border-right: 2px solid gray;
  }
  .el-table__body-wrapper tbody tr td:nth-child(18) {
    border-right: 2px solid gray;
  }
  .el-table__body-wrapper tbody tr td:nth-child(21) {
    border-right: 2px solid gray;
  }
}
.empty-background {
  background-color: #a6a6a6 !important;
  color: white;
}
.el-table td {
  white-space: nowrap;
}
.autocomplete {
  max-width: 400px;
  width: 100%;
}
.rose {
  color: #e6007e;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.space-between {
  justify-content: space-between;
}
.btn-blue {
  background-color: #000047;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.btn-rose {
  background-color: #e6007e;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.news-add {
  padding: 50px;
  background: whitesmoke;
}
.tr-edit {
  padding: 50px;
  img,
  video {
    max-height: 180px;
  }
}

.roundTrip{
  padding: 10px;
  border: solid 2px #000047;
  border-radius: 20px;
  background-color: #a8a47a62;
  margin-bottom: 15px;
}

.histories{
  box-sizing: border-box;
  color: white; 
  background-color: #000047; 
  font-size: 30px; 
  padding:10px;
  border-bottom: 3px solid #e6007e;
  width: 100%;
}
.menu-admin {
  display: inline;
  color: white;
  font-size: 37px;
  font-weight: 300;
  margin: 0 20px;
  cursor: pointer;
}

.menu-admin.active {
  color: #45bbbf; /* 被点击的颜色 */
  font-weight: 400;
}

.menu-admin:not(.active) {
  color: white; /* 没被点击的颜色 */
  font-weight: 300;
}

.price-details{
  background: whitesmoke;
  padding: 20px;
  border-radius: 10px;
}

.status{
  color: white;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: 500;
  text-align: center;
  width: fit-content;
  justify-self: center;
}

.searchBar{
  box-sizing: border-box;
  width: 100%; 
  padding: 20px; 
  background: aliceblue;
}

.timeline-bloc {
  display: flex;
}
.time {
  color: #909399;
  font-size: 12px;
  margin: -1px 10px 0 0;
}
.timeline-comment {
  margin: 0;
  font-size: 12px;
  color: #909399;
  line-height: none;
}


@media screen and (max-width: 1316px) {
  .el-col {
    max-width: 99%;
  }
  .el-form {
    padding: 20px !important;
  }
  .el-dialog {
    width: 98% !important;
  }
}
</style>
