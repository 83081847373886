<template>
  <div>
    <el-form
      ref="ruleLoginRef"
      style="max-width: 600px; padding: 50px"
      :model="formLogin"
      :rules="rulesLogin"
      label-width="auto"
      label-position="top"
      v-loading="isLoginSending"
      status-icon
    >
      <el-form-item label="Mot de passe oublié ?">
        <el-col :span="24">
          <el-form-item label="Nouveau mot de passe" required prop="password">
            <el-input type="password" v-model="formLogin.password" required />
          </el-form-item>
        </el-col>
        <el-col :span="24"></el-col>
        <el-col :span="24">
          <el-form-item label="Confirmation Nouveau mot de passe" required prop="checkPassword">
            <el-input type="password" v-model="formLogin.checkPassword" required />
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-col :span="24"></el-col>
      <button class="btn-trans-blue w-1" type="button" @click="login()">
        Valider
      </button>
    </el-form>
    <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import { ElNotification } from "element-plus";

export default {
  name: "ResetPsw",
  data() {
    return {
      config: config,
      errorMessage: "",
        formLogin:{
          password: "",
          reset_token: ""
        },
      isLoginSending: false,
      rulesLogin: {
        password: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        checkPassword: [
          {
            required: true,
            message: "Les mots de passe ne correspondent pas",
            validator: this.checkPassword,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    checkPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Veuillez confirmer votre mot de passe'));
      } else if (value !== this.formLogin.password) {
        callback(new Error('Les mots de passe ne correspondent pas'));
      } else {
        callback();
      }
    },
    async login() {
      try {
        if (!this.$refs.ruleLoginRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleLoginRef.validate((valid, fields) => {
            if (valid) {
              console.log("submit!");
              resolve(true);
            } else {
              console.log("error submit:" + reject, fields);
              resolve(false);
            }
          });
        });
        if (!check) return;
        this.formLogin.reset_token = new URLSearchParams(window.location.search).get('token');
        this.isLoginSending = true;
        const response = await axios.post(
          `${this.config.API_BASE_URL}/user/resetPsw`,
          {
            data: this.formLogin,
          }
        );
        if (response.data) {
            this.errorMessage = 'Votre mot de passe a été réinitialisé.';
        }
        ElNotification({
          title: "Success",
          message: "Votre mot de passe a été réinitialisé.",
          type: "success",
          position: "bottom-right",
          duration: 0,
        });
        this.isLoginSending = false;
        this.$router.push("/moto");
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            console.error("Lien invalide ou expiré.");
            ElNotification({
              title: "Erreur",
              message: "Lien invalide ou expiré.",
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
          }
          else if (error.response.status === 400) {
            console.error("Lien expiré.");
            ElNotification({
              title: "Erreur",
              message: "Lien expiré.",
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
          } else {
            console.error("Erreur:", error.response.data.message);
            ElNotification({
              title: "Erreur",
              message: error.response.data.message,
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
          }
        } else if (error.request) {
          console.error("Aucune réponse reçue du serveur.", error.request);
          ElNotification({
            title: "Erreur",
            message:
              "Aucune réponse reçue du serveur. Veuillez réessayer plus tard.",
            type: "error",
            position: "bottom-right",
            duration: 0,
          });
          this.isLoginSending = false;
        } else {
          console.error("Erreur:", error.message);
          ElNotification({
            title: "Erreur",
            message: error.message,
            type: "error",
            position: "bottom-right",
            duration: 0,
          });
          this.isLoginSending = false;
        }
      }
    },
  },
};
</script>
