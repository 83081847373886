<template>
  <div style="width: -webkit-fill-available;">
      <div class="nav">
          <a href="/" target="_self"
          ><img
              src="../assets/images/ui/logo.png"
              style="max-width: 250px"
          /></a>
      </div>
      <div class="container">
        <div class="title">Carte cadeau</div>
        <div class='w-1'>
          <div class="txt">
            <div class="elementor-widget-container optml-bg-lazyloaded">
				<p><!-- wp:paragraph --></p>
<p style="text-align: center;"><strong>Une idée de cadeau originale pour vos proches motards.&nbsp;</strong></p>
<p style="text-align: left;">Convivialité, sécurité et repos, la meilleure manière de commencer et finir son Roadtrip.</p>
<p style="text-align: left;">Pour les longs trajets ennuyeux afin d’arriver jusqu’à vos terrains de jeux.</p>
<p style="text-align: left;"><strong>Avec Symone, offrez des km +sécurisés +écologiques +confortables +conviviaux et -chers</strong></p>
<hr>
<p>Offrez une expérience originale en quelques minutes :</p>
<ol>
<li style="text-align: left;">Remplissez et envoyez le formulaire ci-dessous</li>
<li style="text-align: left;">Si besoin de répondre à vos questions nous vous recontacterons</li>
<li style="text-align: left;">Vous recevrez un devis à signer en ligne ainsi qu’un lien de paiement</li>
<li style="text-align: left;">La carte cadeau sera envoyée à l’adresse mail de votre choix avec un code promo</li>
</ol>
<hr>
<ul>
<li>Valable 2 ans&nbsp; !</li>
<li>Utilisable en une ou plusieurs fois</li>
<li>Peut être utilisée seule ou pour son groupe par le bénéficiaire</li>
<li>Valable sur toute demande de trajet accepté en fonction des disponibilités</li>
</ul>
<p><!-- /wp:paragraph --></p>						</div>
<div class='w-1' style='text-align: center;'>
<a href='/moto'><button class='btn-blue'>REVENIR SUR LA PAGE SYMONE MOTO</button></a>
<br><br>
          <video
            style="border-radius: 10px; max-height: 50vh; max-width: 100%;"
            src="../assets/videos/Publicite-Symone-moto-V12.mp4"
            autoplay="autoplay"
            loop="loop"
            muted=""
            controls="controls"
          ></video>
</div>
            <el-form
                  ref="ruleRef"
                  style="max-width: 1000px"
                  :model="form"
                  :rules="rules"
                  label-width="auto"
                  label-position="top"
                  class="form"
                  status-icon
                >
          <div class="loading-container" v-if="isSending">
                <div class="loading-spinner"></div>
              </div>
                  <el-col :span='24'>
                    <el-form-item label="Je souhaite offrir un bon d'achat Symone moto d'une valeur de:" required prop='value'>
                      <el-radio-group v-model="form.value">
                        <el-radio value="50">50€</el-radio>
                        <el-radio value="100">100€</el-radio>
                        <el-radio value="200">200€</el-radio>
                        <el-radio value="500">500€</el-radio>
                        <el-radio value="1000">1000€</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span="12">
                    <el-form-item label="Prénom du bénéficiaire" required prop='firstnameB'>
                      <el-input type="text" v-model="form.firstnameB"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Nom du bénéficiaire" required prop='lastnameB'>
                      <el-input type="text" v-model="form.lastnameB" required />
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span="24">
                    <el-form-item label="E-mail du bénéficiaire" prop='emailB'>
                      <el-input
                        type="email"
                        v-model="form.emailB"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span='24'>
                    <el-form-item required prop='type'>
                      <el-radio-group v-model="form.type">
                        <el-radio value="1">Symone enverra la carte cadeau directement à l'E-mail du bénéficiaire, à la date que vous souhaitez. Date à indiquer dans les commentaires.</el-radio>
                        <el-radio value="2">Symone vous enverra la carte cadeau à votre E-mail.</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span="12">
                    <el-form-item label="Votre Prénom" required prop='firstname'>
                      <el-input type="text" v-model="form.firstname"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Votre Nom" required prop='lastname'>
                      <el-input type="text" v-model="form.lastname" required />
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span="18">
                    <el-form-item label="Votre E-mail" required prop='email'>
                      <el-input
                        type="email"
                        v-model="form.email"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="Téléphone" prop='phone'>
                      <el-input type="text" v-model="form.phone"/>
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span='24'>
                    <el-form-item prop='hide'>
                      <el-checkbox-group v-model="form.hide">
                        <el-checkbox value="true" name="hide">
                          Ne pas indiquer mes informations au destinataire de la carte cadeau (ni dans le mail ni sur la carte)
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span="24">
                    <el-form-item label="Message que vous souhaitez faire figurer sur la carte cadeau">
                      <el-input
                        type="textarea"
                        v-model="form.message"
                      />
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span="24">
                    <el-form-item label="Un commentaire ou une question">
                      <el-input
                        type="textarea"
                        v-model="form.comment"
                      />
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <button
                    class="btn-trans-blue w-1"
                    type="button"
                    @click="sendForm()"
                  >
                    Envoyer
                  </button>
                </el-form>
          </div>
        </div>
      </div>
      <div class="footer">
        <div style="margin: 50px">
          <div
            style="
              font-size: 20px;
              color: #e6007e;
              font-weight: 400;
              text-align-last: center;
            "
          >
            SUIVEZ-NOUS
            <br />
            <a href="https://fr.linkedin.com/company/symone" target="_blank"
              ><i
                class="fab fa-linkedin"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://twitter.com/SymoneMobility" target="_blank"
              ><i
                class="fab fa-twitter"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a
              href="https://www.instagram.com/symonemobility/?hl=fr"
              target="_blank"
              ><i
                class="fab fa-instagram"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
              ><i
                class="fab fa-facebook"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <br />
            <br />
            NOUS CONTACTER
            <br />
            <span style="color: #000047"
              >contact@symone.fr<br />06 17 33 43 93</span
            >
            <br />
            <br />
            ÉCRIVEZ-NOUS
            <br />
            <a href="/contact" target="_blanc"
              ><span style="color: #000047">Contact</span></a
            >
          </div>
          <div
            style="
              font-size: 20px;
              color: #000047;
              font-weight: 400;
              text-align-last: center;
            "
          >
            <br />
            <a href="/" target="_blank">Bienvenue à bord</a>
            <br /><br />
            <a href="/moto" target="_blank">Symone à moto</a>
            <br /><br />
            <a href="/#home-tag-2" target="_self"
              >Une nouvelle expérience de la route</a
            >
            <br /><br />
            <a
              href="/condition"
              target="_blank"
              >Conditions Générales de Vente</a
            >
            <br />
          </div>
        </div>
        <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';

export default {
  name: "GiftPage",
  components: { 
  },
  data() {
    return {
      config: config,
      form: {
        value: '',
        type: '',
        hide: [],
        firstname: '',
        lastname: '',
        email: '',
        firstnameB: '',
        lastnameB: '',
        emailB: '',
        phone: '',
        comment: '',
        message: ''
      },
      isSending: false,
      rules:{
        firstname: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        lastname: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Veuillez saisir une adresse E-mail valide', trigger: ['blur', 'change'] },
        ],
        firstnameB: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        lastnameB: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        Phone: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        type: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'change' },
        ],
        value: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'change' },
        ]
      },
    };
  },
  created(){
    
    document.title= 'Carte cadeau - Symone'
  },
  watch: {
  },
  mounted(){
  },
  methods: {
    async sendForm() {
      try {
        if (!this.$refs.ruleRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleRef.validate((valid, fields) => {
            if (valid) {
              console.log('submit!');
              resolve(true);
            } else {
              console.log('error submit:' + reject, fields);
              resolve(false);
            }
          });
        });
        if(!check) return;
        this.isSending = true;
        const response = await axios.post(
          `${config.API_BASE_URL}/mail/gift`,
          this.form
        );
        console.log(response.data);

        this.$message({
          message:
            "Demande envoyée, nous vous répondrons dans les plus brefs délais.",
          type: "success",
          duration: 5000,
        });
        setTimeout(() => {
          this.isSending = false;
          location.reload();
        }, 5000); 
      } catch (error) {
        console.error("Error Send/Gift:", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
*{
  text-decoration: none;
}
.el-checkbox, .el-radio{
  white-space: normal;
  height: auto;
}
.el-col-1, .el-col-2, .el-col-3, .el-col-4, .el-col-5, .el-col-6, .el-col-7, .el-col-8, .el-col-9, .el-col-10,.el-col-11, .el-col-12, .el-col-13, .el-col-14, .el-col-15, .el-col-16, .el-col-17, .el-col-18, .el-col-19, .el-col-20, .el-col-21, .el-col-22, .el-col-23, .el-col-24 {
  display: inline-block;
  width: 100%;
}
.loading-container, .loading-container-devis, .loading-container-reservation{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #ffffffb0;
  z-index: 5;
}

.loading-spinner, .loading-spinner-devis, .loading-spinner-reservation {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
    .btn-blue {
      margin-left: auto;
      font-size: 20px;
      cursor: pointer;
    }
.border {
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
  1px 1px 0 #000;
}
.container{
  color: #000047;
width: 100%;
}

.btn-trans-blue {
  background-color: transparent;
  color: #000047;
  font-size: 20px;
  border: 2px solid #000047;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-trans-blue:hover {
  background-color: #000047;
  color: white;
}
.title{
  color: #000047;
  font-size: 50px;
  font-weight: 600;
  
background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  min-height: 250px;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  
  display: flex;    
  align-items: center;
  text-align: center;
  justify-content: center;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nav {
  padding: 25px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #000047;
}
.txt{
max-width: 1000px;
text-align: left;
*{
  margin: revert;
}
img{
  max-width: 100%;

}
}
.w-1{
width: 100%;
text-align: -webkit-center;
}
.menu {
display: flex;
}
.menu a {
color: white;
margin: 0 10px;
}
.details {
max-width: 40vw;
color: #dddddd;
font-size: 20px;
font-weight: 300;
text-transform: uppercase;
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
  1px 1px 0 #000;
}
.cover img{
max-width: 60vw;
max-height: 50vh;
}

.footer {
height: 100vh;
width: 100%;
padding: 50px;
box-sizing: border-box;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
img{
  max-width: 100%;
}
}
a{
color: #e6007e;
}
.form{
  padding: 50px 10px;
}
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}
</style>
